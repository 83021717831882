// Abstract Files
@import './sass-utils/variables';

// Base Files
@import './base/reset';
@import './base/typography';
@import './base/formElements';
@import './base/keyFrames';
//@import './base/fonts';

//Components Files
@import './components/button';
@import './components/tabs';
@import './components/nav';
@import './components/editor';
@import './components/progressBar';
@import './components/tooltip';
@import './components/listPanelHead';
@import './components/userInfoCard';
@import './components/searchPopover';
@import './components/listThreeColumn';
@import './components/calender';
@import './components/materialUIElements';
@import './components/screenlockMessage';
@import './components/snackbar';
@import './components/loader';
@import './components/badge';
@import './components/avatar';
@import './components/teamInfoCard';
@import './components/dateRangePicker';
@import './components/cycleDropdown';
@import './pages/create-okr-card/create-okr-card.scss';
@import './components/weightSelection';
@import './components/confidence';
@import './components/weeklyTrends';
@import './components/popper';
@import './components/importOkrs';
@import './components/selectDropdown';

//pages
@import './layout/drawer';

//pages
@import './pages/onboarding/onboarding.scss';
@import './pages/admin/noSearchRecord.scss';
@import './pages/people/people.scss';

//other
@import './globalComponents.scss';

.wrapper-container {
	padding: 0 0 3em;
}
.pad-container {
	padding-left: 3% !important;
	padding-right: 3% !important;
}
* {
	font-family: $font-family-lato !important;
}
body {
	font-family: $font-family-lato !important;
	.MuiChip-label {
		font-weight: $font-weight-bold;
	}
	.MuiBackdrop-root {
		background: rgba($color-primary2, 0.2);
	}
	.okr-default-dropdown {
		box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
		@include border-radius(2px);
		max-height: 20em;
		overflow-y: auto;
		.MuiMenu-list {
			padding: 0;
			.MuiListItem-root {
				height: 2.875em;
				border-bottom: 1px solid rgba($color-input-field, 0.2);
				&:last-child {
					border: none;
				}
				&:hover {
					background-color: $color-hover;
				}
				&.Mui-selected {
					background-color: $color-secondary2;
					// 	color: $color-primary1;
				}
			}
		}
	}
}

.wrapper {
	padding-bottom: 3em;
	min-height: 100vh;
	&.user-wrapper {
		background-color: $color-secondary2;
	}

	.building-img {
		background-image: url(../images/admin-wrapper-bg.svg);
		background-position: center 8em;
		background-repeat: no-repeat;
		min-height: 100vh;
		width: 100%;
		position: fixed;
	}
}

.main-wrapper {
	max-width: 100%;
	min-height: calc(100vh + 3em);
	padding: 1.5em 0 3em;
	position: relative;
	z-index: 1;
}
.page-container {
	padding-top: 1.5em;
	position: relative;
}
.top-heading-area {
	padding-bottom: 1.5em;
}
.okr-default-dropdown,
#async-pagination,
#custom-scroll {
	@include custom-scrollbar;
}

/*Global Header CSS*/
@mixin global-header {
	@include justify-content(flex-end);
	@include align-items(flex-start);
}
.no-data-header {
	@include flex;
	@include global-header;
	min-height: 5em;
	.add-btn {
		&::after {
			content: 'Create Objectives';
			position: absolute;
			right: 15px;
			top: 100%;
			width: 140px;
			background-image: url(../images/arrow.svg);
			background-position: right top;
			background-repeat: no-repeat;
			font-size: 1em;
			color: $color-primary2;
			padding: 52px 20px 0 0;
			margin-top: 6px;
		}
	}
}
.global-header {
	@include flex;
	@include global-header;
	@include justify-content(space-between);
	min-height: 5em;
}
.global-head-info {
	flex: 1;
	.MuiTypography-h2 {
		text-transform: capitalize;
		padding-bottom: 0.25em;
	}
	.global-head-title {
		@include flex;
		@include align-items(baseline);
		// ul {
		// 	padding: 0;
		// 	@include flex;
		// 	padding-left: 2.2em;
		// 	li {
		// 		width: auto;
		// 		flex: 0 0 auto;
		// 		padding: 0;
		// 		padding-right: 1.5em;
		// 		.MuiTypography-body1 {
		// 			@include flex;
		// 			@include align-items(center);
		// 			span {
		// 				width: 1.75em;
		// 				height: 1.75em;
		// 				line-height: 1.75em;
		// 				border-radius: 100%;
		// 				background: #ffdff8;
		// 				color: #ff00c7;
		// 				font-size: $font-size-h6;
		// 				text-align: center;
		// 				margin-left: 0.5em;
		// 			}
		// 		}
		// 	}
		// }
	}
	.connections-btn {
		background: $color-primary1;
		border-radius: 6px;
		color: $color-white;
		border-color: $color-primary1;
		margin-top: 1em;
		width: 100%;
		padding: 0.3em 1.3em;
		@include flex;
		justify-content: left;
		&:hover {
			background: $color-primary1;
		}
		&.Mui-disabled {
			color: $color-input-field;
		}
	}
}
.global-head-action {
	@include flex;
	@include align-items(center);
	@include justify-content(flex-end);
	flex: 1;
	button {
		margin-left: 0.75em;
	}
}

/*Global Contributors List CSS*/
.contributors {
	@include flex;
	@include align-items(center);
	position: relative;
	.contributor-list {
		@include flex;
		@include align-items(center);
		margin: 0 0.5em 0 0;
		.MuiAvatarGroup-avatar {
			//border: none;
			width: 30px;
			height: 30px;
			box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.1);
			&:first-child {
				z-index: 0 !important;
			}
			&:nth-child(2) {
				z-index: 1 !important;
			}
			&.MuiAvatar-colorDefault:nth-child(3) {
				width: 18px;
				height: 18px;
				background-color: $color-primary1;
				color: $color-white;
				z-index: 2 !important;
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				border: none;
			}
			&.MuiAvatar-colorDefault.removeBlue:nth-child(3) {
				background: $color-bg-info;
				color: $color-tags;
				font-size: $font-size-h6;
				width: 30px;
				height: 30px;
				//font-weight: $font-weight-regular;
				border: 1.4px solid $color-bg-teamstag !important;
			}
			&.MuiSkeleton-root {
				background: $color-hover;
			}
		}
		&.not-two-digit {
			.MuiAvatarGroup-avatar {
				&.MuiAvatar-colorDefault:nth-child(3) {
					@include border-radius(168px);
					padding: 0 5px;
					width: auto;
					height: 18px;
					margin-top: 1px;
					font-size: $font-size-subtitle2;
					border: 0px;
				}
			}
		}
	}
	.contributors-avatar-group {
		@include flex;
		@include align-items(center);
		cursor: pointer;
		> .contributor-owner,
		> .MuiAvatar-colorDefault {
			margin-right: 0.5em;
			//margin-top: 3px;
			width: 40px;
			height: 40px;
			font-size: $font-size-h5;
		}
		// &:hover {
		// 	.contributor-details {
		// 		opacity: 1;
		// 		visibility: visible;
		// 		transform: translateY(0);
		// 	}
		// }
		&.no-contributors {
			.contributor-list {
				margin: 0;
			}
		}
		.avatar-default {
			width: 30px;
			height: 30px;
			box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.1);
			background: $color-bg-info;
			svg {
				path {
					fill: rgba($color-primary2, 0.5);
				}
			}
		}
	}
}
/*Global Contributor Chip CSS*/
.contributors-chip {
	ul {
		@include flex;
		flex-wrap: wrap;
		padding: 0px;
		&:before {
			display: none;
		}
		li {
			padding: 0 5px 0 0;
			background: $color-bg-tag;
			width: auto;
			border-radius: 40px;
			overflow: hidden;
			margin: 0.5em 0.5em 0 0;
			@include flex;
			&:before {
				display: none;
			}
			.MuiAvatar-root {
				//margin: 2px 0;
				width: 24px;
				height: 24px;
				border: 1.4px solid #fff;
			}
			.MuiTypography-body1 {
				padding-left: 0.5em;
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
			}
			button {
				min-width: auto;
				padding: 0.25em;
				border-radius: 100%;
				width: 18px;
				margin-left: 0.3125em;
				height: 18px;
				&:hover {
					background: none;
					svg {
						path {
							fill: $color-text-info;
						}
					}
				}
			}
			&.clear-all {
				padding: 0.25em 0.75em;
				background: $color-white;
				width: auto;
				@include border-radius(40px);
				overflow: hidden;
				margin: 0 0 0.5em 0.5em;
				@include flex;
				a {
					color: $color-primary1;
					text-decoration: none;
				}
			}
		}
	}
}
/*Global Popper Menu CSS*/
.popper-menu-list {
	margin-right: 1.65em;
	z-index: 9990;
	&.directReportMenu {
		z-index: 9;
		min-width: 14.625em;

		ul li svg {
			margin-right: 0em;
		}
	}
	&.team-list-menu {
		z-index: 9;
		min-width: 18.375em;
		ul li svg {
			margin-right: 0em;
		}
		&.directReportMenu {
			min-width: 14.625em;
			ul {
				max-height: 248px;
				overflow: auto;
				@include custom-scrollbar;
			}
		}
	}
	/**team filter list dropdown menu**/
	&.team-filter-list-menu {
		min-width: 14.71em;
		width: 15.71em;
		ul {
			max-height: 248px;
			overflow: auto;
			@include custom-scrollbar;
			li {
				padding: 0.61em 0.714em !important;
				svg {
					margin-right: 0em;
				}
				.MuiTypography-h5 {
					font-weight: $font-weight-regular;
				}
				.team-filter-txt {
					p {
						max-width: 92%;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
		&.direct-filter-menu {
			ul {
				li {
					p {
						max-width: 92%;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
	.MuiPaper-elevation1 {
		@include box-shadow(2px 0 10px rgba($color-primary2, 0.12));
		border-radius: 6px;
		ul {
			padding: 0;
			li {
				border-bottom: 1px solid rgba($color-input-field, 0.2);
				padding: 0.75em 1.5em;
				min-width: 170px;
				&:last-child {
					border-bottom: none;
				}
				&:hover {
					background: $color-hover;
				}
				&:first-child {
					border-radius: 6px 6px 0 0;
				}
				&:last-child {
					border-radius: 0px 0px 6px 6px;
				}
			}
		}
	}
}

.scroll-drag-info {
	position: fixed;
	bottom: 2em;
	right: 4%;
	z-index: 9;
	text-align: right;
	padding-right: 2.5em;
	background: url(../images/scroll-drag-icon.svg) right center no-repeat;
	.MuiTypography-body2 {
		color: $color-input-field !important;
	}
}

.custom-node-card {
	width: 17.5em;
	.MuiCardHeader-root {
		@include align-items(flex-start);
		.MuiCardHeader-content {
			text-align: left;
		}
		.MuiCardHeader-action {
			margin: -1em -1em 0 0;
		}
		.MuiCardHeader-avatar {
			margin-right: 0.75em;
		}
	}
	.MuiCardContent-root {
		text-align: left;
		&:last-child {
			padding-bottom: 1em;
		}
	}
	.people-okrs {
		min-width: 7.5em;
		text-transform: inherit;
		height: 2em;
		line-height: 2em;
		padding: 0 0.75em 0 1em;
		color: $color-hover;
		background: $color-primary1;
		font-size: 12px;
		font-weight: $font-weight-bold;
		box-shadow: none;
		border-radius: 0.5em;
		display: inline-block;
	}
}

/** Global Fit to Screen CSS **/
.fit-screen-action {
	@include flex;
	@include justify-content(flex-end);
	margin-left: 0.75em;

	button {
		min-width: auto;
		max-width: 32px;
		width: 32px;
		height: 32px;
		background: $color-white;
		border-radius: 100%;
		border: 1px solid $color-border-tile;
		@include flex;
		@include align-items(center);
		margin-left: 0.75em;
		&:hover {
			background: $color-secondary2;
		}
	}
}

.deleteIcon {
	svg {
		path {
			fill: $color-white;
		}
	}
}

.notification-delete {
	svg {
		path {
			fill: $color-white;
		}
	}
}

.global-head-action {
	button {
		margin-left: 0;
		&:last-child {
			margin-right: 0;
		}
	}
	.btn-circle-white {
		margin-right: 0.75em;
		margin-left: 0;
	}
	.search-btn {
		margin-right: 0.75em;
		.MuiInputBase-root {
			padding: 0px;
			border: 1.4px solid $color-border-tile;
			@include border-radius(40px);
			&.Mui-focused {
				border: 1.4px solid $color-primary1;
			}
		}
	}
	.download-pdf-loader {
		position: absolute;
		left: -3px;
		top: -3px;
		z-index: 1;
		.MuiCircularProgress-svg {
			color: $color-keyword-highlighter;
		}
	}
}

@media (min-width: 1400px) {
	.welcome-content {
		margin-top: 140px;
	}
}
.date-settings {
	.MuiInput-underline:before,
	.MuiInput-underline:after {
		display: none;
	}
	button {
		svg {
			width: 1em;
			height: 1em;
			path {
				fill: $color-primary1;
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
	.MuiInputBase-root {
		padding: 0;
		border: 0;
		margin: 0;
	}
	.calender-date {
		.MuiInputAdornment-positionEnd {
			// position: absolute;
			right: -0.5em;
		}
		button {
			position: absolute;
			right: 0;
			// bottom: 0;
			@include flex;
			@include align-items(center);
			@include justify-content(flex-end);
			width: 100%;
			&:hover {
				background-color: transparent;
			}
			svg {
				path {
					fill: $color-primary1;
				}
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
}

.allign-tooltip {
	width: 15px !important;
	height: 15px !important;
	padding: 0;
	font-size: 1em !important;
	margin-left: 0.35em;
	svg {
		width: 100%;
		height: 100%;
		path {
			fill: $color-keyword-highlighter;
		}
	}
}

.navbar {
	padding: 2em 3% 1.5em;
	position: sticky;
	top: 4em;
	left: 0;
	width: 100%;
	z-index: 9;
	transition: all 0.3s ease-in-out;
	* {
		transition: all 0.3s ease-in-out;
	}
	&.scrolled {
		padding: 1.5em 3% 0.5em;
		background: -moz-linear-gradient(
			top,
			rgba(229, 240, 255, 1) 76%,
			rgba(229, 240, 255, 0.99) 77%,
			rgba(229, 240, 255, 0.7) 100%
		);
		background: -webkit-linear-gradient(
			top,
			rgba(229, 240, 255, 1) 76%,
			rgba(229, 240, 255, 0.99) 77%,
			rgba(229, 240, 255, 0.7) 100%
		);
		background: linear-gradient(
			to bottom,
			rgba(229, 240, 255, 1) 76%,
			rgba(229, 240, 255, 0.99) 77%,
			rgba(229, 240, 255, 0.7) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#E5F0FF', endColorstr='#b3eef5ff',GradientType=0 );
	}
}

.contributor-details {
	&.MuiList-padding {
		padding: 0;
	}
	width: 260px;
	max-height: 296px;
	background: $color-white;
	z-index: 99999;
	box-shadow: 0px 10px 30px rgba(98, 98, 98, 0.1);
	border-radius: 0.5em;
	overflow: hidden;
	ul {
		display: block;
		padding: 0;
		li {
			@include flex;
			padding: 0.75em 0.5em 0.75em 1em;
		}
	}
	.contributor-parent {
		box-shadow: 0px 10px 30px rgba(98, 98, 98, 0.1);
	}
	.contributor-child {
		margin: 1em 0.5em 0 1em;
		max-height: 210px;
		overflow-y: auto;
		ul {
			li {
				padding: 0.5em 0;
			}
		}
	}
}
.MuiRadio-root {
	svg {
		path {
			fill: $color-primary2;
		}
	}
}

.camera-icon {
	position: absolute;
	left: 65px;
	width: 38px;
	top: 65px;
	height: 38px;
	background: $color-primary1;
	text-align: center;
	border-radius: 50%;
	label {
		width: 100%;
		height: 100%;
		display: block;
		text-align: center;
		line-height: 56px;
		cursor: pointer;
	}
	svg {
		width: 20px;
		path {
			fill: $color-hover;
		}
	}
}

/*V2 CSS*/

.default-dialog {
	z-index: 99999 !important;
	.MuiDialog-paper {
		padding: 1.5em 1.25em 1.25em;
		min-width: 432px;
		max-width: 432px;
		.MuiDialogContent-root {
			padding: 0;
			max-width: 100%;
			@include flex;
			@include align-items(center);
			flex-direction: column;
			text-align: center;
			@include animation(fade-in 1.8s);
			svg {
				margin-bottom: 1em;
				margin-left: 1em;
			}
			p {
				padding-bottom: 2em;
				margin: 0;
			}
		}
		.MuiDialogActions-root {
			padding: 0;
			@include animation(fade-in 2.1s);
			@include justify-content(center);
		}
	}
	&.relogin-dialog {
		.MuiDialog-paper {
			min-width: 450px;
			max-width: 450px;
			.MuiDialogContent-root {
				max-width: 100%;
				@include flex;
				@include align-items(center);
				flex-direction: column;
				text-align: center;
				svg {
					margin-left: 1em;
				}
			}
			.MuiDialogActions-root {
				@include justify-content(center);
				button {
					margin: 0;
				}
			}
		}
	}
}

// /*Search Contributor Popup css*/
// .search-contributor-popup {
// 	.MuiPopover-paper {
// 		width: 400px;
// 		min-height: 6.65em;
// 		max-height: unset;
// 		padding: 2.125em 0 0 0;
// 		box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
// 		@include border-radius(6px);
// 		overflow: hidden;
// 		.search-contributor-inner {
// 			position: relative;
// 			@include flex;
// 			@include align-items(center);
// 			> .MuiBox-root {
// 				width: 100%;
// 			}
// 			.icon-search {
// 				position: absolute;
// 				left: 1.875em;
// 				top: 0;
// 				animation: none;
// 				width: 40px;
// 				height: 40px;
// 				min-width: auto;
// 				svg {
// 					width: 20px;
// 					height: 20px;
// 				}
// 			}
// 			.rbt-input-main {
// 				padding-left: 5em;
// 				border: none;
// 				font-weight: $font-weight-regular;
// 				line-height: 2.5em;
// 				background-color: transparent;
// 				width: calc(100% - 1em);
// 			}
// 			.rbt-menu {
// 				width: 400px !important;
// 				max-height: 216px !important;
// 				background-color: transparent;
// 				box-shadow: none;
// 				border-top: none;
// 				&:before {
// 					position: absolute;
// 					top: 0;
// 					left: 5em;
// 					width: calc(100% - 5em);
// 					height: 1.4px;
// 					background-color: rgba($color-input-field, 0.5);
// 					content: '';
// 				}
// 				.dropdown-item {
// 					.user-info {
// 						padding-left: 1.875em;
// 						border: none;
// 					}
// 				}
// 			}
// 		}
// 	}
// 	&.search-add-contributor-popup {
// 		.MuiPopover-paper {
// 			width: 360px !important;
// 			padding-top: 2.75em;
// 			min-height: 8em;
// 			margin-left: -55px;
// 			margin-top: 10px;
// 			.search-contributor-inner {
// 				.rbt-input-main {
// 					padding-left: 5.4em;
// 				}
// 				.rbt-menu {
// 					margin-top: 10px;
// 					width: 360px !important;
// 					max-height: 198px !important;
// 				}
// 			}
// 		}
// 	}
// }
.welcome-content {
	&.dashboard,
	&.teams-dashboard {
		margin-top: 3em;
		margin-bottom: 12em;
	}
	object {
		margin-bottom: 2em;
	}
}
/*Bounce Loading effect*/
.spinner {
	width: 50px;
	height: 1em;
	line-height: 1;
	text-align: center;
}

.spinner > div {
	width: 0.5em;
	height: 0.5em;
	background-color: $color-primary2;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.contributor-details-popover {
	background: $color-white;
	box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
	border-radius: 0.375em;
	min-width: 470px;
	z-index: 99999;
	max-height: 360px;
	overflow-y: auto;
	max-width: 627px;
	@include custom-scrollbar;
	&.objectiveContributors {
		max-width: 627px;
	}
	&.createdPopover {
		margin-top: -2.8em;
	}
	&.teamPopover {
		width: 400px;
	}
	&.virtual-contributors-list {
		min-height: 280px;
		max-height: 280px;
	}
	.contributors-details-head,
	.virtual-contributor-actions {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 0.625em 0.875em 0 0.875em;
		.contributor-label {
			.MuiTypography-body2 {
				text-transform: uppercase;
				color: rgba($color-primary2, 0.5);
			}
		}
		.back-btn {
			padding: 0;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			color: $color-primary1;
			svg {
				margin-right: 0.375em;
			}
			&:hover {
				background: none;
			}
		}
		.btn-link {
			margin: 0;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			height: auto;
			line-height: $line-height5;
			svg {
				width: 14px;
				height: 14px;
				margin-right: 0.375em;
				circle {
					stroke: $color-primary1;
				}
				path {
					fill: $color-primary1;
				}
			}
			&:hover {
				text-decoration: none;
			}
		}
		.virtual-link-btn {
			&.active {
				.btn-link {
					color: rgba($color-primary2, 0.5);
					svg {
						circle {
							stroke: rgba($color-primary2, 0.5);
						}
						path {
							fill: rgba($color-primary2, 0.5);
						}
					}
				}
			}
		}
	}
	.virtual-contributor-actions {
		padding: 0.625em 0.875em;
	}
	ul.contributor-details-list {
		padding: 0px 0;
		position: relative;
		width: 100%;
		border-radius: 0.375em;
		&:before {
			content: '';
			width: 2px;
			background: $color-primary1;
			height: calc(100% - 51px);
			position: absolute;
			left: 34px;
			top: 30px;
		}
		&.contributorsList {
			overflow-y: auto;
			@include custom-scrollbar;
			border-radius: 0;
		}
		&.virtualAlignment {
			max-height: 205px;
			min-height: calc(280px - 70px);
			overflow-y: auto;
			@include custom-scrollbar;
			border-radius: 0;
			&:before {
				display: none;
			}
		}
		li {
			@include flex;
			@include justify-content(space-between);
			padding: 0.625em 0.875em;
			cursor: pointer;
			.contributordetails-left {
				@include flex;
				.MuiListItemAvatar-root {
					min-width: 40px;
					margin-right: 3px;
					&.owner-avatar {
						width: 40px;
						height: 40px;
						border-radius: 100%;
						border: 2px solid $color-primary1;
						background-color: $color-white;
						@include flex;
						@include align-items(center);
						.avatar-default {
							width: 30px;
							height: 30px;
						}
					}
					&.parent-avatar {
						.avatar-default {
							max-width: 40px;
							max-height: 40px;
						}
					}
				}

				.contributor-name {
					@include flex;
					@include align-items(center);
					margin: 0;
					.MuiTypography-body1 {
						font-weight: $font-weight-bold;
					}
					.MuiListItemText-secondary {
						padding: 0 0.625em 0 0.625em;
						color: $color-primary2;
						background: rgba($color-primary2, 0.1);
						font-size: 10px;
						line-height: 16px;
						font-weight: $font-weight-bold;
						border-radius: 0.1875em;
						margin-left: 0.625em;
						letter-spacing: 0.1px;
						@include flex;
						@include align-items(center);
						svg {
							max-width: 10px;
							margin-right: 3px;
						}
					}
				}
				.avatar-default {
					max-width: 30px;
					max-height: 30px;
					margin: 0 auto;
					font-size: $font-size-h6;
					img {
						@include border-radius(100%);
					}
				}
			}
			.contributordetails-right {
				text-align: right;
			}

			&:hover {
				background-color: rgba($color-primary2, 0.1);
			}
			.contributor-info {
				@include flex;
				@include justify-content(center);
				@include flex-direction(column);
			}
		}
	}
	ul.contributor-details-list.hide-linking:before {
		background: unset;
	}
	//kr contributer head
	.contributors-details-targethead {
		padding-top: 0.625em;
		.col-1 {
			width: 56%;
			padding-right: 0.3125em;
		}
		.col-2 {
			width: 20%;
		}
		.col-3 {
			width: 25%;
			padding-left: 0.3125em;
			.contributor-label {
				.MuiTypography-body2 {
					text-align: right;
				}
			}
		}
	}
	.show-target {
		li {
			@include align-items(flex-start);
		}
		.contributordetails-left {
			width: 56%;
			padding-right: 0.3125em;
			.tooltip-text {
				display: block;
				max-width: 140px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.contributordetails-middle {
			width: 20%;
			.MuiTypography-body2 {
				@include flex;
				@include align-items(flex-start);
			}
			svg {
				max-width: 10px;
				margin-right: 0px;
				max-height: 17px;
				margin-top: -1px;
				display: inline-block;
			}
		}
		.contributordetails-right {
			width: 25%;
			padding-left: 0.3125em;
			.pending-text,
			.declined-text {
				display: initial;
			}
			p {
				svg {
					max-width: 10px;
					max-height: 16px;
					display: inline-block;
					vertical-align: top;
				}
				&.dollarCurrency {
					svg {
						margin-right: -1px;
						margin-left: -2px;
					}
				}
				&.onTrack {
					svg {
						path {
							fill: $color-on-track;
						}
					}
				}
				&.atRisk {
					svg {
						path {
							fill: $color-text-info;
						}
					}
				}
				&.lagging {
					svg {
						path {
							fill: $color-lagging;
						}
					}
				}
			}
		}
	}
}
.users-wrapper {
	.user-head-action {
		.search-btn {
			.MuiInputBase-root.Mui-focused {
				padding-right: 10px;
			}
		}
		.user-contributors-chip {
			right: 4em;

			ul {
				flex-wrap: nowrap;
				li.clear-all {
					min-width: 88px;
					margin-left: 0;
				}
				li.search-more {
					width: 30px;
					height: 30px;
					border-radius: 100%;
					text-align: center;
					@include justify-content(center);
					background: $color-white;
					border: 1px solid $color-input-field;
					font-size: 12px;
					font-weight: $font-weight-bold;
				}
				li {
					button {
						width: 20px;
					}
				}
			}
		}
	}
}
.user-search-details-popover {
	background: $color-white;
	box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
	border-radius: 0.375em;
	min-width: 280px;
	max-width: 280px;
	z-index: 99999;
	margin-right: -10px !important;
	ul.search-details-list {
		padding: 0px 0;
		position: relative;
		max-height: 255px;
		overflow-y: auto;
		width: 100%;
		border-radius: 0.375em;
		@include custom-scrollbar;

		li {
			@include flex;
			@include justify-content(space-between);
			padding: 0.7em 1em !important;
			cursor: initial;
			border-bottom: 1px solid rgba($color-input-field, 0.2);
			@include align-items(flex-start);
			font-size: 14px;
			.MuiListItemText-root {
				max-width: 90%;
				cursor: text;
				.MuiTypography-root {
					word-break: break-all;
					display: inline-block;
					max-width: 100%;
					width: 100%;
					white-space: initial;
				}
			}
			.MuiButtonBase-root {
				padding: 2px;
				background: none;
				width: 15px;
				.MuiTouchRipple-root {
					display: none;
				}
			}
			&:last-child {
				border-bottom: 0px;
			}
			&:hover {
				background-color: rgba($color-primary2, 0.1);
			}
		}
	}
}
/**loader of drawer area**/
.okr-drawer-area {
	div[class*='makeStyles-root-'] {
		position: relative !important;
	}
	.MuiLinearProgress-root {
		width: 100%;
		left: 0px;
		position: absolute !important;
	}
}

.owner-details {
	.myself-owner .MuiTypography-body2 {
		padding: 0 0.625em 0 0.625em;
		color: rgba($color-primary2, 0.5);
		background: rgba($color-primary2, 0.1);
		font-size: 10px;
		line-height: 16px;
		font-weight: $font-weight-bold;
		border-radius: 0.1875em;
		margin-left: 0.625em;
		letter-spacing: 0.1px;
		@include flex;
		@include align-items(center);
	}
}

.org-not-found {
	object {
		margin-top: 2em;
	}
}
/*Guided Tour CSS*/
body {
	#react-joyride-step-0 {
		.__floater {
			left: 50px !important;
			top: auto !important;
			bottom: 40px;
			transform: none !important;
		}
	}
	.__floater {
		padding: 0 !important;
		margin: 0 -10px;
		.__floater__arrow {
			display: none;
		}
		.react-joyride__tooltip {
			padding: 0 10px !important;
			@include flex;
			.guided-tour {
				text-align: left;
				.guided-step-text {
					padding-bottom: 1.25em;
				}
				.guided-menu {
					max-width: calc(100% - 80px);
					border-radius: unset;
					padding: 0;
					svg {
						position: absolute;
						right: 5px;
						z-index: 0;
						cursor: pointer;
					}
					.MuiSelect-select {
						padding: 0.5em 1em 0.5em 0;
						color: $color-hover;
						z-index: 1;
					}
					fieldset {
						border: none;
						&:hover {
							border: none;
						}
						&:focus {
							outline: none;
						}
					}
				}
			}
		}
		.MuiTypography-body1 {
			color: $color-white;
		}
		.MuiTypography-h4 {
			color: $color-white;
			padding-bottom: 0.625em;
		}
		button[aria-label='Next'],
		button[aria-label='Got it!'] {
			position: absolute;
			right: 10px;
			bottom: 20px;
			min-width: 5em;
			text-align: center;
			padding: 0 !important;
			@include border-radius(20px!important);
			.MuiTypography-body1 {
				padding: 0.625em;
			}
		}
		button[aria-label='Close'] {
			svg {
				path {
					fill: $color-white;
					stroke: $color-white;
				}
			}
		}
	}
	.guided-tour-popper {
		background-color: rgba($color-primary2, 0.5);
		box-shadow: 0px 30px 30px rgba($color-primary2, 0.3);
		.MuiList-root {
			padding: 0;
			li[data-value='0'] {
				display: none;
			}
			.MuiMenuItem-root {
				color: $color-hover;
				padding-top: 0.75em;
				padding-bottom: 0.75em;
				border-bottom: 1.4px solid $color-hover;
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}
.loading-container {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99999;
	width: 100%;
	height: 100vh;
	//background: $color-primary2;
	.pulse {
		border: 3px solid $color-keyword-highlighter;
		height: 30px;
		width: 30px;
		position: absolute;
		margin-left: -15px;
		margin-top: -15px;
		animation: pulsate 1s ease-out;
		animation-iteration-count: infinite;
		border-radius: 30px;
		opacity: 0;
		left: 50%;
		top: 50%;
	}
}

.orgList {
	table {
		tr {
			&:nth-child(odd) {
				background-color: #f5f5f5;
			}
			&:hover {
				background-color: #f1f1f1;
			}
		}
		th {
			background: $color-primary2;
			color: #fff;
		}
		td {
			padding: 10px 16px;
		}
	}
}
.download-dropdown {
	margin-left: 1em;
	.MuiSelect-selectMenu {
		background: none !important;
	}
}
.drawer-loader {
	position: absolute;
	top: 0px;
	width: 100%;
	left: 0px;
}
