@import './mixins';
/*Color Settings*/
/*Primary Color*/
$color-primary1: #39a3fa;

$color-primary2: #292929;

/*Secondary Colors*/
$color-secondary1: #738fb5;
$color-secondary2: #e5f0ff;

/*Other Colors*/
$color-bg-form: #f4f8ff;
$color-bg-info: #ffeded;
$color-bg-teamstag: #f7f7f7;
$color-bg-tag: #f1f3f4;
$color-bg-dot: #849ec1;
$color-bg-teamtag-selected: #d3e0ea;
$color-bg-tabscroller: #f8f9fb;
$color-bg-filterbtn: #deecfe;
$color-bg-loader: #dcdbdc;
$color-bg-radial-report: rgba(244, 248, 255, 0.5);
$color-bg-profile: rgba(212, 223, 237, 1);
$color-bg-krscore: #f8f9fa;
$color-border-tile: #cee3ff;
$color-border-tileLight1: rgba(206, 227, 255, 0.9);
$color-border-info: #f3c3c3;
$color-border-krscore: #e1e1e2;
$color-text-selection: #debdff;
$color-text-info: #ff0000;
$color-text-teamtag: #19456b;
$color-white: #ffffff;
$color-tags: #cb6228;
$color-completed: #0cbc74;
$color-threshold: #416390;
$color-connected-line: #909090;
$color-expanded-tiles: #d5e7fd;
$color-keyword-highlighter: #cb00ff;
$color-okr-highlighter: #feede3;
$color-hover: #efefef;
$color-input-field: #a1abb9;
$color-on-track: #07a363;
$color-not-started: #d5dae1;
$color-lagging: #ff9401;
$color-report-map-20: #f5a460;
$color-report-map-40: #d16f7b;
$color-report-map-60: #a66a7f;
$color-report-map-80: #655a74;
$color-report-map-100: #445a75;
$color-checked-in-missed: #d7d7d7;
$color-editor-bar: #fafafa;
$color-bubble: rgba(227, 242, 250, 0.9);

$font-family-lato: 'Lato', sans-serif;

$font-family-other: 'indie_flowerregular';

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-thin: 300;

$font-size-base: 16px;
$font-size-h1: 26px;
$font-size-h2: 20px;
$font-size-h3: $font-size-h2;
$font-size-h4: $font-size-base;
$font-size-h5: 14px;
$font-size-h6: 12px;
$font-size-p-body1: $font-size-base;
$font-size-p-body2: $font-size-h6;
$font-size-subtitle1: $font-size-h5;
$font-size-subtitle2: 10px;

$line-height1: 30px;
$line-height2: 22px;
$line-height3: 20px;
$line-height4: 18px;
$line-height5: 16px;

$full-width: 100%;
$header-height: 64px;
