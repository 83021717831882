/*Progress bar css*/
.my-obj-listing,
.create-okr-card {
	.obj-progress {
		width: 100%;
		max-width: calc(100% - 40px);
		min-height: 45px;
		> .kr-progress {
			max-width: 100% !important;
		}
		.obj-progress-slider {
			@include flex;
			@include align-items(flex-end);
			.progress-timestamp-info {
				width: 43px;
				margin-right: 10px;
				@include flex;
				@include justify-content(flex-end);
			}
			.MuiTypography-h2 {
				color: $color-threshold;
				sup {
					font-size: $font-size-h6;
					line-height: $font-size-h6;
					vertical-align: super;
				}
			}
		}
		button {
			padding: 0;
			border: none;
			background: transparent;
			width: 100%;
			text-align: left;
		}
		.MuiSlider-root {
			padding: 0;
			width: calc(100% - 53px);
			height: 22px;
			display: block;
			margin: 0.125em 0;
			@include border-radius(22px);
			overflow: hidden;
			.MuiSlider-rail {
				height: 22px;
				opacity: 1;
				background: $color-not-started;
				@include border-radius(22px);
			}
			.MuiSlider-track {
				height: 22px;
				@include border-radius(22px);
			}
			.Mui-disabled {
				display: none;
			}
			.MuiSlider-thumb {
				width: 14px;
				height: 14px;
				margin-top: -7px;
				margin-left: -5px;
				top: 50%;
				background: $color-white;
				border: 1px solid $color-input-field;
				opacity: 0.5;
			}
		}
		.MuiTypography-h6 {
			margin-left: 53px;
			white-space: no-wrap;
		}
	}
	.kr-progress {
		.kr-progress-slider {
			@include flex;
			@include align-items(flex-end);
			.progress-timestamp-info {
				width: 43px;
				margin-right: 10px;
				@include flex;
				@include justify-content(flex-end);
			}
			.MuiTypography-h2 {
				color: $color-threshold;
				sup {
					font-size: $font-size-h6;
					line-height: $font-size-h6;
					vertical-align: super;
				}
			}
		}
		button {
			padding: 0;
			border: none;
			background: transparent;
			width: 100%;
			text-align: left;
		}
		.MuiSlider-root {
			padding: 0;
			width: calc(100% - 53px);
			height: 16px;
			display: block;
			margin: 3px 0;
			@include border-radius(16px);
			overflow: hidden;
			.MuiSlider-rail {
				height: 16px;
				opacity: 1;
				background: $color-not-started;
				@include border-radius(16px);
			}
			.MuiSlider-track {
				height: 16px;
				@include border-radius(16px);
			}
			.Mui-disabled {
				display: none;
			}
			.MuiSlider-thumb {
				opacity: 0;
				margin-top: 2px;
				margin-left: -14px;
				@include transition(default);
				background: rgba($color-white, 0.5);
				&[aria-valuenow~='0'] {
					margin-left: 0px;
				}
				&:hover {
					box-shadow: unset;
				}
				&:after {
					display: none;
				}
				&.MuiSlider-active,
				&.Mui-focusVisible {
					box-shadow: unset;
				}
				.MuiSlider-valueLabel {
					top: -3em;
					left: -50%;
					transform: translate(-46%);
					@include transition-default;
					span[class^='PrivateValueLabel-circle'],
					> span {
						transform: rotate(0);
						width: auto;
						min-width: 3.5em;
						height: 2.25em;
						@include border-radius(6px);
						background: rgba($color-input-field, 0.5);
						opacity: 0.5;
						span[class^='PrivateValueLabel-label'],
						> span {
							transform: rotate(0);
							font-size: $font-size-base;
							font-weight: $font-weight-bold;
							line-height: $line-height2;
							color: $color-primary2;
							padding: 0 0.625em;
							@include flex;
							@include align-items(baseline);
							.MuiTypography-body2 {
								color: $color-primary2;
							}
							.hover-currency-tooltip {
								@include flex;
							}
							.hover-currency-icon {
								@include flex;
								@include align-items(center);
								svg {
									max-width: 11px;
									max-height: 14px;
								}
							}
							.MuiTypography-h4 {
								margin-right: 0.1875em;
							}
						}
					}
					&:after {
						content: '';
						width: 0px;
						height: 0px;
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-top: 9px solid rgba($color-input-field, 0.5);
						position: absolute;
						bottom: -9px;
						left: 50%;
						margin-left: -6px;
						opacity: 0.5;
					}
				}
			}
		}
		&:hover {
			.MuiSlider-root {
				.MuiSlider-thumb {
					.MuiSlider-valueLabel {
						span[class^='PrivateValueLabel-circle'],
						> span,
						&:after {
							opacity: 1;
						}
					}
				}
			}
			.hover-currency-icon {
				svg {
					path {
						fill: $color-primary2;
						opacity: 1;
					}
				}
			}
		}
		.kr-settings-value {
			margin-left: 53px;
			@include flex;
			@include justify-content(space-between);
			svg {
				width: 10px;
				height: 10px;
				path {
					fill: $color-secondary1;
					stroke: $color-secondary1;
				}
			}
			h6 {
				@include flex;
				@include align-items(center);
			}
		}
		.kr-progress-action {
			margin-left: 53px;
			white-space: no-wrap;
		}
		&.no-cursor {
			.MuiSlider-root {
				cursor: text;
			}
		}
	}
	.create-kr {
		.obj-progress {
			.MuiSlider-root {
				height: 6px;
				.MuiSlider-rail,
				.MuiSlider-track {
					height: 6px;
				}
			}
		}
	}
	.kr-listing-content {
		.my-obj-listing-inner {
			&:hover {
				.kr-progress {
					.MuiSlider-root {
						.MuiSlider-thumb {
							opacity: 1;
						}
					}
				}
				.future-progress,
				.past-progress,
				.people-progress {
					.MuiSlider-root {
						.MuiSlider-thumb {
							opacity: 0;
						}
					}
				}
			}
		}
	}
}
