body {
	.custom-snackbar {
		width: 360px;
		z-index: 9992;
		.MuiSnackbarContent-root {
			width: 100%;
			@include flex;
			@include align-items(center);
			padding: 0.5em 2em 0.5em 5em;
			background-color: $color-primary2;
			background-image: url(../../images/alert-info.svg);
			background-position: 1.5em center;
			background-repeat: no-repeat;
			box-shadow: 0px 25px 20px rgba($color-primary2, 0.2);
			@include border-radius(6px);
			font-weight: $font-weight-regular;
			font-size: $font-size-base;
			color: $color-white;
			min-height: 4.875em;
			&.custom-snackbar-success {
				background-image: url(../../images/alert-success.svg);
			}
			&.custom-snackbar-error,
			&.custom-snackbar-warning {
				background-image: url(../../images/alert-error.svg);
			}
			&.custom-snackbar-info {
				background-image: url(../../images/alert-info.svg);
			}
		}
		.MuiSnackbarContent-action {
			position: absolute;
			right: 0.625em;
			top: 0.625em;
			margin: 0;
			padding: 0;
			button {
				min-width: auto;
				width: 1.5em;
				height: 1.5em;
				padding: 0;
				border-radius: 50%;
				background: rgba($color-white, 0.2);
				svg {
					width: 0.625em;
					height: 0.625em;
					path {
						fill: $color-white;
					}
				}
			}
		}
	}
}
