/**weight selection css start here**/
.weight-selection {
	.weight-group {
		margin-top: 8px;
		max-width: 250px;
		label {
			margin: 0 0.625em 0 0;
			.MuiButtonBase-root {
				display: none;
				&.Mui-checked {
					& + .MuiFormControlLabel-label {
						background: $color-secondary2;
						border-color: $color-primary1;
						color: $color-primary1;
						&:hover {
							box-shadow: none;
							background: $color-secondary2;
							border-color: $color-primary1;
							color: $color-primary1;
						}
					}
				}
			}
			.MuiFormControlLabel-label {
				font-size: $font-size-h5;
				line-height: $line-height3;
				color: $color-secondary1;
				border: 1.4px solid $color-secondary1;
				@include border-radius(100px);
				min-width: 38px;
				min-height: 23px;
				padding: 0 0.625em;
				margin: 0 0 0 0;
				text-align: center;
				background-color: $color-white;
				&.Mui-disabled {
					border-color: rgba($color-primary2, 0.3);
					color: rgba($color-primary2, 0.5);
					box-shadow: none;
				}
				&:hover {
					background-color: $color-hover;
					@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
					&.Mui-disabled {
						box-shadow: none;
						background-color: transparent;
					}
				}
			}
			&.Mui-disabled {
				opacity: 1 !important;
			}
		}
	}
}
