//bounce animation
@keyframes bounce {
	from,
	20%,
	53%,
	80%,
	to {
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: translate3d(0, 0, 0);
	}

	40%,
	43% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -30px, 0);
	}

	70% {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
		transform: translate3d(0, -15px, 0);
	}

	90% {
		transform: translate3d(0, -4px, 0);
	}
}

// bounce delay animation
@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}
@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

//plus animation
@-webkit-keyframes pulse {
	0% {
		@include box-shadow(0 0 0 0 rgba(57, 163, 250, 0.4));
	}
	70% {
		@include box-shadow(0 0 0 10px rgba(57, 163, 250, 0));
	}
	100% {
		@include box-shadow(0 0 0 0 rgba(57, 163, 250, 0));
	}
}
@keyframes pulse {
	0% {
		@include box-shadow(0 0 0 0 rgba(57, 163, 250, 0.4));
	}
	70% {
		@include box-shadow(0 0 0 10px rgba(57, 163, 250, 0));
	}
	100% {
		@include box-shadow(0 0 0 0 rgba(57, 163, 250, 0));
	}
}

//slide up animation
@keyframes slideUp {
	from {
		opacity: 0;
		@include transform(translateY(-50px));
	}
	to {
		opacity: 1;
		@include transform(translateY(0));
	}
}

@-webkit-keyframes slideUp {
	0% {
		opacity: 0;
		@include transform(translateY(-50px));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@-moz-keyframes slideUp {
	0% {
		opacity: 0;
		@include transform(translateY(-50px));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}

//slide in animation
@keyframes slideIn {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 30px;
	}
}

@-webkit-keyframes slideIn {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 30px;
	}
}
@-moz-keyframes slideIn {
	from {
		opacity: 0;
		bottom: 0;
	}
	to {
		opacity: 1;
		bottom: 30px;
	}
}

//pluse animation
@keyframes pulse_animation {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1.1);
	}
	40% {
		transform: scale(1.21);
	}
	50% {
		transform: scale(1);
	}
	60% {
		transform: scale(1);
	}
	70% {
		transform: scale(1.09);
	}
	80% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

//fade in animation
@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

//fade out animation
@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

//plus state animation
@keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

///
@-webkit-keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes slide-up {
	from {
		opacity: 0;
		@include transform(translateY(-5%));
	}
	to {
		opacity: 1;
		@include transform(translateY(0));
	}
}

@-webkit-keyframes slide-up {
	0% {
		opacity: 0;
		@include transform(translateY(-5%));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@-moz-keyframes slide-up {
	0% {
		opacity: 0;
		@include transform(translateY(-5%));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@keyframes slide-down {
	from {
		opacity: 0;
		@include transform(translateY(25%));
	}
	to {
		opacity: 1;
		@include transform(translateY(0));
	}
}

@-webkit-keyframes slide-down {
	0% {
		opacity: 0;
		@include transform(translateY(25%));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@-moz-keyframes slide-down {
	0% {
		opacity: 0;
		@include transform(translateY(25%));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@keyframes slide-up-big {
	from {
		opacity: 0;
		@include transform(translateY(-100px));
	}
	to {
		opacity: 1;
		@include transform(translateY(0));
	}
}

@-webkit-keyframes slide-up-big {
	0% {
		opacity: 0;
		@include transform(translateY(-100px));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@-moz-keyframes slide-up-big {
	0% {
		opacity: 0;
		@include transform(translateY(-100px));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
@-webkit-keyframes fade-in-row {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	} /* 20% of 5 seconds = 1 second */
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes fade-in-row {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	} /* 20% of 5 seconds = 1 second */
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in-row {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	} /* 20% of 5 seconds = 1 second */
	80% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes rotation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@-webkit-keyframes rotation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@-moz-keyframes rotation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

@keyframes mymove {
	50% {
		@include box-shadow(0 0 12px rgba($color-text-info, 0.5));
	}
}
@-webkit-keyframes mymove {
	50% {
		@include box-shadow(0 0 12px rgba($color-text-info, 0.5));
	}
}
@-moz-keyframes mymove {
	50% {
		@include box-shadow(0 0 12px rgba($color-text-info, 0.5));
	}
}
