.popper-wrap {
	z-index: 9999;
	//width: 100%;
	//max-width: 354px;
	.popper-inner {
		background: $color-white;
		@include box-shadow(0px 0px 30px rgba($color-primary2, 0.12));
		@include border-radius(6px);
		margin-top: 0.5em;
		max-width: 295px;
		min-width: 295px;
		max-height: 290px;
		width: 100%;
		overflow-y: auto;
		@include custom-scrollbar;
		&.team-chip-hover {
			.MuiListItem-root {
				&:hover {
					background: $color-hover;
				}
			}
		}
	}
	.MuiPaper-root {
		box-shadow: none;
	}
}
