@import '../sass-utils/variables';

.primary-search-field {
	z-index: 1;
	width: calc(100% - 95px);
	.rbt-menu {
		@include box-shadow(0px 5px 30px 8px rgba($color-primary2, 0.1));
		border-top: 0px;
		@include border-radius(6px);
		margin-top: -3px;
	}
	.rbt-input-main {
		padding: 0 2em 0 2.5em;
		height: $header-height;
		font-weight: $font-weight-regular;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary2;
		width: 100%;
		border: none;
		// background-color: transparent;
		background: url(../../images/search-sprite.svg) no-repeat 0 0;
		background-position: 10px 26px;
		font-family: $font-family-lato;
		&:focus {
			background-position: 10px -88px;
		}
	}
}
.rbt-input-hint {
	display: none;
}
.rbt-menu {
	background-color: $color-white;
	@include box-shadow(0px 25px 30px rgba(0, 0, 0, 0.1));
	max-height: 330px !important;
	border-radius: 0 0 6px 6px;
	border-top: 1.4px solid $color-hover;
	padding: 0;
	margin: 0;
	z-index: 1;
	width: 28em !important;
	a {
		color: $color-input-field;
		display: block;
		height: auto;
		overflow: unset;

		&.disabled {
			text-align: center;
			height: 100%;
			padding-bottom: 1em;
			padding-top: 1em;
			cursor: default;
		}

		.search-no-record {
			height: 100%;
			padding: 0.625em;
			h2 {
				margin-bottom: 0.5em;
			}
			img,
			object {
				margin-top: 1.5625em;
				width: 57%;
			}
		}
		.user-info {
			padding: 0.625em 0.875em;
			.user-info-details {
				@include flex;
				@include justify-content(space-between);
				flex: 1;
				@include align-items(center);
			}
			&:hover {
				background: $color-hover;
			}
		}

		&.rbt-menu-pagination-option {
			padding-top: 1em;
			color: $color-primary1;
			padding-bottom: 1em;
		}
	}
}

.rbt-loader {
	display: none !important;
}
