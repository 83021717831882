/**tab css start here**/
.okr-tabs {
	margin-right: 1em;
	min-height: 60px !important;
	position: relative;
	.MuiTab-root {
		min-width: auto;
		min-height: 60px;
		opacity: 1;
		font-size: $font-size-base;
		font-weight: $font-weight-bold;
		line-height: 1.6875em;
		margin: 0 0.5em;
		.MuiTab-wrapper {
			@include flex;
			@include align-items(center);
			flex-direction: unset;
			svg {
				width: 1em;
				height: 1em;
				margin: 0 0.625em 0 0;
			}
		}
		&.Mui-selected {
			color: $color-primary1;
			&:not(.not-filled-icon) {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}

			&.my-okr-tab.selected {
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
		}

		&.btn-circle-white {
			margin-top: 0.6em;
			min-width: auto;
			max-width: 40px;
			min-height: auto !important;
			svg {
				margin: 0;
			}
		}
	}
	.MuiTabs-indicator {
		height: 3px;
		background-color: $color-primary1;
	}
	/**drawer sub tabs css start here**/
	&.drawer-tabs {
		padding: 0 3em;
		margin: 0px;
		&::after {
			content: '';
			border-bottom: 1px solid rgba($color-primary2, 0.2);
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
		}
		.MuiTab-root {
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			&.Mui-selected {
				color: $color-primary1;
			}
		}
		.MuiTabs-indicator {
			height: 2px;
		}
		.MuiTabs-fixed {
			margin-left: -0.4375em;
		}
	}

	/**Myokrs tab dashbord css start here**/
	.my-okr-tab {
		.arrowIcon {
			padding: 0 5px;
			display: block;
			svg {
				width: 10px;
				height: 8px;
				margin: 3px 0 0 0px;
				path {
					stroke: $color-primary2;
					stroke-width: 2px;
				}
			}
		}
		&.Mui-selected {
			.arrowIcon {
				svg {
					path {
						stroke: $color-primary1;
						stroke-width: 3px;
					}
				}
			}
		}
	}
	.btn-text-wrap {
		position: relative;
	}
}
.okr-oval-tabs {
	padding: 1.25em 3.75em;
	margin: 0px;
	.MuiTabs-indicator {
		display: none;
	}
	.MuiTab-root {
		background-color: transparent;
		border: 1px solid rgba($color-input-field, 0.5) !important;
		color: $color-primary2;
		@include border-radius(3em);
		font-weight: $font-weight-regular;
		@include transition-default;
		min-width: 190px;
		min-height: 36px;
		height: 36px;
		line-height: 36px;
		opacity: 1;
		.MuiTab-wrapper {
			flex-direction: inherit;
			> *:first-child {
				margin-bottom: 0;
			}
		}
		&.MuiTab-labelIcon {
			padding-top: 6px;
		}
		&.Mui-selected {
			background-color: $color-secondary2;
			border-color: $color-primary1 !important;
		}
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		svg {
			width: 16px;
			height: 16px;
			margin-right: 0.5em;
		}
	}
}
@media screen and (max-width: 1300px) {
	.page-subheader-home {
		.okr-tabs {
			.MuiTab-root {
				margin-left: 0.25em;
				margin-right: 0.25em;
			}
		}
	}
}
