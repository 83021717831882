@import '../../sass-utils/variables';

.about-main-wrapper {
	z-index: 9999 !important;
	.about-us-wrapper {
		padding: 0 1.863em 1.3em;
		width: 27.143em;
		background: url('../../../images/about-bg.png') no-repeat 0 bottom;
		.logo-img {
			padding-bottom: 6.25em;
			margin-top: -0.5em;
			img {
				width: 55%;
			}
		}
		.inner {
			.about-info {
				font-size: $font-size-base;
				line-height: 22px;
				color: rgba($color-primary2, 0.5);
				padding-bottom: 1.8em;
				p {
					a {
						text-decoration: underline;
						color: $color-primary1;
					}
					&.about-copy-reserve {
						padding-top: 15px;
					}
				}
				.seprator {
					display: inline-block;
					padding: 0 8px;
				}
			}
			.warning-info {
				font-size: $font-size-h6;
				line-height: 18px;
				color: rgba($color-primary2, 0.5);
			}
		}
	}
	.close-icon-right {
		padding: 0.6em 0.6em 0;
		@include flex;
		@include justify-content(flex-end);
	}
}

.atlwdg-trigger.atlwdg-TOP {
	display: none;
}
