@import '../../../styles/sass-utils/variables';

//new css for assign user dropdown by react select
.user-role-dropdown {
	.user-role__control {
		background: $color-bg-form;
		padding: 0.5em 3.75em 0.5em 1.3125em;
		min-height: 40px;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		cursor: pointer;
		&:hover {
			border: 1.4px solid rgba($color-input-field, 0.5);
		}
		&.user-role__control--is-focused {
			border: 1.4px solid $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		&.user-role__control--menu-is-open {
			z-index: 9;
			@include border-radius(6px 6px 0 0);
			border-bottom: 0px;
			box-shadow: none;
			.user-role__dropdown-indicator {
				@include transform(rotate(180deg));
			}
		}
		.user-role__placeholder {
			color: rgba($color-primary2, 0.5);
			font-weight: $font-weight-regular;
		}
		.user-role__indicators {
			.user-role__indicator-separator,
			.user-role__clear-indicator {
				display: none;
			}
			.user-role__dropdown-indicator {
				background: url(../../../images/down-arrow.svg) no-repeat center center;
				width: 13px;
				height: 13px;
				margin-right: 0.375em;
				@include transition(0.3s);
				position: absolute;
				right: 10px;
				svg {
					display: none;
				}
			}
		}
		.user-role__value-container {
			padding: 0px;
			.user-role__single-value {
				margin: 0px;
			}
		}
		.MuiTypography-body2 {
			color: $color-secondary1;
			font-weight: $font-weight-bold;
			white-space: normal;
		}
	}

	.user-role__menu {
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));

		@include border-radius(6px);
		border: 1.4px solid $color-primary1;
		border-top: 0px;
		background: $color-white;
		width: 419px;
		margin: 0 0 0 0;
		padding-top: 0;
		overflow: hidden;
		//z-index: 99;
		top: 0px;
		padding-top: 40px;
		> div {
			@include custom-scrollbar;
		}
		.user-role__menu-list {
			padding: 0;
			margin: 0;
			max-height: 217px;
			.user-role__option {
				border-bottom: 1.4px solid $color-hover;
				padding: 0.625em 4.0625em 0.625em 1.25em;
				@include flex;
				@include align-items(center);
				min-height: 40px;
				cursor: pointer;
				&:last-child {
					border-bottom: 0px;
				}
				.MuiTypography-body2 {
					color: $color-secondary1;
					font-weight: $font-weight-bold;
					white-space: normal;
				}
				&:hover {
					background: $color-hover !important;
				}
				&.user-role__option--is-selected {
					background: $color-secondary2 !important;
					color: $color-primary1;
					&:hover {
						background: $color-secondary2 !important;
					}
					.MuiTypography-h4 {
						color: $color-primary1;
					}
				}
				&.user-role__option--is-focused {
					background: none;
				}
			}
		}
	}
}

/**manage permission link**/
.mng-permission-link {
	.MuiButton-root.btn-link {
		height: auto;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		line-height: 14px;
		text-transform: uppercase !important;
		text-decoration: none;
		svg {
			width: 14px;
			margin-right: 4px;
			height: 14px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.MuiTypography-subtitle1 {
		font-style: italic;
		font-weight: $font-weight-bold !important;
	}
}

/**search select dropdown css start here**/
.select-search-dropdown {
	.react-select__control {
		min-height: 40px;
		max-height: 40px;
		overflow-y: auto;
		@include custom-scrollbar;
		@include border-radius(6px);
		border: 1.4px solid rgba($color-input-field, 0.5);
		background: $color-white;
		&:hover {
			border: 1.4px solid rgba($color-input-field, 0.5);
		}
		&.react-select__control--is-focused {
			border: 1.4px solid $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		&.react-select__control--menu-is-open {
			z-index: 9;
		}

		.react-select__value-container {
			padding: 0.3em 1em;
			margin: 0px;
			.react-select__input-container {
				margin: 0px;
				font-size: $font-size-h5;
				line-height: $line-height4;
			}
			.react-select__multi-value {
				background: $color-bg-info;
				@include border-radius(24px);
				min-height: 1.5em;
				padding-right: 0.125em;

				.react-select__multi-value__label {
					padding: 0px;
					.search-user-result {
						@include flex;
						@include align-items(center);
						position: relative;
						padding-left: 27px;
						.MuiAvatar-root {
							width: 26.8px;
							height: 26.8px;
							border: 1.4px solid $color-white;
							font-size: $font-size-h6;
							font-weight: $font-weight-bold;
							text-transform: uppercase;
							background: $color-bg-info;
							position: absolute;
							left: 0;
							top: -1.4px;
						}
						.search-user-info {
							padding-left: 0.625em;
							.MuiTypography-subtitle2 {
								display: none;
							}
							.MuiTypography-h4 {
								font-size: $font-size-h6;
								font-weight: $font-weight-bold;
								color: $color-tags;
								max-width: 88px;
								text-overflow: ellipsis;
								white-space: nowrap;
								display: block;
								overflow: hidden;
								text-transform: uppercase;
								line-height: 24px;
							}
							ul {
								display: none;
							}
						}
					}
				}
			}
			.react-select__multi-value__remove {
				margin-left: 0.3125em;
				width: 24px;
				height: 24px;
				text-align: center;
				@include justify-content(center);
				cursor: pointer;
				//padding-right: 0.3125em;
				&:hover {
					background: none;
					color: $color-text-info;
				}
			}
		}
		.react-select__placeholder {
			color: rgba($color-primary2, 0.5);
			font-weight: $font-weight-regular;
			margin: 0px;
			font-size: $font-size-h5;
			line-height: $line-height4;
		}
		.react-select__indicators {
			.react-select__indicator-separator,
			.react-select__clear-indicator {
				display: none;
			}
			.react-select__dropdown-indicator {
				background: url(../../../images/down-arrow.svg) no-repeat center center;
				width: 13px;
				height: 13px;
				margin-right: 10px;
				@include transition(0.3s);
				svg {
					display: none;
				}
			}
			.react-select__clear-indicator {
				svg {
					width: 15px;
					height: 15px;
					color: $color-primary2;
				}
			}
		}
	}
	&.select-search-team-dropdown {
		.react-select__control {
			max-height: 96px;
			.react-select__value-container {
				.react-select__multi-value {
					.react-select__multi-value__label {
						.search-user-result {
							padding-left: 34px;
							.MuiAvatar-root {
								width: 34px;
								height: 26.8px;
								@include border-radius(14px);
								img {
									@include border-radius(14px);
								}
							}
							.search-user-info {
								.MuiTypography-h4 {
									text-transform: uppercase;
									max-width: 200px;
								}
							}
						}
					}
				}
			}
		}
		.react-select__menu {
			.react-select__menu-list {
				.react-select__option {
					.search-user-result {
						.MuiAvatar-root {
							width: 36px;
							height: 30px;
							@include border-radius(168px);
							img {
								@include border-radius(168px);
							}
						}
					}
				}
			}
		}
		&#team-org-dropdown {
			.react-select__multi-value {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
		.delete-icon-select {
			margin-left: 0.3125em;
			width: 24px;
			height: 24px;
			text-align: center;
			cursor: default;
			padding-left: 4px;
			padding-right: 4px;
			@include justify-content(center);
			svg {
				height: 10px;
				path {
					fill: $color-text-info;
				}
			}
		}
	}
	&.select-search-icon {
		.react-select__control {
			cursor: pointer;
			&.react-select__control--menu-is-open,
			&.react-select__control--is-focused {
				.react-select__dropdown-indicator {
					@include transform(rotate(180deg));
				}
				.react-select__value-container {
					.react-select__input-container {
						background-position: 0px -110px;
					}
				}
			}
			.react-select__value-container {
				.react-select__placeholder {
					margin-left: 26px;
				}
				.react-select__input-container {
					background: url(../../../images/search-sprite.svg) no-repeat 0 0;
					background-position: 0px 4px;
					padding-left: 26px;
					font-size: $font-size-h5;
					line-height: $line-height4;
				}
				.react-select__multi-value + .react-select__input-container {
					background: none;
					padding-left: 0;
				}
			}
		}
	}
	.react-select__menu {
		-moz-box-shadow: -2px 3px 5px -2px rgba($color-primary1, 0.5), 2px 3px 5px -2px rgba($color-primary1, 0.5);
		-webkit-box-shadow: -2px 3px 5px -2px rgba($color-primary1, 0.5), 2px 3px 5px -2px rgba($color-primary1, 0.5);
		box-shadow: -2px 3px 5px -2px rgba($color-primary1, 0.5), 2px 3px 5px -2px rgba($color-primary1, 0.5);
		@include border-radius(0 0 6px 6px);
		border: 1.4px solid $color-primary1;
		border-top: 0px;
		margin: -0.3125em 0 0 0;
		padding-top: 0.3125em;
		z-index: 99;
		overflow: hidden;
		> div {
			@include custom-scrollbar;
		}
		.react-select__menu-list {
			padding: 0px;
			max-height: 16.25em;
			border-top: 1.4px solid $color-hover;

			.react-select__option {
				background: transparent;
				padding: 0.625em 0.8125em;
				cursor: pointer;
				.search-user-result {
					@include flex;
					.MuiAvatar-root {
						margin-right: 0.8125em;
						//width: 30px;
						//height: 30px;
						font-size: $font-size-h5;
						//font-weight: $font-weight-bold;
						margin-top: 0.3125em;
					}
					.search-user-info {
						ul {
							@include flex;
							@include align-items(center);
							li {
								padding: 0 7px;
								position: relative;
								width: auto;
								.MuiTypography-subtitle2 {
									@include flex;
									@include align-items(center);
									svg {
										width: 14px;
										height: 14px;
										margin-right: 5px;
										path {
											fill: $color-secondary1;
										}
									}
								}
								&::after {
									content: '';
									width: 3px;
									height: 3px;
									background: url(../../../images/circle.svg) no-repeat center center;
									border-radius: 50%;
									position: absolute;
									right: -2px;
									top: 50%;
									margin-top: -1px;
								}
								&:first-child {
									padding-left: 0px;
								}
								&:last-child {
									&::after {
										display: none;
									}
								}
							}
						}
					}
				}
				&:hover {
					background: $color-hover;
				}
				&:last-child {
					@include border-radius(0 0 6px 6px);
				}
			}
		}
	}
	.dropdown-footer {
		text-align: center;
		padding: 12px 0;
		button {
			margin: 0;
			height: auto;
			line-height: 26px;
		}
	}

	&#reporting-to-dropdown,
	&#team-id-dropdown,
	&#change-reporting {
		.react-select__indicators {
			display: none;
		}
	}
	&#team-id-dropdown {
		.search-user-img {
			img {
				@include border-radius(168px);
			}
		}
	}
}
