@import '../../sass-utils/variables';
.linking-objective {
	// padding-bottom: 1em;
	// padding-top: 0.5em;
	cursor: pointer;
	.link-objective-label {
		font-size: $font-size-base;
		color: $color-primary1;
		svg {
			width: 1em;
			height: 1em;
			margin-right: 0.2em;
			vertical-align: middle;
			margin-top: -3px;
			circle {
				stroke: $color-primary1;
			}
			path {
				fill: $color-primary1;
			}
		}
	}
	.left-content {
		cursor: pointer;
	}
	.link_objective_desc {
		font-size: $font-size-h5;
		font-style: italic;
		font-weight: $font-weight-bold;
		color: $color-secondary1;
		padding-left: 1.25em;
		max-width: 80%;
	}
	ul.objective-ul {
		width: 80%;
		li {
			cursor: default;
			@include flex;
			p {
				font-weight: $font-weight-bold;
			}
			span.virtualdeleteicon {
				cursor: pointer;
			}
			.objective-chips {
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				margin-top: 1em;
				span {
					margin-right: 0.518em;
					border-radius: 3px;
					font-size: $font-size-subtitle2;
					display: inline-block;
					max-width: 117px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					padding: 0em 0.6em;
				}
				.quarter-chip {
					background: $color-hover;
					color: $color-primary2;
				}
			}
		}
	}

	.assigned-link {
		.left-content {
			width: 92%;
			.objective-chips {
				width: 100%;
			}
		}
		.right-content {
			width: 8%;
			margin-top: 1.5em;
		}
	}
}
.link-objective-scroll {
	margin-bottom: 1em;
	.user-info {
		padding: 0.3em 0.875em;
		cursor: pointer;
		.user-info-details {
			@include flex;
			flex: 1;
		}
	}
}

/*linking pbjective popover*/
.link-objective-popover {
	margin: 0px;
	padding: 0px;
	min-width: 636px;
	max-height: 400px;
	max-height: unset;
	min-height: 400px;
	overflow-y: unset;
	position: absolute;
	background: $color-white;
	top: 24px;
	z-index: 9999;
	@include border-radius(6px);
	border: 1px solid #ccc;
	// @include box-shadow(0px 0px 30px rgba($color-primary2, 0.1));

	.link_objective_main {
		position: relative;
		background-color: $color-white;
		@include border-radius(6px 6px 0 0);

		.disp-none {
			display: none;
		}
		.search-link-objective {
			border-bottom: 1px solid $color-hover;
			.no-border {
				border: none !important;
				width: 98%;
				padding: 0em;
				margin: 0em;
				height: 54px;
				border-radius: 0px;
				box-shadow: none !important;
				.search-icon {
					height: 53px;
				}
				input {
					width: 90%;
					//padding-top: 10px;
				}

				span svg {
					cursor: pointer;
				}
			}
		}
		.link-objective-search-users {
			width: 100%;
			background-color: $color-white;
			@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			@include border-radius(6px);
			max-height: 306px;
			//min-height: 306px;
			overflow: auto;
			.user-info:hover {
				background-color: $color-hover;
			}
		}
		.linking-objective-content {
			position: absolute;
			//background-color: $color-white;
			min-height: 306px;
			//@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			width: 100%;
			z-index: -1;
			top: 3em;
			@include border-radius(0 0 6px 6px);
			.blur-list {
				opacity: 0.7;
			}
			.link-objective-tabs {
				height: 40px;
				background: $color-bg-form;
				border-bottom: 1.4px solid $color-hover;
				font-size: $font-size-subtitle2;
				color: $color-primary2;
				.okr-tabs .MuiTab-root {
					min-height: 40px;
				}
				.okr-tabs .MuiTab-root:last-child {
					color: $color-primary1;
					cursor: default;
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			.link_objective_data {
				font-size: $font-size-base;
				font-weight: $font-weight-bold;
				max-height: 285px;
				min-height: 285px;
				overflow: auto;
				ul li {
					border-bottom: 1.4px solid $color-hover;
					padding: 0.9em 0.8em 0.9em 1.5em;
					cursor: pointer;
					.objective-chips {
						font-weight: $font-weight-bold;
						margin-top: 0.625em;
						span {
							margin-right: 0.518em;
							@include border-radius(3px);
							font-size: $font-size-subtitle2;
							display: inline-block;
							max-width: 117px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding: 0em 0.6em;
						}
						.quarter-chip {
							background: $color-hover;
							color: $color-primary2;
						}
					}
				}
				.objective-ul {
					li {
						p {
							&:first-child {
								font-weight: $font-weight-bold;
							}
						}
						&:hover {
							background: $color-hover;
						}
						&.active {
							background: $color-secondary2;
						}
					}
				}
			}
		}
	}
	.link-objective-scroll {
		margin-bottom: 9em;
	}
	.selected-user-link {
		border-bottom: 1.4px solid $color-hover;
	}
	.search-user-close-icon {
		margin-top: 0.625em;
		margin-right: 0em;
	}
}
