@import '../../../styles/sass-utils/variables';
/** team detail css start here**/
.user-team-detail {
	@include flex;
	.team-logo-wrapper {
		position: relative;
		width: 53px;
		height: 44px;
		margin-right: 0.5em;
		margin-top: 0.5625em;
		.profile-avatar {
			border: 1.4px solid $color-bg-teamstag;
		}
		.team-logo {
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			width: 53px;
			height: 44px;
			overflow: hidden;
			position: relative;
			@include border-radius(168px);
			font-size: $font-size-h2;
			line-height: 44px;
			font-weight: $font-weight-bold;
			background: $color-bg-teamtag-selected;
			color: $color-text-teamtag;
			cursor: pointer;

			&.no-org-image {
				background: $color-bg-teamstag;
			}
			img {
				@include border-radius(168px);
			}
		}
		.camera-icon {
			position: absolute;
			width: 26px;
			top: 18px;
			left: -12px;
			height: 26px;
			background: $color-primary1;
			text-align: center;
			border-radius: 50%;
			border: 1.4px solid $color-white;
			label {
				width: 100%;
				height: 100%;
				display: block;
				text-align: center;
				line-height: 25px;
				cursor: pointer;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-white;
						stroke: $color-white;
					}
				}
			}
			.upload-image-file-input {
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				display: none;
			}
		}
	}
	.team-info {
		max-width: 74%;
		.leader-detail {
			display: inline-block;
			position: relative;
			.search-error {
				top: 0;
				width: 227px;
				line-height: 18px;
				svg {
					margin-top: 2px;
				}
			}
			.btn-link {
				font-size: $font-size-h6;
				line-height: $line-height2;
				font-weight: $font-weight-bold;
				text-transform: uppercase !important;
				height: auto;
				margin: 0;
				padding: 0.34em 1em;
				background: $color-white;
				@include border-radius(36px);
				text-decoration: none;
				//margin-top: -3px;
				&.active {
					@include default-shadow;
				}
			}
			.MuiChip-root.chip-avatar {
				margin-left: 10px;
			}
		}
	}
}

/**title field css start here**/
.title-inline-field {
	position: relative;
	min-height: 30px;
	margin-bottom: 3px;
	.title-field {
		min-height: 30px;
		@include flex;
		@include align-items(center);
		padding-left: 0.75em;
		.MuiTypography-h4 {
			.editIcon {
				opacity: 0;
				margin-top: -3px;
			}
			&:hover {
				.editIcon {
					opacity: 1;
				}
			}
		}
	}
	.search-error {
		svg {
			margin-top: 2px !important;
		}
		&.team-name-error {
			top: -1px !important;
			padding: 0.417em 5px !important;
			width: 175px !important;
			line-height: 18px;
		}
	}
}

/**Inline edit text field css here**/
body.admin-body-class {
	.editIcon {
		width: 24px;
		height: 24px;
		margin-left: 5px !important;
		padding: 0px !important;
		@include border-radius(100%);
		svg {
			width: 14px;
			height: 14px;
			path {
				fill: $color-primary1;
			}
		}
	}
	.edit-text-field {
		position: absolute;
		top: 0px;
		left: 0.5px;
		width: 524px;
		z-index: 9;
		.MuiTextField-root {
			.MuiInputBase-root {
				padding: 0 10px;
				background: rgba(239, 239, 239, 0.5);
				@include border-radius(6px 6px 0px 6px);
				border: 1.4px solid $color-primary1;
				-moz-box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
				-webkit-box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
				box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
				.MuiInputBase-input {
					padding: 2px 0px 2px;
					min-height: 23px;
					line-height: 20px;
				}
				&.Mui-focused {
					border: 1.4px solid $color-primary1;
					-moz-box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
					-webkit-box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
					box-shadow: 0px 0px 5px rgba($color-primary1, 0.5), inset 0px 0px 5px rgba($color-primary1, 0.35);
				}
			}
			.MuiFormHelperText-root {
				top: 0px;
				padding-left: 5px;
				padding-right: 5px;
				margin-left: 1px;
			}
		}
		.edit-actions {
			position: absolute;
			right: 0;
			bottom: -23px;
			.edit-save {
				background: $color-primary1;
				@include border-radius(0px 0 3px 3px);
				padding: 4px 2px;
				width: 24px;
				height: 24px;
				svg {
					height: 13px;
					path {
						fill: $color-white;
						stroke: $color-white;
						stroke-width: 3px;
					}
				}
			}
			.edit-cancel {
				padding: 4px 2px;
				width: 24px;
				height: 24px;
				svg {
					width: 12px;
					height: 12px;
					path {
						fill: $color-primary1;
						stroke: $color-primary1;
						stroke-width: 3px;
					}
				}
				&:hover {
					background: none;
				}
			}
		}
	}
}
.edit-mode-field,
.react-select--is-disabled {
	opacity: 0.8;
	.react-select__value-container,
	.react-select__indicators {
		opacity: 0.6;
	}
}
