@import '../../../styles/sass-utils/variables';
.roles-listing {
	box-shadow: none;
	background: none;
	@include custom-scrollbar-small-height;
	padding-bottom: 2.5em;
	table {
		position: relative;
		border-collapse: separate; /* Don't collapse */
		border-spacing: 0;
		.MuiTableHead-root {
			.MuiTableCell-head {
				@include border-radius(6px);
				&.MuiTableCell-stickyHeader {
					background: $color-secondary2;
				}
				.cell-wrap {
					padding: 1.429em 1.15em;
					margin-bottom: 0.5em;
					@include flex-direction(column);
					@include align-items(flex-start);
					@include justify-content(flex-start);
					@include border-radius(6px);
					border-bottom: 1.4px solid $color-border-tile;
					min-height: 7.14em;
					.description-arrow {
						background: url(../../../images/arrow-125.svg) no-repeat center center;
						cursor: pointer;
						font-size: 0px;
						width: 15px;
						height: 15px;
						display: inline-block;
						margin-left: 5px;
						svg {
							transform: rotate(-145deg);
							path {
								fill: $color-primary1;
							}
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
					&:hover {
						.role-head-right {
							opacity: 1;
						}
					}
				}
				&:first-child {
					@include border-radius(0px);
					.cell-wrap {
						@include flex;
						@include justify-content(flex-end);
						padding: 0.5em 2.125em 0;
						margin-bottom: 0;
						border: none;
						text-transform: uppercase;
						color: $color-secondary1;
						//border-right: 1.4px solid $color-border-tile;
						background: transparent;
						cursor: default;
						&:hover {
							box-shadow: none;
						}
						.MuiTypography-overline {
							position: absolute;
							right: 78px;
							bottom: 0px;
						}
					}
				}
				&:nth-child(2) {
					.cell-wrap {
						border-left: 1.4px solid $color-border-tile;
					}
				}
				&.activerole {
					.cell-wrap {
						background-color: $color-okr-highlighter !important;
					}
				}
			}
		}
		.MuiTableRow-hover {
			@include transition(0.3s);
			&:hover {
				// transform: scale(1, 1);
				// -webkit-transform: scale(1, 1);
				// -moz-transform: scale(1, 1);
				// box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
				// -webkit-box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
				// -moz-box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
				.MuiTableCell-root {
					&:first-child {
						// transform: scale(1, 1);
						// -webkit-transform: scale(1, 1);
						// -moz-transform: scale(1, 1);
						// box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
						// -webkit-box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
						// -moz-box-shadow: 2px 2px 10px rgba($color-primary2, 0.12);
						.rotate-arrow {
							opacity: 1;
						}
					}
				}
			}
			.MuiTableCell-root {
				&:first-child {
					@include border-radius(6px 0px 0px 6px);
					.cell-wrap {
						border-left: 1.4px solid $color-border-tile;
						@include border-radius(6px 0px 0px 6px);
						@include flex-direction(column);
						@include align-items(flex-start);
						padding: 1em 2.125em;
						.MuiTypography-subtitle2 {
							text-transform: initial;
							line-height: 12px;
						}
					}
				}
				&.activerole {
					.cell-wrap {
						background-color: $color-okr-highlighter !important;
					}
				}
			}
			&.expandRow {
				.rotate-arrow {
					opacity: 1;
					svg {
						@include transform(rotate(90deg));
					}
				}
			}
			&:nth-last-child(2) {
				.MuiTableCell-root {
					.cell-wrap {
						border-bottom: 1.4px solid $color-border-tile;
					}
				}
			}
		}
		.MuiTableRow-root {
			.MuiTableCell-root {
				.MuiTableRow-root {
					.MuiTableCell-root {
						.cell-wrap {
							min-height: 80px;
							background: $color-expanded-tiles;
							border: 0px;
						}

						&:first-child {
							.cell-wrap {
								@include flex-direction(column);
								@include align-items(flex-start);
								border-right: 1px solid #cee3ff;
								padding-left: 2.85em;
							}
						}
					}
				}
			}
		}
	}
	.MuiTableCell-head {
		padding: 0px;
		left: 0;
		position: sticky;
	}
	.cell-wrap {
		padding: 1.25em 2.125em;
		border: 1.4px solid $color-border-tile;
		min-height: 90px;
		border-bottom: 0px;
		border-left: 0px;
		@include flex;
		@include justify-content(center);
		@include align-items(center);
		background: $color-white;
		.MuiTypography-body1 {
			font-weight: $font-weight-bold;
		}
	}
	.MuiTableCell-root {
		min-width: 240px;
		padding: 0px;
		border: 0px;
		&:first-child {
			min-width: 323px;
			left: 0;
			position: sticky;
			z-index: 9;
			width: 100%;
			cursor: pointer;
			.cell-wrap {
				//border-left: 1.4px solid $color-border-tile;
				//border-radius: 6px 0px 0px 6px;
			}
		}
		.rotate-arrow {
			position: absolute;
			width: 1.375em;
			height: 6.36em;
			left: 1px;
			top: 1px;
			background: rgba(226, 238, 255, 0.4);
			opacity: 0;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			@include border-radius(6px 0px 0px 6px);
			svg {
				@include transition(0.5s);
			}
		}
	}
}


.non-Clickable{
	pointer-events: none;
}
/**role card css here**/
.role-card {
	width: 100%;
	.role-head {
		@include flex;
		@include justify-content(space-between);
		margin-bottom: 0.9375em;
		.role-head-right {
			@include flex;
			@include align-items(center);
			padding-left: 0.625em;
			opacity: 0;
			@include transition(0.3s);
			margin-right: -0.5em;
			.MuiButton-root {
				padding: 0px;
				margin: 0 0 0 0.3125em;
				width: 24px;
				height: 24px;
				@include border-radius(50px);
				&:first-child {
					margin-left: 0;
				}
				&:hover {
					background: rgba(200, 219, 245, 0.4);
				}
				.MuiTouchRipple-root {
					display: none;
				}
				svg {
					max-width: 14px;
					max-height: 14px;
					path {
						fill: $color-primary1;
					}
				}
			}
			&.active {
				opacity: 1;
			}
		}

		.role-title {
			@include flex;
			@include align-items(center);
			.MuiTypography-h3 {
				&:hover {
					.MuiFormControl-root.role-title-text-field {
						opacity: 1;
						pointer-events: initial;
					}
				}
				cursor: default;
			}
			cursor: default;
		}
	}
	.role-content {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		.role-status {
			.MuiTypography-subtitle2 {
				padding: 0 0.4375em;
				background: $color-completed;
				color: $color-white;
				line-height: 16px;
				@include border-radius(10px);
				text-transform: uppercase;
				min-width: 49px;
				&.inactive {
					background: $color-hover;
					color: rgba($color-primary2, 0.5);
				}
			}
		}
		.user-role-info {
			@include flex;
			@include align-items(center);
			.user-quick-search {
				margin-left: 5px;
				.MuiButton-root {
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
		}
	}
}
.role-description-popup {
	z-index: 9999;
	background: $color-white;
	box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
	border-radius: 0.375em;
	padding: 0.625em;
	margin-top: 10px;
	margin-right: -55px;
	&::before {
		content: '';
		width: 0px;
		height: 0px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $color-white;
		position: absolute;
		top: -10px;
		right: 50px;
		//margin-left: -55px;
	}
	&[x-placement~='top-end'] {
		margin-top: 0px;
		margin-bottom: 10px;
		//margin-left: 55px;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			width: 0px;
			height: 0px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid $color-white;
			position: absolute;
			bottom: -10px;
			right: 50px;
			margin-left: -11px;
		}
	}
	.textarea-expand {
		// -moz-appearance: textfield-multiline;
		// -webkit-appearance: textarea;
		resize: both;
		overflow: auto;
		min-width: 495px;
		width: 495px;
		height: 85px;
		@include custom-scrollbar;
		strong {
			font-weight: $font-weight-bold;
		}
		a {
			color: $color-primary1;
			text-decoration: underline;
		}
	}
	::-webkit-resizer {
		position: absolute;
		top: 0px;
		background: url(../../../images/grab-icon.svg) no-repeat center 0px;
		left: 0px;
	}
}

/**role inline editing css start here**/
#role-card-1 {
	.MuiFormControl-root.role-title-text-field {
		.MuiInput-root {
			padding-left: 10px;
		}
	}
}

.opacity1 {
	opacity: 1 !important;
}

.MuiFormControl-root.role-title-text-field {
	position: absolute;
	top: 14px;
	left: 0;
	padding: 0 6px;
	z-index: 99;
	opacity: 0;
	pointer-events: none;
	.MuiInput-root {
		border: 1.4px solid rgba($color-input-field, 0.5);
		background: #e5e5e5;
		@include border-radius(6px);
		padding: 0 13px 0 9px;
		&:before,
		&:after {
			display: none;
		}
		.MuiInputBase-input {
			font-size: $font-size-h2;
			font-weight: $font-weight-black !important;
			line-height: $line-height2;
			padding: 6px 0;
		}
		&.Mui-focused {
			border: 1.4px solid $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
			cursor: text;
		}
		&:hover {
			cursor: pointer;
			.MuiInputBase-input {
				cursor: pointer;
			}
			&.Mui-focused {
				cursor: text;
				.MuiInputBase-input {
					cursor: text;
				}
			}
		}
	}
}
