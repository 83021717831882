@import '../../../styles/sass-utils/variables';
@import './circular';
@import './filter';

.reports-wrapper {
	.page-content-wrapper {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.okr-reports-area {
	.col-full {
		width: 100%;
	}
	.col-half {
		width: 50%;
	}
	.col-one-third {
		width: 33.3333%;
	}
	.col-two-thirds {
		width: 66.6666%;
	}
	.reports-head {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		padding: 1em 0 0.75em;
		.reports-head-left {
			@include flex;
			@include align-items(center);
			.MuiButton-root {
				margin: 0.25em 0 0 0.5em;
				padding: 0;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $color-primary1;
					}
				}
				&:hover {
					background: transparent;
				}
			}
		}
		.reports-head-right {
			.report-filter-btn {
				padding: 0.375em 1em;
				font-size: $font-size-h5;
				background: $color-bg-filterbtn;
				border: 1.4px solid $color-border-tile;
				@include border-radius(43px);
				svg {
					margin-right: 0.5em;
				}
				&.active {
					background: $color-white;
					border-color: $color-white;
					@include default-shadow;
				}
			}
		}
	}
	.reports-card {
		padding: 1.5em;
		margin-bottom: 2.25em;
		@include flex;
		@include justify-content('space-between');
		background: $color-white;
		border: 1.4px solid $color-border-tile;
		@include border-radius(6px);
		.MuiTypography-h4 {
			margin-bottom: 1.5625em;
			font-weight: $font-weight-bold;
			span {
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				margin-left: 5px;
				opacity: 0.5;
			}
		}
		.column-chart,
		.spline-chart {
			.chart-legend-default {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
			}
		}
	}
}
/**tabs overwrite css**/
.report-tabs {
	.MuiTab-root {
		//padding-top: 6px;
		padding-left: 0;
		padding-right: 0;
		.MuiTab-wrapper {
			svg {
				width: 14px;
				height: 14px;
			}
		}
		.MuiTab-wrapper {
			padding-left: 12px;
			padding-right: 12px;
		}
		&#report-tabpanel-1 {
			.MuiTab-wrapper {
				span {
					padding: 0 5px;
					display: block;
				}
				.arrowIcon {
					svg {
						width: 8px;
						height: 8px;
						margin: 3px 0 0 0px;
						path {
							stroke: $color-primary2;
							stroke-width: 2px;
						}
					}
				}
				svg {
					&:nth-child(2) {
						width: 8px;
						height: 8px;
						margin: 3px 0 0 0px;
						path {
							stroke: $color-primary2;
							stroke-width: 2px;
						}
					}
				}
			}
			&.menu-open {
				.MuiTab-wrapper {
					background: $color-white;
					@include border-radius(28px);
					@include box-shadow(0px 0px 10px -2px rgba($color-primary2, 0.12));
				}
			}
			&.Mui-selected {
				.MuiTab-wrapper {
					svg {
						&:last-child {
							path {
								stroke: $color-primary1;
								stroke-width: 2px;
							}
						}
					}
				}
			}
		}
	}
}
/**No Reports css**/
.no-records-area {
	@include flex;
	@include justify-content(center);
	padding: 100px 60px;
	min-height: calc(100vh - 165px);
	background-image: url(../../../images/blank-screen-bg2.svg), url(../../../images/blank-screen-bg.svg);
	background-position: left bottom, center bottom;
	background-repeat: no-repeat, no-repeat;
	.no-record-message {
		@include flex;
		@include flex-direction(column);
		@include align-items(center);
		.MuiTypography-h3 {
			padding-bottom: 0.25em;
			font-weight: $font-weight-black;
		}
	}
	object {
		margin-top: 35px;
	}
}
/**header tooltip css start here**/
.report-header-tooltip {
	margin-right: 0;
	margin-left: -58px;
	margin-top: 10px;
	padding: 15px 5px 15px 20px;
	&::before {
		right: auto;
		left: 56px;
	}
	&[x-placement~='top-start'] {
		margin-top: 0px;
		margin-bottom: 10px;
		&::before {
			bottom: -10px;
			top: auto;
			@include transform(rotate(180deg));
		}
	}
	.textarea-expand {
		min-width: 352px;
		width: 352px;
		height: 132px;
		padding-right: 15px;
		@include custom-scrollbar-small-width;
	}
}

/**loading css start here**/
.org-loading {
	text-align: center;
	width: 100%;
	min-height: 250px;
	@include flex;
	@include align-items(center);
	@include justify-content(center);
}

.title-center {
	text-align: center;
}

#charts {
	overflow: initial !important;
}
