@import '../pages/drawer/krMetricsFileds.scss';
@import '../pages/drawer/messageTrail.scss';
@import '../pages/drawer/treeViewPopover.scss';
@import '../pages/drawer/drawerButtonGroup.scss';
@import '../pages/drawer/teamChip.scss';
@import '../pages/drawer/linkingObj.scss';

.drawer-head-common {
	position: relative;
	&::after {
		content: '';
		border-bottom: 1px solid rgba($color-primary2, 0.2);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	&.drawer-head-tabs {
		padding-top: 0;
		padding-bottom: 0;
		.drawer-tabs {
			padding: 0;
			&::after {
				display: none;
			}
		}
		.drawer-okr-links {
			padding: 0 1em;
		}
		.MuiSelect-select {
			padding: 0.5em 1em 0.5em 0;
			width: auto;
			font-size: $font-size-base;
			background: none;
		}
		.MuiSelect-icon {
			width: 25px;
			height: 25px;
		}
	}
}
.main-drawer-panel {
	z-index: 9990 !important;
	.highcharts-legend {
		.highcharts-graph {
			display: none !important;
		}
	}
	.MuiDrawer-paper {
		overflow: inherit;
		width: 860px;
		@include panel-shadow;
	}
	.drawer-close-btn {
		width: 1.75em;
		height: 1.75em;
		position: absolute;
		left: -0.85em;
		top: 2.7em;
		background: $color-white;
		z-index: 2;
		@include box-shadow(0px 4px 30px rgba(0, 0, 0, 0.15));
		font-size: $font-size-base;
		padding: 0.5em;
		@include border-radius(50%);
		svg {
			path {
				fill: $color-primary2;
			}
		}
		&:hover {
			background: $color-white;
		}
	}
	.drawer-area {
		.drawer-head {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			padding: 1em 3.75em 1em;
			min-height: 60px;
			svg {
				width: 14px;
				height: 14px;
			}
		}
		.drawer-content {
			position: relative;
			.drawer-inner-content {
				.drawer-tabs-panel {
					max-height: calc(100vh - 215px);
					min-height: calc(100vh - 215px);
					overflow-y: auto;
					@include custom-scrollbar;
					padding: 1.875em 0;
					margin: 0;
					position: relative;
					&.import-okr-tabpanel {
						padding: 0;
						max-height: 100%;
						min-height: 270px;
						overflow: inherit;
					}
				}
			}
		}
		.drawer-footer-actions {
			min-height: 5.8125em;
			padding: 1.625em 3.75em;
			@include box-shadow(0px 0px 17px rgba(0, 0, 0, 0.08));
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
		}
	}

	/**main drawer title panel  css start here**/
	&.main-drawer-titlepanel {
		.drawer-area {
			.drawer-head {
				@extend .drawer-head-common;
			}
			.drawer-content {
				.drawer-inner-content {
					.drawer-content-panel {
						max-height: calc(100vh - 153px);
						min-height: calc(100vh - 153px);
						overflow-y: auto;
						@include custom-scrollbar;
						padding: 1.875em 0;
						margin: 0;
						position: relative;
					}
				}
			}
		}
	}
	/**main drawer panel with no footer css start here**/
	&.drawer-no-footer {
		.drawer-area {
			.drawer-head {
				@extend .drawer-head-common;
			}
			.drawer-content {
				padding: 0;
				.drawer-inner-content {
					.drawer-content-panel {
						max-height: calc(100vh - 60px);
						min-height: calc(100vh - 60px);
						overflow-y: auto;
						@include custom-scrollbar;
						padding: 1.875em 0;
						margin: 0;
						position: relative;
						&.okr-drawer-form-area {
							padding-left: 3.75em;
							padding-right: 3.75em;
						}
						&.kr-weekly-trends-area {
							padding-top: 2em;
						}
					}
				}
			}

			.drawer-footer-actions {
				display: none;
			}
		}
	}
	/**main drawer sub panel css start here**/
	&.main-drawer-subpanel {
		.MuiDrawer-paper {
			overflow: inherit;
			width: 800px;
		}
		.drawer-area {
			.drawer-content {
				padding-top: 0px;
				.drawer-inner-content {
					.drawer-tabs-panel {
						max-height: calc(100vh - 153px);
						min-height: calc(100vh - 153px);
						padding: 1.875em 3em;
						&.drawer-upload-image-panel {
							padding: 0;
							max-height: calc(100vh - 89px);
							min-height: calc(100vh - 89px);
						}
					}
					.drawer-content-subpanel {
						@extend .drawer-tabs-panel;
						padding: 1.875em 3.75em;
					}
				}
			}
			.drawer-head {
				@extend .drawer-head-common;
			}
		}
		.drawer-form-fields {
			.drawer-input-field {
				padding: 0 0 1.875em;
			}
		}
	}
}
/**drawer field row**/
.drawer-field-bottom {
	margin-bottom: 1.875em !important;
}
.drawer-field-row {
	padding: 0 3.75em 1.875em;
}
.key-result-head {
	padding-bottom: 0px !important;
}
.user-team-wrap {
	@include flex;
	align-items: center;
	margin-top: 1em;
}

/**date field**/
.drawer-date-field {
	.MuiFormControl-root {
		&:first-child {
			margin-right: 30px;
		}
	}
	input {
		&.form-control {
			width: 240px;
			padding: 0.714em 1em;
			font-size: $font-size-h5;
			line-height: $line-height4;
			border: 1.4px solid rgba($color-input-field, 0.5);
			@include border-radius(6px);
			font-family: $font-family-lato;
		}
	}
	.cycle-and-remaining-days {
		margin-top: 0.5em;
		max-width: 240px;
		@include flex;
		@include justify-content(space-between);
	}
}
/**virtuallink drawer top head**/
.objectiveName {
	.objectiveDetails {
		@include flex;
		@include align-items(center);
		img {
			margin-right: 0.5em;
		}
	}
	margin-bottom: 1em;
	.tags {
		margin-top: 0.5em;
		margin-left: 1.75em;
		button {
			margin-right: 0.625em;
		}
	}
}
