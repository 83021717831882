@import '../../sass-utils/variables';
.contributor-expand-collapse-view {
	.message-trail {
		padding: 1.25em !important;
		margin: 0;
	}
}
.message-trail {
	padding: 1.25em 3.75em !important;
	background: $color-bg-form;
	margin-bottom: 1.875em;
	.message-trail-label {
		margin-bottom: 0.5em;
	}
	.MuiList-root {
		.MuiListItem-gutters {
			@include flex-direction(column);
			align-items: flex-start;
			padding: 0px;
		}
	}
	.user-info {
		padding-bottom: 0.3125em;
	}
	.user-message-description {
		padding-left: 2.625em;
	}
	.message-trail-first-label {
		.first-label-row {
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 1.4px;
				height: calc(100% - 50px);
				top: 51px;
				left: 14px;
				background: rgba($color-primary2, 0.2);
			}
		}
	}
	.message-trail-second-label {
		padding-left: 2.625em;
		padding-top: 1.25em;
		.user-message-description {
			padding-left: 0px;
		}
	}
}
