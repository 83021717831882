/**tenure drawer list start here**/
.tenure-drawer {
	z-index: 9990 !important;
	height: auto;
	.MuiDrawer-paper {
		//@include border-radius(6px);
		width: 400px;
		left: auto;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
		height: auto;
		//padding-top: 64px;
	}
	.MuiBackdrop-root {
		//background: none;
	}

	.past-cycle-link {
		padding: 0 28px;
		@include flex;
		align-items: center;
		min-height: 48px;
		cursor: pointer;
		font-size: $font-size-h5;
		line-height: $line-height4;

		svg {
			max-width: 16px;
			margin-right: 9px;
		}
		&:hover {
			background: $color-hover;
			@include transition(all ease-in-out 0.25s);
		}
	}
}

/**cycle list start here**/
.tenure-list {
	//border-top: 1px solid $color-input-fieldLight4;
	height: 100vh;
	overflow-y: auto;
	@include custom-scrollbar;
	.current-cycle-tag {
		background: $color-bg-tag;
		padding: 5px 7px;
		@include border-radius(100px);
		line-height: 10px;
		color: $color-primary2;
	}
	.back-to-main {
		padding: 1em 0 0.625em 1.725em;
	}
	.MuiPaper-elevation1 {
		box-shadow: none;
		transition: all ease-in-out 0.25s;
		&.Mui-expanded {
			margin: 0;
		}
		&:before {
			display: none;
		}

		.MuiCollapse-entered {
			border-bottom: 1px solid $color-hover;
		}
		.MuiExpansionPanelDetails-root {
			padding: 0;
			nav {
				width: 100%;
				padding: 0;
				.MuiListItem-button {
					padding: 0.25em 1.25em 0.25em 3.4em;
					min-height: 40px;
					&:hover {
						background: none;
						.MuiTypography-body1 {
							color: $color-primary1;
						}
					}
					&.active {
						background: $color-secondary2;
					}
					.MuiTypography-body1 {
						font-size: $font-size-h5;
						line-height: $line-height4;
					}
					.MuiListItemText-root {
						@include flex;
						@include align-items(center);
						svg {
							width: 12px;
							height: 12px;
							margin-left: 8px;
							margin-top: 4px;
						}
					}
				}
			}
		}
		.MuiExpansionPanelSummary-root {
			border-bottom: 1px solid rgba(215, 215, 215, 0.5);
			padding: 0px 26px;
			&:hover {
				background: $color-hover;
			}
			&.Mui-expanded {
				min-height: 48px;
				//background: $color-secondary2;
				.MuiTypography-body1 {
					color: $color-primary1;
				}
				.MuiExpansionPanelSummary-expandIcon {
					svg {
						path {
							fill: $color-primary1;
						}
					}
				}
			}
			.MuiExpansionPanelSummary-content {
				svg {
					width: 12px;
					height: 12px;
					margin-left: 5px;
					margin-top: 2px;
				}
				&.Mui-expanded {
					margin: 12px 0;
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
					line-height: $line-height4;
					@include flex;
					align-items: center;
				}
			}
		}
	}
	.MuiExpansionPanelSummary-expandIcon {
		margin-right: 0;
		margin-left: -12px;
		order: -1;
		&.Mui-expanded {
			transform: rotate(90deg);
		}
	}
	.MuiTouchRipple-root {
		display: none;
	}
}

/**cycle dropdown start here**/

.cycle-dropdown {
	position: relative;
	display: inline-block;
	.tenure-list {
		position: absolute;
		z-index: 99;
		top: 38px;
		right: 0;
		width: 282px;
		height: auto;
		max-height: 294px;
		background: $color-white;
		@include box-shadow(2px 0px 10px rgba($color-primary1, 0.12));
		@include border-radius(8px);
		.MuiPaper-elevation1 {
			.MuiExpansionPanelSummary-root {
				padding: 0 0.9375em;
			}
			.MuiExpansionPanelDetails-root {
				nav {
					.MuiListItem-button {
						padding-left: 2.65em;
					}
				}
			}
		}
	}
}
