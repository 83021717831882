@import '../../../styles/sass-utils/variables';
/**manage user drawer**/
.add-user-field {
	@include flex;
	@include align-items(center);
	.MuiButton-root {
		margin-right: 0.4375em;
	}
	.MuiTypography-subtitle1 {
		color: $color-secondary1;
	}
}
.manage-user-drawer {
	padding: 0 3.75em 1.875em;
	.drawer-input-field {
		padding-left: 0px !important;
		padding-right: 0px !important;
		padding-bottom: 1.875em !important;
	}
	.drawer-user-search-field {
		.Mui-focused {
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.users-listing {
		margin: 0em -0.625em 0;
		.chip-group {
			@include flex;
			flex-wrap: wrap;
		}
		.users-list-content {
			.users-list-row {
				.MuiList-root {
					.MuiListItem-gutters {
						.btn-cross {
							right: -5px;
							&.btn-disable {
								svg {
									path {
										fill: $color-text-info;
										stroke: $color-text-info;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.userCount {
		padding-top: 0px;
	}
}
