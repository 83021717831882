@import '../../sass-utils/variables';
.mails-wrapper {
	.whats-tabs-wrap {
		@include justify-content(flex-end);
		min-height: 60px;
	}
	.okr-update-list-area {
		.okr-list-panel-head {
			padding: 0 40px;
			ul {
				li {
					&.okr-update-col1 {
						width: 60%;
						padding: 0 12px 0 0;
					}
					&.okr-update-col2 {
						width: 20%;
					}
					&.okr-update-col3 {
						width: 20%;
					}
					.MuiTypography-body2 {
						color: $color-secondary1;
					}
				}
			}
		}
		.okr-update-list {
			.mail-status {
				padding: 0 7px;
				color: $color-primary2;
				border: 1px solid $color-bg-tag;
				background: $color-bg-tag;
				min-height: 16px;
				min-width: 3.0625em;
				border-radius: 12px;
				font-size: 10px;
				font-weight: 700;
				line-height: 20px;
				display: block;
				text-align: center;
				text-transform: uppercase;
				&.draft-chip {
					background: $color-okr-highlighter;
					border: 1px solid $color-border-info;
					color: $color-tags;
				}
				&.sent-chip {
					background: rgba($color-on-track, 0.2);
					border: 1px solid $color-on-track;
					color: $color-on-track;
				}
			}
			.okr-update-list-inner {
				.okr-update-head {
					ul {
						height: 50px;
						li {
							&.okr-update-col1 {
								width: 60%;
								padding: 0 12px 0 0;
							}
							&.okr-update-col2 {
								width: 20%;
								padding: 0 12px 0 20px;
							}
							&.okr-update-col3 {
								width: 20%;
								padding: 0 12px 0 20px;
								@include justify-content(space-between);
								.email-actions {
									.MuiButton-root {
										&.Mui-disabled {
											svg {
												path {
													fill: rgba($color-primary2, 0.5);
												}
											}
										}
									}
									.send-email-btn-wrap {
										&.disabled {
											cursor: default;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.mail-modal {
	.MuiFormLabel-root {
		sup {
			margin-left: 3px;
		}
	}
	.error-des {
		.MuiFormLabel-root {
			color: $color-text-info;
			sup {
				color: $color-text-info;
			}
		}
		.ck.ck-toolbar {
			border-color: $color-text-info !important;
		}
		.ck.ck-editor__main > .ck-content {
			border-color: $color-text-info !important;
		}
	}
}
