@import '../../sass-utils/variables';
.contributors-list-panel {
	.kr-contributor-listing {
		.kr-contributor-list-head {
			.MuiList-root {
				.user-col4 {
					.kebab-icon {
						padding: 0;
						width: $font-size-h5;
						height: $font-size-h5;
						margin: 0 0 0 0.375em;
						svg {
							width: 100%;
							height: 100%;
						}
						.MuiTouchRipple-root {
							display: none;
						}
						&:hover {
							background: none;
						}
					}
				}
			}
		}
		.kr-contributor-list-row {
			@include transition-default;
			.MuiAvatar-root {
				box-shadow: none;
			}
			.user-col3 {
				@include flex-direction(column);
			}
			.contributor-expand-collapse-view {
				.user-info {
					overflow: hidden;
					.MuiTypography-h4 {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.btn-wrap {
					@include transition-default;
					opacity: 0;
					@include flex;
					@include flex-direction(column);
					button {
						padding: 0;
						width: auto;
						height: auto;
						&.edit-btn {
							margin-bottom: 1.25em;
						}
						&:hover {
							background: none;
						}
						.MuiTouchRipple-root {
							display: none;
						}
					}
				}
			}
			&:hover {
				.contributor-expand-collapse-view {
					.btn-wrap {
						opacity: 1;
						svg {
							path {
								fill: $color-primary1;
							}
						}
					}
				}
			}
		}
	}
}
/**drawer okr info list **/
.okr-info-list {
	form {
		padding: 0 1.25em;
		.field-row {
			padding-bottom: 1.875em;
		}
	}
}
