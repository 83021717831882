@import '../../sass-utils/variables';
.assign-target-panel {
	.kr-contributor-listing {
		.kr-contributor-list-head {
			.MuiList-root {
				.MuiListItem-gutters {
					padding: 0 1em;
				}
				.user-col1 {
					width: 45%;
					padding: 0 0 0 1em;
				}
				.user-col2 {
					width: 33%;
				}
				.user-col3 {
					width: 22%;
				}
			}
		}
		.kr-contributor-list-content {
			.kr-contributor-list-row {
				.MuiList-root {
					padding: 1em 0;
					.MuiListItem-gutters {
						padding: 0 1em;
					}
					.user-col1 {
						width: 45%;
						padding: 0 0 0 1em;
					}
					.user-col2 {
						width: 33%;
						.MuiTypography-root {
							.currency-span {
								@include flex;
								@include align-items(circle);
							}
						}
						.edit-btn {
							opacity: 0;
							padding: 3px 8px;
							@include transition-default;
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: none;
							}
						}
						.target-btn {
							padding: 0;
							color: $color-primary1;
							.icon {
								margin-right: 0.5em;
							}
							.MuiTouchRipple-root {
								display: none;
							}
							svg {
								path {
									fill: $color-primary1;
								}
							}
							&:hover {
								background: transparent;
							}
						}
					}
					.user-col3 {
						width: 22%;
						.set-timeline-area {
							.timeline-field {
								.calendar-btn {
									button {
										.MuiButton-label{
											min-width: 115px;
										}
									}
								}
							}
						}
						.MuiInputLabel-root {
							display: none;
						}
						.MuiInputBase-input {
							padding: 0;
							width: 3.2em;
						}
						button {
							padding: 0;
						}
					}
				}

				&:hover {
					.MuiList-root {
						.user-col2 {
							.edit-btn {
								opacity: 1;
							}
						}
					}
				}
				.calender-date {
					&.helperText-bottom {
						.MuiInputBase-root {
							border: 0px !important;
						}
						.MuiFormHelperText-root {
							width: 150px;
							bottom: -60px;
							left: -8px;
						}
					}
				}
			}
		}
	}
}
