.list-panel-wrap {
	.okr-list-panel-head {
		ul {
			@include flex;
			li {
				width: 33.33%;
				min-width: 33.33%;
				align-items: flex-end;
			}
		}
	}
}
.list-panel-content {
	ul {
		@include flex;
		@include align-items(flex-start);
		padding: 0;
		width: 100%;
		li {
			width: 33.33%;
			min-width: 33.33%;
			padding: 1em 0.75em 1em 1.25em;
		}
	}
}
