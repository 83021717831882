@import '../../sass-utils/variables';

.weekly-check-ins-wrap {
	margin-top: 0.5em;
	/**checkin head css start here**/
	.check-ins-head {
		@include flex;
		@include align-items(baseline);
		padding: 0.8em 0.625em 1.25em;
		h5 {
			color: $color-primary2;
			opacity: 0.5;
			margin-left: 0.625em;
		}
		.red {
			color: $color-text-info;
			opacity: 1;
		}
	}
	/**checkin content css start here**/
	.check-ins-content {
		padding: 0.625em 0 25px;
		background: $color-white;
		@include border-radius(6px);
		@include flex;
		@include justify-content('space-between');
		.col-one-third {
			width: 410px;
			padding: 0 20px;
			border-left: 1px solid $color-hover;
		}
		.col-two-third {
			width: calc(100% - 410px);
			padding: 0 1.875em;
		}
		.check-ins-content-head {
			padding: 40px 0 20px;
			.user-avatar {
				width: 80px;
				height: 80px;
				@include border-radius(100%);
				font-size: 2em;
			}
			.user-info-details {
				position: relative;
				flex: 1 1;
				width: calc(100% - 95px);
			}
		}
		.back-to-main {
			padding-bottom: 0.625em;
			position: absolute;
			left: 0;
			top: -24px;
			a {
				color: $color-primary1;
				font-style: normal;
				margin-right: 0.625em;
				svg {
					display: inline-block;
					vertical-align: middle;
					margin-top: -2px;
					path {
						fill: #39a3fa;
					}
				}
			}
			p {
				font-size: $font-size-h6;
				line-height: $line-height5;
				color: rgba($color-primary2, 0.5);
				font-style: italic;
				font-weight: $font-weight-bold;
			}
		}

		.user-quick-search-type2 {
			margin-top: 1.25em;
		}
	}
}
/**status**/
.week-status {
	width: 14px;
	min-width: 14px;
	min-height: 14px;
	height: 14px;
	@include border-radius(100px);
	border: 2px solid $color-primary1;
	background: $color-white;
	margin-right: 5px;
	&.checked-in {
		background: $color-completed;
		border-color: $color-completed;
	}
	&.checked-in-missed {
		background: $color-checked-in-missed;
		border-color: $color-checked-in-missed;
	}
	&.checked-no-data {
		background: $color-checked-in-missed;
		border-color: $color-checked-in-missed;
	}
}

/**checkins search css start here**/
.check-in-search {
	margin-top: 1.25em;
	max-width: 390px;
	> div {
		background: $color-white;
		padding: 0 0.625em;
		z-index: 2;
	}
	.rbt-aux {
		display: none;
	}
	// .rbt-input-main {
	// 	padding: 0 2em 0 2.6875em;
	// 	height: 50px;
	// 	font-weight: $font-weight-regular;
	// 	font-size: $font-size-h5;
	// 	line-height: $line-height4;
	// 	color: $color-primary2;
	// 	max-width: 390px;
	// 	width: 100%;
	// 	border: none;
	// 	border-bottom: 1.4px solid rgba($color-input-field, 0.5);
	// 	background: url(../../../images/search-icon.svg) no-repeat 6px center;
	// 	background-size: 14px;
	// 	background-color: $color-white;
	// 	position: relative;
	// 	z-index: 2;
	// 	&:focus {
	// 		background: url(../../../images/search-icon-blue.svg) no-repeat 6px center;
	// 		background-size: 14px;
	// 	}
	// }
	// .rbt-menu {
	// 	width: 100% !important;
	// 	max-width: 390px !important;
	// 	border: 0px;
	// 	@include panel-shadow;
	// 	margin-top: -3.125em;
	// 	padding: 3.125em 0 0 0;
	// 	margin-left: -0.625em;
	// 	@include border-radius(6px);
	// 	z-index: 1;
	// 	max-height: 384px !important;
	// 	a {
	// 		&.disabled {
	// 			cursor: default;
	// 		}
	// 	}
	// }
}

/**member list css start here**/
.reportees-members {
	margin-top: 2.25em;

	> h5 {
		padding: 0 0.625em !important;
	}
	.reportees-members-list {
		margin-top: 0.625em;
		li {
			padding: 10px 0.625em;
			@include align-items(flex-start);
			margin-bottom: 8px;
			@include border-radius(6px);
			cursor: pointer;
			&:hover {
				background: $color-hover;
			}
			.user-info {
				flex: 1 1;
			}
			.member-status {
				@include flex;
				margin-top: 3px;
				.week-status {
					margin: 0 0.4375em;
					&:last-child {
						margin-right: 0px;
					}
				}
			}
			&.selected {
				background: $color-secondary2;
			}
		}
	}
	.no-record {
		margin-top: 2.25em;
		text-align: center;
		padding: 0 30px;
		object {
			margin-bottom: 15px;
		}
		h2 {
			margin-bottom: 5px;
		}
	}
}
/* Weekly Checkins Questions Answers */
.ques-ans-area {
	.ques-ans-head {
		@include flex;
		@include justify-content(flex-end);
		.cont-style {
			@include flex;
			@include flex-direction(column);
			@include justify-content(flex-start);
			@include align-items(flex-end);
		}
		.text-style {
			margin-right: 15px;
			color: $color-primary2;
			opacity: 0.5;
		}
		.btn-link {
			height: auto;
			line-height: $line-height2;
			svg {
				margin-right: 5px;
				path {
					fill: $color-primary1;
				}
			}
			&.Mui-disabled {
				svg {
					path {
						fill: rgba(0, 0, 0, 0.26);
					}
				}
			}
		}
	}
	.ques-ans-list {
		padding: 1.25em 0 1em 2.625em;
		> li {
			position: relative;
			padding: 1.75em 2em 2.25em;
			margin-bottom: 10px;
			@include flex-direction(column);
			align-items: unset;
			background: $color-bg-form;
			@include border-radius(6px);
			.qa-list-index {
				margin-right: 1em;
				position: absolute;
				right: 100%;
				top: 26px;
				width: 30px;
				height: 30px;
				z-index: 1;
				background: $color-white;
				border: 1.4px solid $color-primary1;
				@include border-radius(100%);
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				color: $color-primary1;
				font-size: $font-size-h5;
			}
			.ans-box {
				margin-top: 15px;
				.MuiFormControl-root {
					min-width: 100%;
				}
				.inactive {
					textarea {
						background: $color-hover !important;
					}
				}
				.MuiInputBase-root {
					padding: 0;
					//border: 1.4px solid transparent;
					@include border-radius(6px);
					@include transition(0.25s);
					&:before,
					&:after {
						display: none;
					}
					textarea {
						@include transition(0.25s);
						height: auto;
						padding: 1em 1.25em;
						font-size: $font-size-h5;
						line-height: 1.25;
						//border: 1.4px solid rgba($color-input-field, 0.5);
						@include border-radius(6px);
						background: $color-white;
					}
					&.Mui-focused {
						textarea {
							box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
						}
					}
				}
				.MuiTypography-body2 {
					font-size: $font-size-h5;
					line-height: 19px;
					color: $color-primary2;
					font-weight: $font-weight-regular;
				}
			}
			.action-btn {
				@include flex;
				width: 100%;
				@include justify-content(flex-end);
				margin-top: -2px;
				padding-right: 5px;
				.ans-edit-actions {
					@include flex;
					button {
						padding: 0.1em;
						min-width: auto;
						border-radius: 0px 0px 4px 4px;
						svg {
							width: 12px;
							height: 12px;
							path {
								fill: $color-primary1;
							}
						}
						&.task-edit-save {
							background: $color-primary1;
							svg {
								width: 14px;
								height: 14px;
								path {
									fill: $color-white;
								}
							}
						}
						&.task-edit-cancel {
							margin-right: 2px;
							color: rgba($color-input-field, 0.5);
							svg {
								path {
									fill: rgba($color-input-field, 0.5);
									stroke: rgba($color-input-field, 0.5);
								}
							}
							&:hover {
								background-color: transparent;
								svg {
									path {
										fill: $color-primary2;
										stroke: $color-primary2;
									}
								}
							}
						}
					}
				}
				.saving-changes {
					text-transform: uppercase;
					font-weight: $font-weight-bold;
					color: rgba($color-primary2, 0.5);
					@include flex;
					@include align-items(center);
					padding-top: 5px;
					svg {
						width: 14px;
						margin-right: 7px;
						path {
							fill: $color-primary2;
						}
					}
				}
			}
			&:after {
				margin-right: 1.75em;
				position: absolute;
				right: 100%;
				top: 0;
				content: '';
				width: 1.4px;
				height: calc(100% + 10px);
				background: $color-hover;
			}
			&:first-child {
				&:after {
					top: 56px;
					height: calc(100% - 46px);
				}
			}
			&:last-child {
				&:after {
					height: 26px;
				}
			}
		}
	}
	.ques-ans-list-bottom {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding-left: 2.625em;
		.ques-ans-notes {
			max-width: 450px;
			// p {
			// 	font-size: $font-size-h5;
			// 	font-style: italic;
			// 	font-weight: $font-weight-bold;
			// 	line-height: $line-height4;
			// 	opacity: 0.7;
			// 	strong {
			// 		font-weight: $font-weight-bold;
			// 	}
			// }
		}
	}
	.checkins-no-data {
		width: 100%;
		min-height: 400px;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		.no-record {
			text-align: center;
			object {
				margin-bottom: 15px;
			}
			h2 {
				margin-bottom: 5px;
			}
		}
	}
}
.tab-scroller {
	width: auto;
	max-width: 610px;
	margin-top: 0.625em;
	@include flex;
	.MuiTabs-root {
		min-height: 36px;
		background: $color-bg-tabscroller;
		padding: 0px;
		@include border-radius(100px);
	}
	.MuiTab-root {
		padding: 10px 6px;
		@include border-radius(100px);
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		line-height: $line-height5;
		text-transform: uppercase !important;
		color: $color-primary2;
		min-width: 130px;
		margin: 5px 2px;
		min-height: 36px;
		opacity: 1;
		.MuiTab-wrapper {
			flex-direction: row;
		}
		&.Mui-selected {
			background-color: $color-white;
			@include default-shadow;
			color: $color-primary1;
		}
	}
	.MuiTabScrollButton-root {
		width: 36px;
		.MuiTouchRipple-root {
			display: none;
		}
	}
	.MuiTabScrollButton-root {
		opacity: 1;
		svg {
			font-size: 1.5em;
		}
		&.Mui-disabled {
			opacity: 1;
			svg {
				opacity: 0.4 !important;
			}
		}
	}
	.MuiTabs-indicator {
		display: none;
	}
}

.loading-center {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}
.highcharts-tooltip > span {
	background: $color-primary2;
	border: 0px;
	@include border-radius(2px);
	box-shadow: none;
	padding: 8px;
	z-index: 9999 !important;
}
