@import '../styles/sass-utils/variables';

/**Tab panel okr card**/
.okr-card-main {
	width: 100%;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 1.25em;
	display: grid;
	&.okr-directReport {
		.okr-tabpanel-card {
			cursor: pointer;
			// @include transition(1s);
			transition: all 1s ease !important;
			&:hover {
				@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			}
		}
	}
}
.okr-tabpanel-card {
	margin-top: 1.375em;
	border-radius: 6px;
	min-height: 157px;
	background: $color-white;
	@include flex;
	@include flex-direction(column);
	@include justify-content(space-between);
	@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
	border: 1.4px solid $color-border-tile;

	&.feedbackDrawer {
		@include box-shadow(0px 0px 0px rgba($color-primary2, 0.1));
		min-height: auto;
		margin-top: 0;
		.okr-tabpanel-card-top {
			padding: 0;
		}
	}
	.okr-tabpanel-card-top {
		@include flex;
		@include justify-content(space-between);
		padding: 1.25em;

		.user-info-details {
			a {
				color: $color-primary1;
				text-decoration: underline;
			}
			.contribution-text {
				padding: 0.25em 0 1em;
				font-weight: $font-weight-bold;
			}
		}
		.okr-tabpanel-card-actions {
			@include flex;
			.kebab-icon {
				margin-top: -0.75em;
				margin-right: -1.1em;
			}
			.progressChart {
				max-width: 3.625em;
				.CircularProgressbar {
					.CircularProgressbar-text {
						font-size: 20px !important;
						font-weight: bold;
						font-weight: $font-weight-regular;
					}
				}
			}
		}
	}
	.okr-tabpanel-card-bottom {
		padding: 0.75em 1.25em;
		.user-progress-text {
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			height: 2.25em;
			.MuiTypography-body2 {
				font-weight: $font-weight-bold;
				&.progress-not-started {
					color: $color-text-info;
				}
				&.progress-started {
					color: $color-primary1;
					@include flex;
					@include align-items(center);
					cursor: pointer;
					svg {
						margin-right: 0.25em;
					}
				}
			}
		}
		.user-progress-bar {
			button {
				padding: 0;
				border: none;
				background: transparent;
				width: 100%;
				text-align: left;
			}
			.MuiSlider-root {
				padding: 0;
				height: 15px;
				display: block;
				@include border-radius(15px);
				overflow: hidden;
				.MuiSlider-rail {
					height: 15px;
					opacity: 1;
					background: $color-not-started;
					@include border-radius(15px);
				}
				.MuiSlider-track {
					height: 15px;
					@include border-radius(15px);
				}
				.Mui-disabled {
					display: none;
				}
				.MuiSlider-thumb {
					width: 14px;
					height: 14px;
					margin-top: -7px;
					margin-left: -5px;
					top: 50%;
					background: $color-white;
					border: 1px solid $color-input-field;
					opacity: 0.5;
				}
				&.grey {
					.MuiSlider-track {
						background-color: $color-not-started;
					}
				}
				&.green {
					.MuiSlider-track {
						background-color: $color-on-track;
					}
				}
				&.yellow {
					.MuiSlider-track {
						background-color: $color-lagging;
					}
				}
				&.red {
					.MuiSlider-track {
						background-color: $color-text-info;
					}
				}
			}
		}
		.tabpanel-okr-counts {
			@include flex;
			@include justify-content(space-between);
			padding-top: 0.25em;
		}
	}
	.okr-counts-text {
		@include flex;
		.MuiTypography-h6 {
			padding-right: 10px;
			margin-right: 5px;
			position: relative;
			&:after {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 5px;
				height: 5px;
				content: '';
				background: $color-input-field;
				@include border-radius(10px);
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
	.user-count-txt {
		span {
			@include flex;
			@include align-items(center);
		}
		svg {
			margin-right: 0.2em;
			width: 1em;
			path {
				fill: $color-secondary1;
			}
		}
	}
	.direct-reports-bottom {
		@include flex;
		@include justify-content(space-between);
		@include align-items(center);
		padding: 0.75em 1.25em;
		border-top: 1px solid $color-hover;
		.direct-report-team-cont {
			@include flex;
			@include align-items(center);
			padding: 2px;
		}
		.teams-tags {
			@include flex;
			@include align-items(center);

			.team-tag {
				@include flex;
				@include border-radius(3px);
				overflow: hidden;
				margin-right: 0.5em;
				.MuiTypography-body2 {
					padding: 0.2em 0.45em;
					font-weight: $font-weight-bold;
				}
				.team-progress {
					.MuiTypography-body2 {
						color: $color-white;
						min-width: 35px;
						text-align: center;
					}
					&.red {
						background: $color-text-info;
					}
				}
				.team-tag-text {
					max-width: 100px;
				}
			}
		}
	}
	&.okr-tabpanel-compact-card {
		min-height: 7.375em;
		@include justify-content(flex-start);
		.okr-tabpanel-card-top {
			padding-bottom: 0px;
			.user-info-text {
				.contribution-text {
					padding-bottom: 0px;
				}
			}
			.okr-tabpanel-card-actions {
				.progressChart {
					margin-top: 0px;
					padding-top: 0.8125em;
				}
			}
		}
		.okr-tabpanel-card-bottom {
			height: auto;
			padding-top: 0px !important;
			.user-progress-text {
				height: auto;
				margin-top: -0.25em;
			}
			.tabpanel-okr-counts {
				margin-top: -0.25em;
				padding-top: 0px;
			}
		}
	}
	&.okr-tabpanel-ultracompact-card {
		min-height: 4.125em;
		max-width: 25.25em !important;
		min-width: 25.25em !important;
		.okr-tabpanel-card-top {
			padding: 0.6875em 1.25em;
			min-height: 4.125em;
			.user-info-details {
				@include align-items(center);
			}
			.okr-tabpanel-card-actions {
				@include align-items(center);
				.kebab-icon {
					margin-top: 0 !important;
					margin-right: -0.7em !important;
				}
			}
			.user-info-text .contribution-text {
				padding-bottom: 0;
			}
		}
		.progressText {
			padding-right: 0.625em;
			.MuiTypography-body1 {
				font-size: $font-size-h5;
				font-weight: $font-weight-bold;
			}
		}
	}
	.contribution-text {
		cursor: pointer;
		@include flex;
		@include align-items(center);
		svg {
			margin-right: 3px;
		}
	}
}

/**Team Filter Buttons**/
.teams-filter {
	@include flex;
	@include align-items(center);
	.teams-filter-btns {
		button {
			margin-right: 0.5em;
			padding: 0.33em 1.07em;
			font-size: $font-size-h5;
			font-weight: $font-weight-bold;
			@include border-radius(0.375em);
			@include transition-default;
			min-height: 32px;
			&:hover {
				@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
			}
			&.all-teams-btn {
				background: $color-white;
				border: 1.4px solid rgba($color-input-field, 0.5);
				&.active {
					@include box-shadow(5px 8px 24px rgba($color-primary2, 0.2));
				}
			}
			&.team-btn {
				padding: 0 0 0 1.07em;
				border: 0;
				//color: $otherColor38;
				//background: $otherColor39;
				&.active {
					@include box-shadow(0px 10px 30px rgba($color-primary2, 0.2));
				}
				.percentage {
					padding: 7px;
					@include border-radius(0 5px 5px 0);
					color: $color-white;
					margin-left: 10px;
				}
				&:last-child {
					//color: $otherColor40;
					//background: $color-bg-info;
					border: 0;
				}
			}
		}
	}
	.teams-filter-dropdown {
		button {
			width: 30px;
			height: 30px;
			@include border-radius(30px);
			svg {
				max-width: 1.07em;
			}
		}
	}
	&.team-filter-no-percentage {
		.teams-filter-btns {
			button {
				&.team-btn {
					padding: 0 1.07em 0 1.07em;
				}
			}
		}
	}
}

/**OKR Popover(list of OKR & KR)**/
.okr-popover-dropdown {
	//background: $color-white;
	//box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
	//border-radius: 0.375em;
	//min-width: 480px;
	//max-width: 480px;
	z-index: 9999;
	&.scale-popover-dropdown {
		.okr-popover-dropdown-wrap {
			transform-origin: top left;
		}
		&[x-placement='top-start'] {
			.okr-popover-dropdown-wrap {
				transform-origin: bottom left;
			}
		}
	}
	.okr-popover-dropdown-wrap {
		background: $color-white;
		box-shadow: 0px 0px 30px rgba($color-primary2, 0.1);
		border-radius: 0.375em;
		min-width: 480px;
		max-width: 480px;
		z-index: 9999;
	}
	.okr-popover-dropdown-inner {
		max-height: 350px;
		overflow-y: auto;
		@include custom-scrollbar;
	}
	ul.okr-popover-okr-list {
		padding: 0px 0;
		width: 100%;
		> li {
			display: block;
			border-bottom: 1px solid $color-hover;
			padding: 10px;
			margin-bottom: 0px;
			cursor: default;
			&:last-child {
				margin-bottom: 0px;
				border-bottom: 0px;
			}
			.okr-popover-okr {
				@include flex;
				@include justify-content(space-between);
				padding: 0 0.625em 0.625em;
				cursor: default;
				.okr-popover-okr-left {
					width: 78%;
					@include flex;
					@include align-items(flex-start);
					padding-top: 0.571em;
					.okr-title {
						margin-left: 0.625em;
						margin-bottom: 0.3125em;
						width: 100%;
						.MuiTypography-h4 {
							overflow: hidden;
							white-space: normal;
							font-size: $font-size-h5;
							line-height: 1.42em;
							&.cursor {
								cursor: pointer;
							}
						}
					}
				}
				.okr-popover-okr-right {
					text-align: right;
					.progressChart {
						max-width: 58px;
						.CircularProgressbar {
							.CircularProgressbar-text {
								font-size: 20px !important;
								font-weight: bold;
								font-weight: $font-weight-regular;
							}
						}
					}
				}
			}
			&:hover {
				background: none;
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
	ul.okr-popover-kr-list {
		padding: 0;
		width: 100%;
		li {
			@include flex;
			@include justify-content(space-between);
			padding: 3px 0.625em;
			cursor: default;
			background: $color-bg-tag !important;
			border: 1px solid $color-border-tile;
			@include border-radius(6px);
			margin-bottom: 0.3125em;
			&:last-child {
				margin-bottom: 0px;
			}
			.okr-popover-okr-left {
				width: 78%;
				@include flex;
				.okr-title {
					margin-left: 0.625em;
					margin-bottom: 0;
					width: 100%;
					.MuiTypography-h4 {
						overflow: hidden;
						// text-overflow: ellipsis;
						font-weight: normal;
						font-size: $font-size-h5;
						&.cursor {
							cursor: pointer;
						}
					}
				}
			}
			.okr-popover-okr-right {
				text-align: right;
				.kr-score {
					font-size: $font-size-h5;
					min-width: 51px;
					background: $color-bg-krscore;
					border: 1px solid $color-border-krscore;
					box-sizing: border-box;
					@include border-radius(6px);
					color: $color-lagging;
					font-weight: $font-weight-bold;
					padding: 0;
					margin: 0;
					text-align: center;
					height: 2em;
					line-height: 2em;
				}
			}
		}
	}
	.standalone-krlist {
		padding: 1em 0.625em 1em;
		border-top: 1px solid $color-hover;
		position: relative;
		margin-top: 1.28em;
		.standalone-head {
			position: absolute;
			top: -9px;
			font-size: 10px;
			color: rgba($color-primary2, 0.5);
			//opacity: 0.5;
			padding-right: 0.5em;
			padding-left: 0.5em;
			left: 0.5em;
			background: $color-white;
		}
		ul.okr-popover-kr-list {
			li {
				margin-bottom: 0.6em;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
/**Description arrow**/
.description-arrow {
	background: url(../images/arrow-125.svg) no-repeat center 0px;
	cursor: pointer;
	font-size: 0px;
	width: 15px;
	height: 15px;
	display: inline-block;
	margin-left: 5px;
	svg {
		transform: rotate(-145deg);
		path {
			fill: $color-primary1;
		}
	}
	.MuiTouchRipple-root {
		display: none;
	}
	&.description-arrow-tooltip {
		position: absolute;
		right: 0px;
		bottom: 9px;
	}
}
.alignment-body-class {
	.description-arrow,
	.description-arrow-tooltip {
		display: none;
	}
}

/** Currency icon for popover*/
.currencyWrapper {
	@include flex;
	@include align-items(center);
	@include justify-content(flex-start);
	svg {
		width: 12px;
		height: 12px;
	}
}

.okr-nature-type-tag {
	@include flex;
	.MuiTypography-body1 {
		text-transform: uppercase;
		//color: rgba($color-primary2, 0.7);
		font-weight: $font-weight-bold;
		font-size: $font-size-subtitle2;
		line-height: 1.6;
		border-radius: 1em;
		letter-spacing: 0.1px;
	}
	.kr-nature-type {
		padding: 0 0.375em;
		margin-bottom: 0.375em;
		@include flex;
		@include align-items(center);
		background-color: $color-hover;
		@include border-radius(3px);

		svg {
			width: 12px;
			height: 12px;
			margin-right: 5px;
		}
	}
}

.not-achieved {
	color: $color-text-info !important;
}
.avatar-decline {
	display: none !important;
}

/** common css class start here**/
.display-flex {
	@include flex;
}
.display-flex-space-between {
	@include flex;
	@include justify-content(space-between);
	flex: 1;
	align-items: center;
}

.no-record-max {
	max-width: 430px;
	margin: 0 auto !important;
}
/** no-record css **/
.no-okr-area {
	text-align: center;
	h2 {
		margin-bottom: 0.5em;
	}
}
.no-records-area {
	@include flex;
	@include justify-content(center);
	padding: 100px 60px;
	min-height: calc(100vh - 165px);
	background-image: url(../images/blank-screen-bg2.svg), url(../images/blank-screen-bg.svg);
	background-position: left bottom, center bottom;
	background-repeat: no-repeat, no-repeat;
	.no-record-message {
		@include flex;
		@include flex-direction(column);
		@include align-items(center);
		.MuiTypography-h2 {
			padding-bottom: 0.25em;
			margin-bottom: 0;
		}
		.get-started-card {
			padding: 1em 1em 0.875em;
			margin-top: 2.25em;
			max-width: 450px;
			@include flex;
			@include align-items(center);
			background: $color-white;
			border: 1.4px solid $color-border-tile;
			@include border-radius(6px);
			.add-user-img {
				margin: 0 14px -22px -50px;
			}
			.MuiTypography-h4 {
				padding-bottom: 0.375em;
				color: rgba($color-primary2, 0.7);
			}
			.MuiLink-root {
				@include flex;
				@include align-items(center);
				text-transform: uppercase;
				color: $color-primary1;
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				text-decoration: none;
				svg {
					margin-right: 0.25em;
				}
			}
		}
		.or-separator {
			margin: 1.875em 0;
			padding: 0 50px;
			position: relative;
			opacity: 0.5;
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 10px;
				width: 40px;
				height: 2px;
				background: $color-secondary1;
			}
			&:before {
				left: 0;
			}
			&:after {
				right: 0;
			}
		}
		.create-align-link-area {
			margin-top: 30px;
			@include flex;
			.create-align-card {
				max-width: 385px;
				padding: 30px;
				margin: 0 15px;
				@include flex;
				@include align-items(center);
				background: $color-white;
				border: 1.4px solid #cde4ef;
				@include border-radius(6px);
				cursor: pointer;
				.card-left {
					margin-right: 30px;
					@include flex;
					@include align-items(flex-start);
					svg {
						margin-right: 0.5em;
						margin-top: -3px;
					}
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
					line-height: $line-height3;
				}
				&:hover {
					@include box-shadow(2px 0px 10px rgba(41, 41, 41, 0.12));
					.add-btn-white {
						background: $color-primary1;
						svg {
							path {
								fill: $color-white;
							}
						}
					}
				}
				&.active {
					border: 1.4px solid $color-primary1;
				}
			}
		}
	}
}
.unlock-me-wrapper {
	.no-records-area {
		//min-height: calc(100vh - 124px);
	}
}
/**back to main link**/
.back-to-main {
	padding-bottom: 0.625em;
	a {
		color: $color-primary1;
		font-style: normal;
		margin-right: 0.625em;
		svg {
			display: inline-block;
			vertical-align: middle;
			margin-top: -2px;
			margin-right: 3px;
			width: 16px;
			height: 16px;
			path {
				fill: $color-primary1;
			}
		}
	}
	p {
		color: rgba($color-primary2, 0.5);
	}
	&.cycle-back {
		padding-bottom: 0;
		padding-left: 3%;
		padding-right: 3%;
		margin-top: 0;
		position: absolute;
		width: 100%;
		top: 10px;
		z-index: 99;
	}
	&.cycle-back-amaps {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		margin-top: 0;
		position: absolute;
		width: 100%;
		top: -13px;
		left: 0px;
		z-index: 99;
	}
}

/**left tabs space css start here**/
.okr-tabs-space {
	.MuiTabs-scroller {
		&.MuiTabs-fixed {
			margin-left: -1em;
		}
	}
}
/**free trial start form css start here**/
.free-trial-create-form {
	padding: 1.25em 3.75em 2.5em;
	max-width: 980px;
	margin: 0 auto;
	.logo-img {
		max-width: 160px;
		margin: 0 auto 1.875em;
		img {
			max-width: 100%;
		}
	}
	form {
		background: $color-white;
		padding: 2.5em 0;
		@include border-radius(6px);
		width: 100%;
	}
	.form-submit {
		padding: 0 3.75em;
		button {
			margin: 1em 0 0;
		}
	}
}
/**free trial auth popup css start here**/
.free-auth-popup {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	z-index: 9999 !important;
	.free-auth-popup-inner {
		position: absolute;
		width: 30em;
		padding: 30px;
		background: $color-white;
		@include border-radius(6px);
		.drawer-form-fields {
			.drawer-input-field {
				padding-left: 0px;
				padding-right: 0px;
				&.form-submit {
					padding-bottom: 0px;
					button {
						margin-left: 0px;
					}
				}
			}
		}
	}
}

.free-trial-msg {
	color: $color-text-info !important;
	margin-top: 30px !important;
}

/**css for support org wrapper css start here**/
.support-org-wrapper {
	.filters {
		@include align-items(center);
	}
	.select-filter {
		padding: 0px;
		min-width: 115px;
		.MuiSelect-select {
			padding: 8px 32px 8px 16px;
			font-size: $font-size-base;
			background: none;
			text-align: center;
		}
		.MuiSelect-icon {
			width: 25px;
			height: 25px;
		}
	}
	table {
		tr {
			td {
				.kebab-icon {
					display: inline-block;
					padding: 6px;
					text-align: center;
				}
			}
		}
	}
}
.okr-default-dropdown.select-filter-dropdown {
	.MuiMenu-list {
		width: 200px;
		.MuiListItem-root {
			height: auto;
			padding: 0.625em 1em;
		}
	}
	&.full-width-dropdown {
		.MuiMenu-list {
			width: 100%;
		}
	}
}

.impersonate-drawer {
	.chip-group {
		.btn-link-type1 {
			&:hover {
				text-decoration: underline !important;
			}
		}
	}
	#sort-email {
		.sortable-label {
			cursor: default;
		}
	}
	.drawer-user-search-field {
		position: relative;
		margin-bottom: 10px;
		.user-search-chip {
			margin-top: 5px;
			position: absolute;
			top: 40px;
			@include flex;
			@include justify-content(flex-start);
			ul {
				@include flex;
				@include align-items(center);
				@include justify-content(flex-end);
				padding: 0;
				.MuiListItem-gutters {
					padding: 0;
					margin-left: 0;
					&.search-more {
						.MuiTypography-body1 {
							background: $color-white;
							width: 24px;
							height: 24px;
							border-radius: 50%;
							border: 1.4px solid $color-border-tile;
							line-height: 20px;
							text-align: center;
							font-size: 10px;
							font-weight: $font-weight-bold;
						}
					}
					&.clear-all {
						.MuiButton-root {
							min-width: 50px;
							margin: 0;
							height: auto;
							line-height: initial;
							font-size: $font-size-h6;
							line-height: $line-height5;
							font-weight: $font-weight-bold;
						}
					}
				}
			}
			.MuiChip-root {
				border-radius: 24px;
				padding-right: 2px;
			}
			svg {
				width: 10px;
				height: 10px;
				path {
					stroke: $color-primary2;
					fill: $color-primary2;
				}
			}
			.MuiChip-deleteIcon {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				margin: 0 0 0 5px;
				width: 20px;
				height: 20px;
				&:hover {
					svg {
						path {
							stroke: $color-text-info;
							fill: $color-text-info;
						}
					}
				}
			}
		}
	}
}
.expiry-date-picker {
	.MuiFormControl-root {
		width: 100%;
	}
}
