.department-collapsible {
	.department-collapsible-body {
		.department-collapsible-head {
			.department-collapsible-head-content {
				.rotating-arrow {
					padding: 15px 10px;
					@include flex;
					cursor: pointer;
					border-bottom: 1.4px solid $color-hover;
					svg {
						@include transition(0.5s);
						margin-top: 4px;
						margin-right: 12px;
						width: 14px;
						height: 14px;
					}
					&:hover {
						background: $color-hover;
					}
					&.expand {
						border-bottom: 1.4px solid transparent;
						background: $color-hover;
					}
				}
			}
		}
		.department-collapsible-content {
			padding: 10px 0;
		}
		&.expand-row {
			border-bottom: none;
			.rotating-arrow {
				svg {
					@include transform(rotate(90deg));
				}
			}
		}
	}
}
.department-content {
	.department-title {
		position: relative;
		margin: 10px 0;
		span {
			position: relative;
			background: $color-white;
			font-size: $font-size-h6;
			line-height: $line-height5;
			font-weight: $font-weight-bold;
			color: $color-secondary1;
			padding-right: 15px;
		}
		&::before {
			content: '';
			width: 100%;
			height: 1.4px;
			background: $color-hover;
			position: absolute;
			left: 0;
			top: 8.5px;
		}
	}
}
.import-okr-list {
	padding: 15px 5px 0 16px;
	margin-bottom: 10px;
	@include transition(0.3s);
	@include box-shadow(0px 0px 5px rgba($color-primary2, 0.1));
	@include border-radius(6px);
	&:last-child {
		margin-bottom: 20px;
	}
	.MuiCheckbox-root {
		margin-top: 3px;
		margin-right: 10px;
		padding: 0;
		&:hover {
			background: transparent !important;
		}
		&.MuiCheckbox-indeterminate {
			svg {
				path {
					fill: $color-white;
				}
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
	.import-obj-col {
		padding-right: 15px;
		@include flex;
		@include align-items(flex-start);
		.MuiTypography-body1 {
			flex: 1;
			font-weight: $font-weight-bold;
		}
	}
	.import-okr-actions {
		margin-left: 27px;
		padding: 8px 0 15px;
		.btn-blue-chip {
			font-size: $font-size-subtitle2;
			text-transform: uppercase !important;
			border-radius: 20px;
			svg {
				margin-right: 5px;
				width: 7px;
				height: 7px;
				path {
					stroke-width: 3px;
				}
			}
		}
	}
	.import-kr-list {
		padding: 5px 0;
		@include flex;
		@include align-items(flex-start);
		.MuiCheckbox-root {
			margin-top: 18px;
		}
		.import-kr-content {
			flex: 1;
			padding: 15px;
			@include border-radius(6px);
			background: $color-hover;
		}
	}
	&.expanded-okr {
		@include box-shadow(0px 0px 20px rgba($color-primary2, 0.1));
	}
}
