/**badge css**/
.MuiBadge-root {
	.MuiBadge-badge {
		&:after {
			width: 4px;
			height: 4px;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));
			content: '';
			background-color: $color-white;
			@include border-radius(4px);
		}
		&.MuiBadge-dot {
			background-color: $color-text-info;
			color: $color-white;
		}
	}
	/**badge icon **/
	&.badge-icon {
		.MuiBadge-badge {
			svg {
				width: 16px;
				height: 16px;
				margin: 0;
				path {
					fill: $color-white !important;
				}
			}
			&::after {
				display: none;
			}
		}
	}
	&.okr-badge-icon {
		position: absolute;
		right: -10px;
		top: -7px;
		padding: 0px;
		.MuiBadge-badge {
			position: static;
			transform: none;
			width: 16px;
			height: 16px;
			padding: 0;
		}
	}
}
/**alignment map card badge css**/
.alignment-card {
	.MuiBadge-anchorOriginTopRightRectangle {
		top: 3px;
		right: 7px;
	}
}
.ultra-compact-view {
	.MuiBadge-anchorOriginTopRightRectangle {
		right: -3px;
	}
}
.alignment-kr-card {
	.MuiBadge-anchorOriginTopRightRectangle {
		top: 2px;
		right: 4px;
	}
}
