.team-details {
	@include flex;
	.MuiTypography-h5 {
		position: relative;
		padding-bottom: 3px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: none;
		max-width: 100%;
	}
	.team-info {
		overflow: hidden;
		.MuiTypography-h4 {
			text-transform: initial;
		}
		.MuiTypography-subtitle2 {
			margin-right: 0.5em;
			@include flex;
			@include align-items(center);
			position: relative;
			padding-right: 0.8em;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 3px;
				height: 3px;
				@include border-radius(3px);
				background-color: $color-bg-dot;
				opacity: 0.5;
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
		svg {
			margin-right: 0.3125em;
			width: 14px;
			min-width: 14px;
			height: 14px;
			min-height: 14px;
			path {
				fill: $color-secondary1;
			}
		}
	}
}
