/**radial chart outer structure**/
.radial-chart {
	.e-circulargauge {
		@include flex;
		@include justify-content(center);
		position: static !important;
	}
	&.radial-high-chart {
		.radial-chart-area {
			margin: 0 auto;
			width: 320px;
			.highcharts-container {
				position: static !important;
			}
		}
		&.org-report {
			.radial-chart-area {
				width: 195px;
				margin: 0 auto;
			}
		}
	}
	.radial-chart-area {
		position: relative;

		.radial-chart-ct-image {
			width: 69px;
			height: 69px;
			position: absolute;
			top: 50%;
			left: 50%;
			//z-index: 9;
			overflow: hidden;
			background: $color-bg-teamstag;
			@include transform(translate(-50%, -50%));
			@include border-radius(50%);
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			font-size: $font-size-h2;
			text-transform: uppercase;
			font-weight: $font-weight-bold;

			svg {
				max-width: 24px;
				max-height: 24px;
			}
		}
	}
}
/**radial legend**/
.radial-legend {
	margin-top: 20px;
	ul {
		@include flex;
		@include justify-content(center);
		li {
			display: block;
			padding: 0;
			width: 115px;
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
				width: auto;
			}
		}
	}
	.legend-color {
		width: 23px;
		height: 5px;
		@include border-radius(5px);
	}
	p {
		font-weight: $font-weight-bold;
	}
	.MuiTypography-body2 {
		font-weight: $font-weight-bold;
	}
	&.radial-legend-team {
		ul {
			li {
				margin-right: 5px;
				&:last-child {
					width: 123px;
					margin-right: 0;
				}
			}
		}
	}
}
/** Default legend **/
.chart-legend-default {
	.MuiList-root {
		@include flex;
		.MuiListItem-root {
			width: auto;
			padding-right: 5px;
			&:last-child {
				margin-right: 0;
			}
			.legend-cirlce {
				width: 8px;
				height: 8px;
				margin-right: 6px;
				@include border-radius(8px);
			}
		}
	}
}

.column-chart {
	.chart-legend-default {
		margin-top: 40px;
	}
}
