@import '../../styles/sass-utils/variables';

.whats-new-wrapper {
	max-width: 1160px;
	margin: 0 auto;
}
.whats-new-header {
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
	padding-top: 20px;
	padding-bottom: 20px;
	.logo {
		svg {
			max-width: 170px;
			width: 170px;
			height: 40px;
		}
	}
}
.whats-new-footer {
	max-width: 1160px;
	width: 100%;
	position: fixed;
	left: auto;
	right: auto;
	bottom: 0;
	z-index: 9;
	@include flex;
	@include justify-content(space-between);
	padding: 20px 0;
	border-top: 1.4px solid rgba(161, 171, 185, 0.5);
	background-color: $color-secondary2;
	.footer-left {
		@include flex;
		@include align-items(baseline);
		.footer-logo {
			margin-right: 10px;
			svg {
				width: 100px;
				height: 20px;
			}
		}
	}
	.footer-right {
		@include flex;
		@include align-items(center);
		a {
			width: 20px;
			height: 20px;
			margin-left: 10px;
		}
	}
	.MuiTypography-body2 {
		font-weight: $font-weight-regular;
		line-height: $line-height2;
	}
}
/**whats new tab css start here**/
.whats-tabs-wrap {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	position: relative;
	&::after {
		content: '';
		border-bottom: 1px solid rgba($color-primary2, 0.2);
		width: 100%;
		height: 1px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
}
.whats-new-tab {
	&.okr-tabs {
		margin-right: 0px;

		.MuiTabs-fixed {
			margin-left: -1.25em;
		}
	}
}
/**whats new tab panel start here**/
.whats-new-tabpanel-wrap {
	padding: 18px 0;
}

/**whats ne version start here**/
.whats-new-version {
	@include flex;
	@include align-items(center);
	@include justify-content(space-between);
	.version-left {
		@include flex;
		align-items: center;
		.btn-circle-white {
			margin-left: 10px;
			width: 30px;
			height: 30px;
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}
	.btn-primary {
		.icon {
			svg {
				width: 10px;
				margin-right: 6px;
				path {
					stroke-width: 1px;
					fill: $color-white;
					stroke: $color-white;
				}
			}
		}
	}
}

/**Add version modal css start here**/
.version-modal {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	z-index: 9991 !important;
	.version-modal-inner {
		width: 50em;
		//min-height: 28.75em;
		background: $color-white;
		@include border-radius(6px);
		@include box-shadow(0px 0px 60px rgba($color-primary2, 0.2));
		padding: 0;
		.drawer-input-field-first {
			padding-bottom: 20px !important;
			.helperText-bottom .MuiFormHelperText-root {
				bottom: -20px;
			}
		}
		.disable-label {
			opacity: 0.8 !important;
			color: rgba($color-primary1, 0.5);
		}
		.range-selector-calender {
			.form-control {
				padding: 0.69em 1em;
				font-size: 14px;
				line-height: 18px;
				border: 1.4px solid rgba(161, 171, 185, 0.5);
				border-radius: 6px;
				width: 100%;
			}
			input[disabled] {
				opacity: 0.8 !important;
				color: rgba($color-primary1, 0.5);
			}
		}
		.modal-head {
			padding: 10px 30px;
		}
		.modal-footer {
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			@include box-shadow(0px 0px 17px rgba($color-primary2, 0.08));
			padding: 20px 3.75em;
			margin-top: 10px;
		}
		.modal-content {
			max-height: calc(475px - 60px);
			overflow: auto;
			@include custom-scrollbar;
			form {
				padding-top: 30px;
			}
			.field-col2-split {
				@include flex;
				@include justify-content(space-between);
				@include align-items(center);
				padding-top: 26px;
				.btn-with-border {
					min-width: 142px;
				}
				button {
					margin-right: 0px;
				}
			}
			.user-mannual {
				margin-left: 10px;
				position: relative;
				.btn-link {
					line-height: 32px;
					height: auto;
				}
				.file-name {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.pdf-file-name {
					position: absolute;
					top: 30px;
					left: 0px;
					width: 100%;
					@include flex;
					@include align-items(center);
					.del-pdf-icon {
						cursor: pointer;
						margin-left: 5px;
						@include border-radius(100%);
						width: 20px;
						height: 20px;
						padding: 5px;
						svg {
							width: 10px;
							height: 10px;
						}
					}
				}
			}
		}
	}

	&.add-version-modal {
		.version-modal-inner {
			width: 25em;
			.drawer-form-fields .drawer-input-field {
				padding-left: 1.75em;
				padding-right: 1.75em;
			}
			.modal-content {
				min-height: 125px;
			}
			.modal-footer {
				padding: 0px 1.75em 20px;
				margin-top: 0px;
				box-shadow: none;
				@include justify-content(flex-end);
			}
		}
	}
}

.okr-update-list-area {
	padding: 20px 0 50px;
	.okr-update-list {
		border: 1.4px solid $color-border-tile;
		@include border-radius(0.375em);
		.okr-update-list-inner {
			position: relative;
			background-color: $color-white;
			@include border-radius(0.375em);
			transition: transform 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s;
			transition: transform 0.2s, filter 0.2s, box-shadow 0.4s, -webkit-filter 0.2s;
			.okr-update-head {
				position: relative;
				padding: 20px 40px;
				height: 90px;
				cursor: pointer;
				.rotating-arrow {
					cursor: pointer;
					position: absolute;
					width: 20px;
					height: 92.8px;
					left: -1.4px;
					top: -1.4px;
					background: rgba($color-secondary2, 0.5);
					opacity: 1;
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					@include transition(0.5s);
					svg {
						@include transition(0.5s);
					}
					&.expand {
						svg {
							@include transform(rotate(90deg));
						}
					}
				}
				ul {
					@include flex;
					padding: 0;
					li {
						padding: 0;
						&.okr-update-col1 {
							width: 68%;
							.feature-title {
								h4 {
									padding-bottom: 5px;
								}
								.MuiTypography-body1 {
									font-size: $font-size-h5;
									line-height: $line-height3;
								}
							}
						}
						&.okr-update-col2 {
							width: 15%;
							.feature-type-chip {
								padding: 0 0.7em;
								font-size: 10px;
								font-weight: $font-weight-bold;
								line-height: $line-height5;
								text-transform: uppercase;
								border-radius: 1.6em;
								vertical-align: top;
								&.feature-type-new {
									color: $color-white;
									background-color: $color-completed;
								}
								&.feature-type-fix {
									color: $color-white;
									background-color: $color-text-info;
								}
							}
						}
						&.okr-update-col3 {
							width: 17%;
							@include flex;
							@include justify-content(flex-end);
							a {
								@include flex;
								@include align-items(center);
								color: $color-primary1;
								font-size: $font-size-h5;
								font-weight: $font-weight-bold;
								line-height: $line-height3;
								margin-right: 8px;
								svg {
									margin-left: 10px;
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
							}
							button {
								opacity: 0;
								@include transition(0.25s);
								svg {
									width: 14px;
									height: 14px;
									path {
										fill: $color-primary1;
									}
								}
								&:hover {
									background-color: transparent;
								}
								.MuiTouchRipple-root {
									display: none;
								}
							}
						}
					}
				}
				&:hover {
					.rotating-arrow {
						opacity: 1;
					}
					ul {
						.okr-update-col3 {
							button {
								opacity: 1;
							}
						}
					}
				}
			}
			.okr-update-content {
				.feature-content {
					padding: 20px 40px;
				}
			}
			&:hover {
				@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
				z-index: 1;
			}
		}
	}
}
/** dropdown css start here**/
.select-dropdown {
	position: relative;
	display: inline-block;
	.list-dropdown {
		position: absolute;
		z-index: 99;
		top: 35px;
		left: 0;
		width: 170px;
		height: auto;
		max-height: 246px;
		overflow: auto;
		@include custom-scrollbar;
		background: $color-white;
		@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
		@include border-radius(8px);
		ul {
			padding: 0px;
			li {
				cursor: pointer;
				padding: 0.625em 15px;
				border-bottom: 1px solid $color-hover;
				display: block;
				.version-title {
					@include flex;
				}
				h5 {
					font-weight: normal;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				&:hover {
					background: $color-hover;
				}
				&.active {
					background: $color-secondary2;
					h5 {
						color: $color-primary1;
					}
				}
			}
		}
	}
	&.version-dropdown {
		margin-right: 10px;
		.MuiButton-root.btn-with-border {
			border: 0px;
			background: none;
			padding: 0px;
			color: $color-primary2;
			min-width: 100px;
			@include justify-content(left);
			.MuiTouchRipple-root {
				display: none;
			}
			svg {
				margin-left: 10px;
				path {
					fill: $color-primary2;
				}
			}
		}
		.list-dropdown {
			top: 28px;
		}
	}

	.feature-select-error {
		min-width: 152px;
	}
}

/**button border css start here**/
body {
	.MuiButton-root.btn-with-border {
		padding: 0.25em 1.125em;
		font-size: $font-size-h5;
		line-height: $line-height2;
		color: $color-primary1;
		@include border-radius(100px);
		border: 1.4px solid $color-border-tile;
		@include justify-content(space-between);
		&:hover {
			background: none;
		}
		&.open {
			.icon {
				svg {
					@include transform(rotate(180deg));
				}
			}
		}
		.icon {
			order: 2;
			svg {
				width: 14px;
				margin-left: 5px;
				height: 14px;
				path {
					fill: $color-primary1;
				}
			}
		}
		&.Mui-disabled {
			.icon {
				svg {
					path {
						fill: rgba($color-primary2, 0.5);
					}
				}
			}
		}
		&.error-btn {
			border: 1.4px solid $color-text-info;
			color: $color-text-info;
			.icon {
				svg {
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}

	.MuiButton-root.btn-primary-white {
		margin-left: 1em;
		min-width: 6.6875em;
		min-height: 40px;
		@include border-radius(100px);
		color: $color-primary2;
		background: $color-white;
		padding: 0.375em 1.09375em;
		@include transition(0.5s);
		@include box-shadow(2px 2px 10px rgba($color-primary2, 0.12));
		border: 1.4px solid $color-border-tile;
		&:hover {
			background: $color-expanded-tiles;
			border-color: $color-primary1;
		}
		&.Mui-disabled {
			background-color: $color-hover;
			color: $color-connected-line;
			box-shadow: none !important;
		}
	}
}
.loading-txt-center,
.version-not-found {
	text-align: center;
	padding: 40px 0;
	object,
	img {
		margin-top: 10px;
	}
}
/**editor design css start here**/
.ck {
	&.ck-toolbar {
		border-width: 1.4px !important;
		border-color: rgba($color-input-field, 0.5) !important;
		border-bottom: 0px !important;
		@include border-radius(6px 6px 0 0!important);
	}
	&.ck-editor__main {
		> .ck-content {
			border: 1.4px solid rgba($color-input-field, 0.5);
			max-height: 150px;
			min-height: 130px;
			@include custom-scrollbar;
			@include border-radius(0px 0px 6px 6px!important);

			ul,
			ol {
				margin-left: 40px;
			}
			i {
				font-style: italic;
			}
			.mention {
				background: none;
				color: $color-primary1;
			}
			a {
				color: $color-primary1;
			}
		}
		.ck.ck-content.ck-focused {
			border: 1.4px solid rgba($color-primary1, 1);
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
	}

	&.ck-button.ck-on {
		color: $color-primary1 !important;
		background: $color-secondary2 !important;
		box-shadow: none !important;
	}
	&.ck-balloon-panel {
		@include box-shadow(2px 2px 10px rgba($color-primary2, 0.2) !important);
		border-color: rgba($color-input-field, 0.4) !important;
		z-index: 9999 !important;
		.ck-button.ck-link-actions__preview {
			.ck-button__label {
				color: $color-primary1 !important;
			}
		}
	}
}
/**editor formatted text css start here**/
.formatted-text {
	color: $color-primary2;
	font-weight: normal;
	line-height: $line-height2;
	font-size: $font-size-h5;
	font-weight: $font-weight-regular;
	p {
		line-height: $line-height2;
		font-size: $font-size-h5;
	}
	h1 {
		color: $color-primary2;
	}
	a,
	.tagged-name {
		color: $color-primary1;
		cursor: pointer;
		text-decoration: underline;
	}
	strong {
		font-weight: $font-weight-bold;
	}
	em,
	i {
		font-style: italic;
	}
	ul,
	ol {
		margin: 0 0 0 1.07em;
		li {
			padding-left: 0;
			font-weight: $font-weight-regular;
		}
	}
	ul {
		list-style-type: circle;
	}
	ol {
		list-style-type: decimal;
	}

	blockquote {
		border-left: 4px solid rgba($color-primary2, 0.2);
		margin: 5px 0;
		padding: 5px;
	}
	table {
		width: 100%;
		font-family: $font-family-lato;
		border-collapse: collapse;
	}

	table td,
	table th,
	table {
		border: 1px solid $color-white;
		padding: 8px;
		border-collapse: collapse;
	}
	table tr {
		background-color: $color-hover;
	}
	table tr:nth-child(even) {
		background-color: rgba($color-hover, 0.5);
	}
	table th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		font-weight: $font-weight-bold;
	}
}
