@import '../../sass-utils/variables';
.edit-chart-wrapper {
	height: 100%;
	@include flex;
	@include justify-content(center);
	&.locate-me {
		.alignment-node-card {
			position: relative;
			&:before {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				content: '';
				background: rgba(205, 12, 255, 0.5);
				z-index: 9;
				border-radius: 3px;
				border: 1px solid rgb(205, 12, 255);
			}
		}
	}
	.oc-node {
		padding: 0;
		&.highlight-node {
			.org-node-card {
				box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.5);
				background: $color-white;
				border: 1px solid $color-keyword-highlighter;
			}
			.view-people-map-img {
				border: 1px solid $color-keyword-highlighter !important;
				box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.5) !important;
				border-radius: 50%;
			}
			.custom-node-card {
				border: 1px solid $color-keyword-highlighter !important;
				box-shadow: 0px 5px 15px rgba(174, 174, 174, 0.5) !important;
			}
		}
		&.highlight-node-default {
			.org-node-card {
				box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.5);
				background: $color-white;
				border: 1px solid $color-keyword-highlighter;
			}
			.view-people-map-img {
				border: 1px solid $color-keyword-highlighter !important;
				box-shadow: 0px 10px 20px rgba(174, 174, 174, 0.5) !important;
				border-radius: 50%;
			}
			.custom-node-card {
				border: 1px solid $color-keyword-highlighter !important;
				box-shadow: 0px 5px 15px rgba(174, 174, 174, 0.5) !important;
			}
		}
		.view-people-map-img {
			width: 4.375em;
			height: 4.375em;
			@include border-radius(100%);
			margin-right: 0;
			font-size: 1.75em;
		}
		.custom-node-card {
			border: 1px solid rgba(161, 171, 185, 0.3);
			box-shadow: 0px 4px 12px rgba(174, 174, 174, 0.2);
			.user-img {
				svg {
					path {
						stroke: $color-tags;
					}
				}
			}
		}
		.org-node-card {
			background: $color-white;
			border: 1px solid rgba($color-input-field, 0.3);
			@include border-radius(6px);
			box-shadow: 0px 4px 12px rgba(174, 174, 174, 0.2);
			width: 19.25em;
			.MuiCardHeader-root {
				padding: 0 0.5em;
				@include flex-direction(column);
				margin-top: -1.25em;
				.MuiCardHeader-avatar {
					margin: 0;
					width: 4.375em;
					height: 4.375em;
					border: 1px solid rgba(161, 171, 185, 0.3);
					border-radius: 100%;
					.MuiAvatar-root {
						width: 100%;
						height: 100%;
						font-size: 1.75em;
						position: inherit;
						.MuiAvatar-img {
							width: auto;
							height: auto;
						}
					}
					.no-org-image {
						width: 100%;
						height: 100%;
						@include flex;
						@include align-items(center);
						@include justify-content(center);
						@include border-radius(100%);
						background: $color-bg-info;
						svg {
							path {
								fill: $color-tags;
							}
						}
					}
				}
				.MuiCardHeader-content {
					margin: 0.5em 0 0.5em 0;
					.MuiTypography-h4 {
						color: $color-primary2;
					}
					.org-title {
						cursor: pointer;
						&:hover {
							color: $color-primary1;
							text-decoration: underline;
						}
					}
				}
			}
			.MuiCardContent-root {
				padding: 0;
				@include flex;
				@include flex-direction(column);
				@include align-items(center);
				.MuiTypography-h4 {
					min-height: 22px;
				}
				.map-user-avatar {
					@include flex;
					@include align-items(center);
					@include justify-content(center);
					width: 40px;
					height: 40px;
					overflow: hidden;
					position: inherit;
					background: $color-bg-info;
					margin-bottom: 3px;
					@include border-radius(50%);
					img {
						@include border-radius(50%);
					}
					.MuiTypography-body1 {
						color: $color-tags;
						font-size: $font-size-p-body1;
					}
				}
				.org-people-count {
					padding-left: 1.5em;
					background: url(../../../images/user-icon-2.svg) left center no-repeat;
					display: inline-block;
					margin-top: 0.6em;
					background-size: 18px;
					font-weight: $font-weight-bold;
				}
			}
			.card-head-action {
				height: 34px;
				@include flex;
				@include justify-content(flex-end);
				button {
					position: inherit;
					padding: 0.5em;
					.MuiTouchRipple-root {
						display: none;
					}
				}
			}
			.MuiCardActions-root {
				@include justify-content(flex-end);
				padding: 0;
				.map-delete-icon {
					height: 40px;
					cursor: pointer;
					display: inline-flex;
					@include align-items(center);
					@include border-radius(50%);
					span {
						display: inline-flex;
						@include align-items(center);
						padding: 0.7em;
						@include border-radius(50%);
						&:hover {
							background-color: rgba(0, 0, 0, 0.04);
						}
					}
				}
			}
			svg {
				path {
					stroke: none;
				}
			}
		}
	}
}

.rd3t-tree-container {
	&.rd3t-grabbable {
		padding: 10px 10px 0 10px;
		ul {
			li {
				.oc-node {
					margin-bottom: 9px;

					> div {
						box-shadow: none;
					}

					&:hover {
						background-color: transparent;
					}

					&.selected {
						background-color: transparent;
					}

					.oc-edge {
						&:before {
							width: 24px;
							height: 24px;
							content: '';
							border: none;
							left: 50%;
							transform: translateX(-50%);
							background-color: $color-white;
							background-position: center;
							background-repeat: no-repeat;
							background-image: url('../../../images/up-arrow.svg');
							box-shadow: 0px 4px 12px rgba(174, 174, 174, 0.2);
							border-radius: 50%;
							z-index: 2;
						}
					}

					.oci-chevron-down {
						&:before {
							background-image: url('../../../images/arrow-2.svg');
							bottom: 0;
						}
						&:after {
							width: 100%;
							height: 19px;
							content: '';
							background: url(../../../images/cards.svg) left bottom / 100% no-repeat;
							position: absolute;
							left: 0;
							top: 0;
						}
					}

					.oci-chevron-up {
						&:before {
							background-image: url('../../../images/up-arrow.svg');
						}
					}

					.oci-chevron-expand {
						&:before {
							background-image: url('../../../images/slide-icon-minus.svg');
							z-index: 12;
							bottom: 0;
						}

						&.topEdge {
							&:before {
								bottom: -12px;
							}
						}
					}

					.oci-chevron-left {
						&:before {
							background-image: url('../../../images/up-arrow.svg');
							bottom: -9px !important;
							z-index: 1;
						}
					}

					&:not(:only-child):after {
						bottom: -9px;
					}

					.rightEdge {
						right: 13px;
					}

					.leftEdge {
						left: 10px;
					}
				}
			}
		}
	}
}

.people-wrapper {
	.edit-chart-wrapper {
		.custom-node-card {
			.MuiCardHeader-content {
				min-height: 75px;
			}
		}
	}
	.current-user-parent {
		svg {
			path {
				stroke: $color-tags;
			}
		}
	}
}
.node-bottom-arrow {
	cursor: pointer;
	svg {
		path {
			fill: $color-primary2;
		}
		g {
			path {
				fill: $color-white;
			}
		}
	}
}

.organization-wrapper {
	.edit-chart-wrapper {
		.oc-node {
			.org-node-card {
				position: inherit;
				.MuiCardHeader-root {
					.MuiCardHeader-content {
						min-height: 3.15em;
						.MuiTypography-h3 {
							line-height: 24px;
						}
					}
					.MuiCardActions-root {
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}
				.MuiCardContent-root {
					.org-people-count {
						line-height: 18px;
					}
				}
			}
		}
	}
}
