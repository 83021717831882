.screenlock-message {
	display: none;
}
@media only screen and (max-width: 1023px) {
	.screenlock-message {
		position: fixed;
		background: $color-secondary2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 999999;
		@include flex;
		@include justify-content(center);
		padding: 5em;
		@include flex-direction(column);
		p {
			padding-bottom: 1em;
		}
		.screenlock-img {
			text-align: center;
			margin: 0 auto 2em;
			img {
				max-width: 250px;
			}
		}
	}
}
