/**default avatar css **/
body {
	.MuiAvatar-root {
		background: $color-bg-info;
		color: $color-tags;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		width: 30px;
		height: 30px;
		text-transform: uppercase;
		border: 1.4px solid $color-bg-teamstag;
		img {
			@include border-radius(100%);
		}
		&.avatar-default-grey {
			background: $color-hover;
			border: 1px solid $color-white;
			cursor: pointer;
			svg {
				max-width: 14px;
				max-height: 14px;
				opacity: 0.5;
			}
		}

		&.team-avatar {
			width: 36px;
			height: 30px;
			@include border-radius(168px);
			font-size: $font-size-h5;
			img {
				@include border-radius(168px);
			}
		}
		&.team-avatar-small {
			width: 31px;
			height: 24px;
			@include border-radius(168px);
			img {
				@include border-radius(168px);
			}
			&.all-team {
				background: $color-bg-teamstag;
				svg {
					max-width: 13px !important;
					max-height: 13px !important;
				}
			}
		}
		&.team-avatar-smaller {
			width: 24px;
			height: 20px;
			@include border-radius(100px);
			font-size: $font-size-subtitle2;
			line-height: $line-height3;

			img {
				@include border-radius(100px);
			}
		}
		&.profile-avatar-style {
			width: 100px;
			height: 100px;
			font-size: 2em;
		}
		&.user-setting-avatar {
			width: 136px;
			height: 136px;
			font-size: 2em;
			border: 6px solid $color-bg-teamstag;
		}
		&.avatar-type1 {
			width: 69px;
			height: 69px;
			font-size: $font-size-h2;
			img {
				@include border-radius(50%);
			}
		}
		&.avatar-type2 {
			width: 52px !important;
			height: 52px !important;
			font-size: $font-size-h2;
		}
		&.avatar-type3 {
			width: 40px;
			height: 40px;
			font-size: $font-size-h5;
		}
	}
	.MuiListItemAvatar-root {
		min-width: auto;
		margin-right: 0.625em;
		margin-top: 5px;
	}
	/****/
	.teams-select-all {
		.user-img {
			.MuiAvatar-root,
			img {
				@extend .team-avatar;
			}
		}
	}
	/**avtar group count max 1**/
	.avatar-count-group {
		@include flex;
		align-items: center;
		.MuiAvatar-root {
			&:nth-child(2) {
				background: $color-primary1;
				color: $color-white;
				width: auto;
				min-width: 18px;
				min-height: 18px;
				height: 18px;
				padding: 0 2px;
				font-size: $font-size-subtitle2;
				line-height: $line-height5;
				z-index: 99 !important;
				position: relative;
				@include border-radius(100px);
				margin-left: -4px;
				&.remove-blue {
					background: $color-hover;
					border: 1px solid $color-white;
					min-width: 30px;
					min-height: 30px;
					width: auto;
					height: 30px;
					margin-top: 0;
					color: rgba($color-primary2, 0.5);
				}
			}
			&.count-more-then-two {
				@include border-radius(168px);
				padding: 0 4px;
			}
		}
	}
}
