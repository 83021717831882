@import '../../sass-utils/variables';

//create new objective form css

.create-okr-card {
	margin-top: 2em;
	margin-bottom: 2em;
	position: relative;
	padding: 1.75em 1.25em 1em 3.4em;
	@include box-shadow(0px 13px 60px rgba($color-primary2, 0.2));
	@include border-radius(6px);
	background: $color-white;
	.okr-col1 {
		width: 54%;
	}
	.okr-col2 {
		width: 19%;
		&.contributors {
			@include animation(fade-in 1.1s);
			.contributors-avatar-group {
				.MuiSkeleton-root {
					background: $color-hover;
					border: 1.4px solid $color-bg-teamstag;
				}
			}
		}
	}
	.okr-col3 {
		width: 15%;
		.MuiInputLabel-root {
			display: none;
		}
		// .date-settings {
		// 	width: 60%;
		// 	@include animation(fade-in 1.1s);
		// 	input {
		// 		padding: 0;
		// 		width: 3.75em;
		// 	}
		// 	.MuiInput-underline:before,
		// 	.MuiInput-underline:after {
		// 		display: none;
		// 	}
		// 	button {
		// 		svg {
		// 			width: 1em;
		// 			height: 1em;
		// 			path {
		// 				fill: $color-primary1;
		// 			}
		// 		}
		// 		.MuiTouchRipple-root {
		// 			display: none;
		// 		}
		// 	}
		// }
	}
	.okr-col4 {
		width: 23%;
	}
	.obj-progress {
		min-height: auto;
	}
	button {
		padding: 0;
		min-width: auto;
		&.btn-primary,
		&.button-blue-border {
			padding: 0.375em 1.09375em;
		}
	}
	.cancel-btn {
		position: absolute;
		right: 0.5em;
		top: 0.5em;
		padding: 0.5em;
		@include border-radius(30px);
	}
	.MuiInputBase-root {
		padding: 0;
		border: 0;
		margin: 0;
	}
	.create-obj {
		min-height: 5em;
		margin-bottom: 0.5em;
		.create-obj-col {
			min-height: 4em;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
		}
		.add-objective {
			width: 85%;
			.MuiInputBase-root {
				textarea {
					padding: 0;
					font-weight: $font-weight-bold !important;
				}
				&.Mui-focused {
					border: none;
					box-shadow: none;
				}
			}
		}
	}
	.create-kr {
		margin-bottom: 0.5em;
		min-height: 6.5em;
		padding: 1em 0;
		background: $color-bg-tag;
		border-radius: 6px;
		@include transition-default;
		@include animation(slide-down 1s);

		&:hover {
			background-color: $color-hover;
		}
		.create-obj-col {
			min-height: 4em;
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			.okr-col1 {
				padding-left: 1.5em;
			}
			.okr-col4 {
				@include flex;
				@include justify-content(space-between);
				@include align-items(center);
				.obj-progress {
					max-width: calc(100% - 53px);
					.obj-progress-slider {
						.progress-timestamp-info {
							width: 43px;
							margin-right: 10px;
							@include flex;
							@include justify-content(flex-end);
						}
						.MuiTypography-h2 {
							color: $color-threshold;
							sup {
								font-size: $font-size-h6;
								vertical-align: super;
							}
						}
					}
				}
				.kr-progress {
					width: 100%;
					max-width: calc(100% - 53px);
					.kr-progress-slider {
						.progress-timestamp-info {
							width: 43px;
							margin-right: 10px;
							@include flex;
							@include justify-content(flex-end);
						}
						.MuiTypography-h2 {
							color: $color-threshold;
							sup {
								font-size: $font-size-h6;
								vertical-align: super;
							}
						}
					}
				}
			}
			.add-kr {
				width: 84%;
				.MuiInputBase-root {
					textarea {
						padding: 0;
						font-weight: $font-weight-bold !important;
					}
					&.Mui-focused {
						border: none;
						box-shadow: none;
					}
				}
			}
		}
		.okr-field-actions {
			padding-left: 1.5em;
		}
	}
	.okr-field-actions {
		margin-top: -0.5em;
		.MuiButton-text {
			font-size: $font-size-h5;
			color: $color-primary1;
			svg {
				width: 16px;
				height: 16px;
				margin-right: 5px;
			}
			// &.delete-btn {
			// 	color: $color-primary2;
			// 	svg {
			// 		path {
			// 			fill: $color-primary2;
			// 		}
			// 	}
			// }
		}
		.MuiButton-text.settings-btn-disable {
			color: $color-input-field;
			cursor: default;
			svg {
				path {
					fill: $color-input-field;
				}
			}
		}
		.MuiButton-text.settings-btn-error {
			color: $color-text-info;
			.icon {
				@include animation(rotation 2s linear infinite);
				//animation-iteration-count: infinite;
				margin-right: 5px;
				svg {
					margin-right: 0px;
					path {
						fill: $color-text-info;
					}
				}
			}
		}
	}
	.create-okr-actions {
		padding-top: 0.5em;
		visibility: visible;
		opacity: 1;
		@include align-items(center);
		p {
			font-style: italic;
			font-size: $font-size-h5;
			text-transform: lowercase;
			color: $color-input-field;
		}
		&.hidden-actions {
			button,
			p {
				visibility: hidden;
				opacity: 0;
				transition: visibility 0.3s linear, opacity 0.3s linear;
			}
		}
		.draft-btn {
			svg {
				width: 1em;
				height: 1em;
				margin-right: 0.625em;
				path {
					fill: $color-primary1;
				}
			}
		}
		.btn-primary {
			padding: 0.625em 1.25em;
		}
		.create-add-another {
			height: 40px;
			line-height: 40px;
			svg {
				width: 0.6875em;
				margin-right: 0.625em;
				path {
					fill: $color-primary1;
					stroke: $color-primary1;
				}
			}
			&.Mui-disabled {
				svg {
					path {
						fill: rgba(0, 0, 0, 0.26);
						stroke: rgba(0, 0, 0, 0.26);
					}
				}
			}
		}
	}
}
