@import '../../../styles/sass-utils/variables';

.chart-contributors-list {
	.okr-progress {
		@include flex;
		@include flex-direction(column);
		margin-top: 7px;
		margin-bottom: 10px;

		.MuiTypography-root.MuiTypography-body1 {
			color: $color-primary2;
		}
	}

	.objective-name {
		padding-bottom: 0.5em;
		font-size: 13px;
		font-weight: 300;
	}

	.contributor-pagination-wrapper {
		width: 100%;
		@include flex;
		@include justify-content(space-around);
		user-select: none;

		.contributor-pagination-arrow-wrapper {
			width: 5px;

			.contributor-pagination-arrow {
				cursor: pointer;
			}
		}

		.MuiTypography-subtitle2 {
			font-size: 0.85rem;
			font-weight: 300;
		}
	}
}
