@import '../../sass-utils/variables';
.teams-chips {
	margin-top: 0.25em;
	@include flex;
	.MuiChip-root {
		height: 20px;
		border-radius: 3px;
		margin-right: 0.6em;
		padding-right: 0.3em;
		max-width: 130px;
	}
	.MuiChip-deletable {
		height: 20px;
		border-radius: 3px;
		margin-right: 0.6em;
		padding-right: 0.3em;
		max-width: 130px;
		.MuiChip-label {
			padding-left: 7px;
			padding-right: 3px;
		}
	}
}
