@import '../../../styles/sass-utils/variables';
/**manage user permission drawer**/
.mng-perm-top {
	padding: 0 3.75em;
	.user-info {
		margin-bottom: 1.875em;
		.user-img {
			margin-right: 1.25em;
			margin-top: 0;
			.MuiAvatar-root {
				margin-top: 0;
			}
		}
	}

	.role-description {
		p {
			font-size: $font-size-base;
			line-height: $line-height2;
			color: $color-primary2;
			font-weight: $font-weight-regular;
		}
	}
}
.user-role-detail {
	max-width: 82%;
	margin-bottom: 1.875em;
}
/**user role dropdown without bg**/
.user-role-dropdown.user-role-dropdown-nobg {
	margin-bottom: 0.625em;
	.user-role__control {
		min-height: auto;
		background: none;
		min-height: auto;
		border: 0px !important;
		padding: 0px;
		@include inline-flex;
		@include align-items(center);
		box-shadow: none !important;
		.role-info {
			@include flex;
			background: $color-bg-tag;
			min-height: 16px;
			min-width: 3.0625em;
			@include border-radius(10px);
			padding: 0px 7px;
			height: auto;
			font-size: $font-size-subtitle2;
			font-weight: $font-weight-bold;
			line-height: $font-size-base;
			.MuiTypography-h4 {
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				line-height: $font-size-h4;
				display: inline-block;
				max-width: 300px;
				text-overflow: ellipsis;
				overflow: hidden;
				text-transform: uppercase;
			}
			sup {
				position: absolute;
				right: 7px;
				top: 3px;
				line-height: 6px;
			}
			&.role-info.chip-star {
				padding-right: 14px;
			}
		}
		&.user-role__control--menu-is-open {
			z-index: initial;
			.user-role__dropdown-indicator {
				transform: none;
			}
		}
		.user-role__indicators {
			.user-role__dropdown-indicator {
				position: static;
				margin-right: 0px;
				margin-left: 1.25em;
			}
		}
		.user-role__value-container {
			input {
				position: absolute;
			}
		}
		.user-role__placeholder {
			margin: 0px;
			font-size: $font-size-h5;
		}
	}
	.user-role__menu {
		padding: 0;
		border: 1.4px solid $color-primary1;
		margin-left: -15px;
		z-index: 99;
		.user-role__option {
			&:first-child {
				&::after {
					content: '';
					background: url(../../../images/down-arrow.svg) no-repeat center center;
					width: 13px;
					height: 13px;
					@include transition(0.3s);
					position: absolute;
					right: 10px;
				}
			}
			sup {
				display: none;
			}
		}
	}
}
