@import '../../sass-utils/variables';
.not-found-area {
	background: url('../../../images/not-found-img.svg') center center no-repeat;
	height: 100vh;
}
.internal-server-error-area {
	background: url('../../../images/internal-server-error.svg') center center no-repeat;
	height: 100vh;
}
.inner-area-wrapper {
	height: 100%;
	@include align-items(center);
	@include flex;
	@include justify-content(center);
	.inner {
		text-align: center;
		h1 {
			//color: $color-primary1;
			font-size: 36px;
			font-weight: $font-weight-black;
			margin-bottom: 0.5em;
		}
		p {
			color: $color-primary2;
			max-width: 22.125em;
			margin: 0 auto;
			margin-bottom: 1.625em;
			a {
				color: $color-primary1;
				//text-decoration: underline;
			}
		}
		.btn-group {
			.btn-primary {
				box-shadow: 6px 9px 26px rgba(57, 163, 250, 0.5);
				margin-right: 2em;
				padding: 0 2em;
			}
			a {
				color: $color-primary1;
				//text-decoration: underline;
			}
		}
	}
}

.coming-soon-area {
	background: url('../../../images/coming-soon.svg') center center no-repeat;

	height: 100vh;

	.inner-wrapper-area {
		@include flex;

		@include align-items(center);

		height: 100vh;

		padding: 0 5em;

		.inner-info {
			max-width: 24.063em;

			img {
				margin-bottom: 2.063em;
			}

			h1 {
				font-size: $font-size-h1;

				color: $color-primary1;

				font-weight: 900;

				line-height: 42px;
			}
		}
	}
}
/**error main warapper css**/
.forbidden-wrapper {
	.main-wrapper {
		padding-top: 4.6em;
		max-width: 100%;
	}
}
