@import '../sass-utils/variables';

body {
	label + .MuiInput-formControl {
		margin: 0;
	}
	.label-disabled {
		opacity: 0.8;
		svg {
			opacity: 0.6;
		}
	}
	.label-active {
		.MuiFormLabel-root {
			color: $color-primary1;
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
	}

	.MuiFormLabel-root {
		padding-bottom: 0.625em;
		position: static;
		transform: unset;
		font-size: $font-size-h5;
		line-height: $line-height4;
		color: $color-primary2;
		@include flex;
		@include align-items(center);
		&.Mui-focused {
			color: $color-primary2;
		}
		&.Mui-error {
			color: $color-text-info;
			svg {
				path {
					fill: $color-text-info;
				}
			}
		}
		.asterisk {
			margin-left: 2px;
		}
		&.label-des {
			padding-bottom: 3px;
		}
	}
	.MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
		padding: 0.69em 1em;
	}
	.MuiInputBase-root,
	.MuiInputBase-input,
	.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
		padding: 0;
		font-weight: $font-weight-regular;
		font-size: $font-size-h5;
		color: $color-primary2;
		line-height: $line-height4;
		height: auto;
		@include border-radius(6px);
		@include transition(0.25s);
		&.Mui-focused {
			border: 1.4px solid $color-primary1;
			@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		}
		&.Mui-error {
			border: 1.4px solid $color-text-info;
		}
	}
	.MuiInput-inputMultiline {
		@include custom-scrollbar;
	}
	.MuiInputBase-root {
		padding: 0.69em 1em;
		border: 1.4px solid rgba($color-input-field, 0.5);
		&:before,
		&:after {
			display: none;
		}
		.MuiInput-input {
			@include border-radius(0);
		}
		&.MuiInputBase-adornedStart {
			padding-left: 10px;
			.MuiInputAdornment-positionStart {
				margin-right: 8px;
				svg {
					width: 14px;
					height: 14px;
				}
			}
		}
		&.MuiInputBase-adornedEnd {
			padding-right: 12px;
			.MuiInputAdornment-positionEnd {
				button {
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
		}
	}
	.MuiSwitch-root {
		width: 24px;
		height: 14px;
		@include flex;
		padding: 0;
		.MuiSwitch-track {
			opacity: 1;
			background: $color-primary2;
		}
		.MuiSwitch-switchBase {
			padding: 2px;
			&.Mui-checked {
				transform: translateX(10px);
			}
			&.Mui-checked + .MuiSwitch-track {
				background: $color-completed;
			}
			.MuiSwitch-thumb {
				width: 10px;
				height: 10px;
				background: $color-white;
			}
		}
	}
	.MuiFormHelperText-root,
	.search-error,
	.error-field {
		position: absolute;
		left: calc(100% + 10px);
		top: 2.7em;
		width: 186px;
		padding: 0.417em 1em;
		margin: 0;
		color: $color-text-info;
		font-weight: $font-weight-bold;
		background: $color-bg-info;
		border: 1px solid $color-border-info;
		@include border-radius(6px);
		@include box-shadow(0px 10px 25px rgba($color-primary2, 0.1));
		font-size: $font-size-h6;
		@include flex;
		&:before {
			content: '';
			z-index: 1;
			position: absolute;
			right: 100%;
			top: 0.8em;
			width: 0;
			height: 0;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-right: 10px solid $color-bg-info;
		}
		&:after {
			content: '';
			position: absolute;
			right: 100%;
			top: 0.7em;
			width: 0;
			height: 0;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 12px solid $color-border-info;
		}
		svg {
			width: 14px;
			min-width: 14px;
			height: 14px;
			margin-right: 0.5em;
			vertical-align: middle;
			margin-top: 3px;
			path {
				fill: $color-text-info;
			}
		}
	}
	.helperText-bottom {
		position: relative;
		.MuiFormHelperText-root,
		.error-field {
			left: 0;
			top: auto;
			bottom: -30px;
			padding: 0.417em 1em !important;
			width: auto;
			@include flex;
			//@include align-items(center);

			&::before,
			&::after {
				right: auto;
				top: auto;
				left: 50%;
				bottom: 96%;
				margin-left: -5px;
				@include transform(rotate(90deg));
			}
			&::before {
				border-right: 8px solid $color-bg-info;
			}
			&::after {
				border-right: 8px solid $color-border-info;
			}
			svg {
				margin-bottom: 0px !important;
			}
		}
		.MuiFormLabel-root.Mui-error,
		&.select-error .MuiFormLabel-root {
			color: $color-text-info;
			svg {
				path {
					fill: $color-text-info;
					stroke: none;
				}
			}
		}
		&.select-error {
			.select-search-dropdown {
				.react-select__control {
					border-color: $color-text-info;
				}
			}
			.select-search-icon {
				+ .MuiFormHelperText-root {
					bottom: -5px !important;
					z-index: initial;
				}
			}
			.user-role-dropdown {
				.user-role__control,
				.user-role__menu {
					border-color: $color-text-info;
				}
			}
			.select-search-dropdown .react-select__menu {
				border-color: $color-text-info;
			}
		}
	}
	.field-label-twocol {
		label {
			@include flex;
			@include justify-content(space-between);
			> div {
				@include flex;
				@include align-items(center);
			}
		}
	}
	.MuiCheckbox-root {
		svg {
			width: 1em;
			height: 1em;
			path {
				fill: rgba($color-primary2, 0.5);
			}
		}
		&.Mui-checked {
			color: $color-expanded-tiles !important;
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
		&:hover {
			background-color: $color-expanded-tiles !important;
		}
		&.Mui-disabled {
			opacity: 0.8;
			svg {
				opacity: 0.6;
			}
		}
	}
	.MuiRadio-root {
		svg {
			width: 20px;
			height: 20px;
			path {
				fill: $color-primary2;
			}
		}
		&.Mui-checked {
			svg {
				path {
					fill: $color-primary1;
				}
			}
		}
	}
	.MuiRadio-colorSecondary {
		&.Mui-checked {
			color: $color-primary1;
			&:hover {
				background-color: $color-expanded-tiles;
			}
		}
		&:hover {
			background-color: $color-expanded-tiles;
		}
	}
	.MuiFormHelperText-root {
		color: $color-text-info;
	}
}

.roles-listing {
	.MuiCheckbox-root {
		svg {
			path {
				fill: $color-primary1;
			}
		}
		&.MuiCheckbox-indeterminate,
		&.not-checked {
			svg {
				path {
					fill: $color-white;
				}
			}
		}
	}
}

.drawer-form-fields {
	.drawer-form-fields-group {
		padding-top: 1.875em;
		border-top: 1.4px solid rgba($color-primary2, 0.2);
		.field-head {
			padding: 0 3.75em 1.875em;
		}
		&:first-child {
			border: 0px;
			padding-top: 0px;
		}
	}
	.drawer-input-field {
		position: relative;
		padding: 0 3.75em 1.875em;
		&.drawer-input-field-col2 {
			@include flex;
			@include justify-content(space-between);

			.helperText-bottom {
				.MuiFormHelperText-root {
					bottom: -26px !important;
					z-index: 9;
				}
				.Mui-disabled {
					input {
						opacity: 0.8;
					}
					svg {
						opacity: 0.6;
					}
				}
			}
			.edit-icon-in-label {
				cursor: pointer;
				position: relative;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-primary1;
					}
				}
				button {
					@include border-radius(50%);
					width: 24px;
					height: 24px;
					position: absolute;
					right: 0;
					top: -12px;
					svg {
						opacity: 1 !important;
					}
				}
			}
		}
		&.drawer-field-col1-bigsize {
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			padding-bottom: 0;
			.field-col1 {
				width: calc(60% - 20px);
			}
			.field-col2 {
				width: 40%;
			}
			.width-100 {
				width: 100%;
			}
			.helperText-bottom {
				.MuiFormHelperText-root {
					bottom: -52px !important;
					//max-width: 300px;
					svg {
						margin-top: 4px !important;
					}
				}
			}
		}
		.field-col1 {
			width: calc(50% - 15px);
		}
		.field-col2 {
			width: calc(50% - 10px);
		}
		.team-field-col2 {
			min-width: calc(50% - 10px);
		}
		.desc-btn-link {
			position: absolute;
			top: -3em;
			z-index: 1;
			svg {
				width: 0.75em;
				height: 0.75em;
				margin-right: 0.325em;
				path {
					fill: $color-primary1;
				}
			}
		}
		.desc-wrap-field {
			margin-top: 0.625em;
		}
		.role-status-field {
			position: relative;
			width: 104px;
		}
		&.drawer-input-blug-bg {
			background: $color-bg-form;
			padding: 1.875em 3.75em;
		}
		.Mui-disabled {
			opacity: 0.8 !important;
			color: rgba($color-primary2, 0.5);
			svg {
				opacity: 0.6 !important;
			}
		}
		.disableStatus {
			.Mui-disabled {
				opacity: 1 !important;
			}
		}
	}
	.drawer-blue-bg-full {
		background: $color-bg-form;
		padding: 0 3.75em;
		//padding-top: 2.5em;
		margin-bottom: 1.875em;
		.drawer-input-field {
			padding-left: 0;
			padding-right: 0;
			.MuiInputBase-root {
				background: $color-white;
			}
		}
		.drawer-form-fields-group {
			border-color: $color-expanded-tiles;
			padding-top: 1.875em;
		}
	}
	.MuiFormLabel-root,
	.MuiGrid-container {
		svg {
			width: 14px;
			height: 14px;
			margin-right: 5px;
			vertical-align: middle;
		}
	}
	.note-text {
		padding: 2em 3.75em;
		.MuiTypography-subtitle1 {
			font-weight: $font-weight-bold;
			font-style: italic;
			strong {
				font-weight: $font-weight-bold;
			}
		}
	}
	#rteSection {
		height: auto;
	}
	.form-error-text {
		color: $color-text-info;
	}
}

/**autocomplete popper css**/
.autocomplete-popper {
	.MuiAutocomplete-paper {
		@include default-shadow;
		@include border-radius(6px);
		ul {
			padding: 0px;
			max-height: 335px;
			@include custom-scrollbar;
			li {
				padding: 0.875em 1.25em;
				font-size: $font-size-base;
				line-height: $line-height2;
				color: $color-primary2;
				border-bottom: 1.4px solid $color-hover;
				&:last-child {
					border-bottom: 0px;
				}

				&.MuiAutocomplete-option[aria-selected='true'] {
					background: $color-secondary2 !important;
				}

				&.MuiAutocomplete-option[data-focus='true'] {
					background: $color-hover;
				}
			}
		}
	}
}

/**default dropdown css start here**/
//new css for assign user dropdown by react select
.dropdown-default-wrapper {
	.dropdown-default__control {
		background: $color-white;
		padding: 0.54em 3.75em 0.54em 1.3125em;
		min-height: 40px;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		cursor: pointer;
		&:hover {
			border: 1.4px solid rgba($color-input-field, 0.5);
		}
		&.dropdown-default__control--is-focused {
			border: 1.4px solid $color-primary1;
			box-shadow: 0px 0px 5px rgba($color-primary1, 0.5);
		}
		&.dropdown-default__control--menu-is-open {
			z-index: 9;
			@include border-radius(6px 6px 0 0);
			border-bottom: 0px;
			box-shadow: none;
			.dropdown-default__dropdown-indicator {
				@include transform(rotate(180deg));
			}
		}
		.dropdown-default__placeholder {
			color: rgba($color-primary2, 0.5);
			font-weight: normal;
		}
		.dropdown-default__indicators {
			.dropdown-default__indicator-separator,
			.dropdown-default__clear-indicator {
				display: none;
			}
			.dropdown-default__dropdown-indicator {
				background: url(../../images/down-arrow.svg) no-repeat center center;
				width: 13px;
				height: 13px;
				margin-right: 0.375em;
				@include transition(0.3s);
				position: absolute;
				right: 4px;
				svg {
					display: none;
				}
			}
		}
		.dropdown-default__value-container {
			padding: 0px;
			.dropdown-default__single-value {
				margin: 0px;
			}
			.role-info {
				h4 {
					font-size: $font-size-h5;
					line-height: $line-height4;
					font-weight: $font-weight-regular;
				}
			}
		}
		.MuiTypography-body2 {
			color: $color-secondary1;
			font-weight: $font-weight-bold;
			white-space: normal;
		}
	}

	.dropdown-default__menu {
		@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));
		@include border-radius(6px);
		border: 1.4px solid $color-primary1;
		border-top: 0px;
		background: $color-white;
		width: 356px;
		margin: 0 0 0 0;
		padding-top: 0;
		overflow: hidden;
		top: 0px;
		padding-top: 52px;
		> div {
			@include custom-scrollbar;
		}
		.dropdown-default__menu-list {
			padding: 0;
			margin: 0;
			max-height: 200px;
			border-top: 1.4px solid $color-hover;
			.dropdown-default__option {
				border-bottom: 1.4px solid $color-hover;
				padding: 0.8125em 4.0625em 0.8125em 1.25em;
				@include flex;
				@include align-items(center);
				min-height: 50px;
				cursor: pointer;
				&:last-child {
					border-bottom: 0px;
				}
				.MuiTypography-body2 {
					color: $color-secondary1;
					font-weight: $font-weight-bold;
					white-space: normal;
				}
				&:hover {
					background: $color-hover !important;
				}
				&.dropdown-default__option--is-selected {
					background: $color-secondary2 !important;
					color: $color-primary1;
					&:hover {
						background: $color-secondary2 !important;
					}
					.MuiTypography-h4 {
						color: $color-primary1;
					}
				}
				&.dropdown-default__option--is-focused {
					background: none;
				}
			}
		}
	}
	&.dropdown-default--is-disabled {
		opacity: 0.8;
		.dropdown-default__single-value--is-disabled {
			h4 {
				opacity: 0.4;
			}
		}
		.dropdown-default__dropdown-indicator {
			opacity: 0.6;
		}
	}
}

/**datepicker css start here**/
.datepicker-text-field {
	.MuiInputAdornment-positionEnd {
		button {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 40px;
			@include align-items(center);
			padding: 0;
			.MuiIconButton-label {
				@include align-items(center);
				@include justify-content(flex-end);
				height: 100%;
				svg {
					margin-right: 11px;
				}
			}
			.MuiTouchRipple-root {
				display: none;
			}
			&:hover {
				background: none;
			}
		}
	}
}
.datepicker-popover {
	border-radius: 6px !important;
	box-shadow: 0px 0px 30px rgba($color-primary2, 0.1) !important;
	width: 356px !important;
	max-height: 268px !important;
	//margin-left: 5px;
	@include custom-scrollbar;
	.MuiPickersYearSelection-container {
		@include flex;
		flex-wrap: wrap;
		align-content: stretch;
		height: auto !important;
		padding: 10px;
		.MuiPickersYear-root {
			flex: 1 0 25%;
			cursor: pointer;
			height: 50px;
			@include flex;
			@include align-items(center);
			@include justify-content(center);
			margin: 0px !important;
		}
	}
	.MuiTypography-subtitle1 {
		color: $color-primary2 !important;
		font-weight: normal !important;
		margin: 0px !important;
	}
	.MuiPickersMonth-monthSelected {
		color: $color-primary2 !important;
		background: $color-text-selection !important;
	}
	.MuiPickersYear-yearSelected {
		color: $color-primary2 !important;
		background: $color-text-selection !important;
	}
	.MuiPickersMonthSelection-container {
		width: 100%;
		padding: 0 10px;
		.MuiPickersMonth-root {
			height: 60px !important;
		}
	}
	.MuiPickersBasePicker-pickerView {
		min-height: 268px;
		width: 100%;
		max-width: 100%;
	}
}

/**grace perid**/
#gracePeriod-label {
	svg {
		path {
			stroke: $color-primary2;
			stroke-width: 0.4px;
		}
	}
}
.days-short-text {
	font-size: $font-size-h6;
	color: rgba($color-primary2, 0.5);
	font-weight: $font-weight-regular;
	line-height: 12px;
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle;
	margin-top: 4px;
}
