@import '../../sass-utils/variables';
@mixin errorbox {
	&::before {
		top: -0.85em;
		left: 0.8em;
		border-right: 5px solid transparent;
		border-left: 5px solid transparent;
		border-bottom: 10px solid #ffeded;
		border-top: none;
	}
	&::after {
		top: -1em;
		left: 0.65em;
		border-right: 6px solid transparent;
		border-left: 7px solid transparent;
		border-bottom: 12px solid #f3c3c3;
		border-top: none;
	}
}
.sign-in-area {
	min-height: 100vh;
	height: 100%;
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	@include flex-direction(column);
	background-position: top 80px right, bottom right, bottom left;
	background-repeat: no-repeat;
	.login-screen {
		@include flex;
		height: auto;
		width: 100%;
		background: url('../../../images/Default_Background.png') no-repeat center center;
		flex-wrap: wrap;
		position: relative;
		background-size: cover;
		.inner {
			flex: 0 0 50%;
			&.login-screen,
			&.login-form-area {
				background: $color-white;
			}			
				.login-inner-wrapper {
					max-width: 36em;
					width: 100%;
					padding: 20px 60px;
					.logo {
						margin-bottom: 2.5em;
						width: 40%;
					}
					.welcomeText {						
						font-weight: 400;
						font-size: $font-size-h1;
						margin-bottom: 5px;
						span {
							font-weight: 700;
							color: $color-primary1;
						}
					}
					.welcomeSubText {
						color: lighten($color: #000000, $amount: 50%);
						font-size: $font-size-h5;
						font-weight: 400;
					}
					.hi-useremail {
						font-size: 14px;
						padding-bottom: 1em;
						span {
							color: #000000;
							font-size: 14px;
							text-decoration: none;
						}
					}
					.Mui-disabled {
						box-shadow: none;
					}
					.login-inner-form {
						padding-top: 1.5em;
						.makeStyles-margin-1 {
							margin: 0;
						}
						.MuiFormControl-root {
							margin-bottom: 1em;						
							.MuiFormHelperText-root {
								font-size: $font-size-h6;
								margin-left: 1em;
								margin-top: 0.5em;
								max-width: 100%;
							}
						}

						.MuiInput-root {
							button {
								padding: 0;
								&:hover {
									background: none;
								}
							}

							&.error {
								border-bottom: 1px solid #ff0000;

								&::after {
									border-bottom: 0;
								}
							}
							svg {
								path {
									fill: lighten($color: #000000, $amount: 70%);
								}
							}
							border-width: 0 0 1px 0;
							border-radius: 0;
							&.Mui-focused {
								box-shadow: none;
							}
						}
						
						.rememberMe_box {
							margin: 0.1em 0 1.125em 0;
							display: block;
							span {
								padding: 0.05em;
								&.MuiFormControlLabel-label {
									font-size: $font-size-h6;
									font-weight: 400;
									color: lighten($color: #000000, $amount: 50%);
								}
							}
							.MuiCheckbox-root {
								.MuiSvgIcon-root {
									width: 0.7em;
									height: 0.7em;
								}
							}
						}
						
						.login-btn {
							min-width: 9.5em;							
							margin: 0.5em 0;
						}
						.forgotRow {
							display: block;
							margin-top: 1em;
							.forgotLink {								
								font-size: $font-size-h6;
								font-weight: 400;
								color: $color-primary1;
								&:hover {
									text-decoration: none;
								}
							}
						}
						&.domain-form {
							.pass-txtbox {
								margin-bottom: 0;
							}
							.MuiInput-root {								
								padding-left: 1.2em;
							}
							p {
								font-size: $font-size-h6;
								font-weight: 400;
								padding-left: 0.7em;
								margin-bottom: 0em;
							}
							a {
								color: $color-primary1;
								text-decoration: underline;
							}
						}
						&.recover-form {
							button {
								// box-shadow: 6px 10px 30px rgba(57, 163, 250, 0.4);
								margin-bottom: 1.875em;
								margin-top: 1.5em;
							}
						}
					}
					.forget-pass {
						padding: 0.5em;
						@include justify-content(flex-end);
						@include flex;
						a {
							font-size: $font-size-h6;
							font-weight: $font-weight-bold;
							color: $color-primary1;
							line-height: $line-height5;
						}
					}

					.signup-content {
						p {
							margin-bottom: 0.6em;
						}
						.social-btn {
							margin-bottom: 7.125em;
							@include flex;
							button {
								background-color: transparent;
							}
							.google-btn {
								margin-right: 1.3em;
							}
						}
					}
					.footer-content {
						position: absolute;
						bottom: 0;
						a {
							color: rgba($color-primary2, 0.5);
						}
						p {
							font-weight: 400;
						}
					}

					&.custom-domain-content {
						p {
							margin-bottom: 1em;
							line-height: 1.313em;
						}
					}

					&.custom-login,
					&.recovery-password {
						p {
							margin-bottom: 1em;
						}
					}
					.reset-link {
						p {
							font-size: 12px;
						}						
						a {
							color: $color-primary1;
							text-decoration: underline;
							transition: 0.3s ease-in-out;
							&:hover {
								text-decoration: none;
							}
						}
					}

					&.reset-inner-form {
						p {
							margin-bottom: 1em;
						}
						.reset-form {							
							.login-btn {								
								margin-top: 2em;
							}
							.pass-error {
								color: $color-primary2;
								font-style: italic;
								left: 0 !important;
								top: 4em;
								z-index: 99;								
								@include errorbox;
							}
						}
					}
				}
			
			.img-inner-wrapper {
				@include flex;
				height: 100vh;
				@include justify-content(center);
				@include align-items(center);
				@include flex-direction(column);

				&.login-screen .inner .img-inner-wrapper .client-logo {
					max-width: 180px;
					margin-bottom: 2em;
				}
				&.create-pass {
					margin-top: 8em;
				}

				.cat-walk-img {
					margin-top: -5em;
				}
			}
		}

		// Alert Box
		.alert-box {
			position: absolute;
			width: 100%;
			max-width: 32em;
			left: 1.75em;
			bottom: 1.8em;
			.inner-box {
				padding: 1em 2.25em;
				box-shadow: 0px 25px 20px rgba(171, 171, 171, 0.2);
				@include border-radius(6px);
				@include flex;
				.alertIcon {
					margin-right: 1.8em;
				}
				p {
					line-height: 1.313em;
				}
				.delete-icon {
					padding: 0;
					min-width: auto;
					position: absolute;
					right: 0.625em;
					top: 0.625em;

					&:hover {
						background-color: transparent;
					}
				}

				&.danger {
					background-color: #ffe5df;
					P {
						color: #f56160;
					}
				}
				&.success {
					background-color: #ddffeb;
					p {
						color: #3dd37a;
					}
				}
			}
			&.recover-msg {
				max-width: 33em;
				.inner-box {
					.alertIcon {
						margin-right: 1.3em;
					}
					p {
						max-width: 19em;
					}
				}
			}
			&.resetPass-msg {
				.inner-box {
					margin-bottom: 1.25em;
				}
				p {
					max-width: 20em;
				}
			}
		}
	}
	.MuiInput-underline:before {
		display: none;
	}

	.MuiInput-underline:after {
		border-bottom: 2px solid $color-primary1;
	}
	input {
		padding: 0.75em 0;
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		font-weight: $font-weight-regular;
	}
	.MuiFormHelperText-root {
		&.Mui-error {
			position: absolute;
			left: 0 !important;
			top: 4em;
			z-index: 99;
			color: $color-text-info;
			font-weight: 500;
			@include errorbox;
		}
	}
}

// .login-inner-info {
//     @include flex;
//     @include justify-content(space-between);
// }

@media screen and (min-width: 1600px) {
	.login-screen {
		.left-login-panel {
			@include flex;
			@include align-items(center);
		}

		.img-inner-wrapper {
			img {
				width: 30em;
			}
		}
	}
}

.login-inner-info {
	@include flex;
	@include justify-content(space-between);
}

.remember-me {
	margin-top: 1em;
	span {
		font-size: $font-size-h6 !important;
		color: $color-primary2 !important;
	}
}

@media screen and (min-width: 1600px) {
	.login-screen {
		.img-inner-wrapper {
			img {
				width: 30em;
			}
		}
	}
}
