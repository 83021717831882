// @import '../sass-utils/variables';
.kr-weekly-trends-area {
	padding: 2em 3.75em 5em;
	background: $color-bg-form;
	.weekly-trends-chart-area {
		padding: 1.5em 1.25em 0 0.25em;
		background: $color-white;
		border: 1.4px solid $color-secondary2;
		@include border-radius(6px);
		.weekly-trends-chart-top {
			margin-bottom: 30px;
			@include flex;
			@include justify-content(space-between);
			.weekly-trends-zoom-filter {
				@include flex;
				@include align-items(center);
				button {
					margin: 0 5px;
					padding: 1px 6px;
					font-size: $font-size-subtitle2;
					font-weight: $font-weight-bold;
					color: $color-secondary1;
					line-height: 1.5;
					border: 1.4px solid $color-secondary1;
					@include border-radius(20px);
					&.active {
						color: $color-primary1;
						border-color: $color-primary1;
						background: $color-secondary2;
					}
				}
			}
		}
		.weekly-trends-chart-bottom {
			position: relative;
			height: 400px;
			padding-right: 40px;
			.confidence-chart-emoticons {
				position: absolute;
				right: 0;
				top: 0;
				width: 25px;
				height: 322px;
				@include flex;
				@include justify-content(space-between);
				@include flex-direction(column);
				background: linear-gradient(
					180deg,
					rgba(167, 226, 157, 0.2) 21.18%,
					rgba(255, 217, 59, 0.2) 48.97%,
					rgba(246, 129, 129, 0.2) 77.13%,
					rgba(216, 209, 209, 0.2) 92.35%
				);
				@include border-radius(25px);
				svg {
					width: 25px;
					height: 25px;
				}
			}
		}
	}
	.wt-chart-info {
		padding-bottom: 7px;
		@include flex;
		@include justify-content(space-between);
		.MuiTypography-subtitle1 {
			font-style: italic;
		}
	}
}

/**trend contributor css start here**/
.trend-contributors {
	margin-bottom: 1.5em;
	.trend-contributors-group {
		position: relative;
		display: inline-flex;
		.contributors-owner {
			margin-right: 0.5em;
			width: 40px;
			height: 40px;
			font-size: $font-size-h5;
			line-height: $line-height3;
		}
		.contributors-group-list {
			@include flex;
			@include align-items(center);

			margin: 0;
		}
		.MuiAvatarGroup-avatar {
			@include box-shadow(-1px 0px 2px rgba($color-primary2, 0.15));

			&:first-child {
				z-index: 0 !important;
			}
			&:nth-child(2) {
				z-index: 1 !important;
			}
			&.MuiAvatar-colorDefault:nth-child(3) {
				width: 18px;
				height: 18px;
				background-color: $color-primary1;
				color: $color-white;
				z-index: 2 !important;
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				border: none;
			}
			&.MuiAvatar-colorDefault.removeBlue:nth-child(3) {
				background: $color-bg-info;
				color: $color-tags;
				font-size: $font-size-h6;
				width: 30px;
				height: 30px;
				border: 1.4px solid $color-bg-teamstag !important;
			}
		}
		.not-two-digit {
			.MuiAvatarGroup-avatar {
				&.MuiAvatar-colorDefault:nth-child(3) {
					@include border-radius(168px);
					padding: 0 5px;
					width: auto;
					height: 18px;
					margin-top: 1px;
					font-size: $font-size-subtitle2;
					border: 0px;
				}
			}
		}
	}
}
/**trend contributors popover css start here**/
.trend-contributors-popper {
	background: $color-white;
	@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
	@include border-radius(6px);
	z-index: 99999;
	max-width: 276px;
	min-width: 276px;
	padding: 0;
	margin-top: 0px;
	margin-left: -5px;
	transform: none !important;
	ul {
		max-height: 268px;
		overflow-y: auto;
		@include custom-scrollbar;
		li {
			padding: 5px 10px;
			@include flex;
			@include align-items(center);
			@include justify-content(space-between);
			margin-bottom: 5px;
			.user-info {
				@include align-items(center);
				.user-img {
					.MuiAvatar-root {
						margin-top: 0px;
					}
				}

				.user-info-details {
					h5 {
						max-width: 120px;
						text-overflow: ellipsis;
						overflow: hidden;
						font-weight: normal;
						white-space: nowrap;
					}
				}
			}
			.col {
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				button {
					padding: 0px;
					margin-left: 20px;
					cursor: default;
					&:hover {
						background: none;
					}
					svg {
						width: 25px;
						height: 25px;
					}
				}
			}
			&:hover {
				background: $color-hover;
			}
			&:last-child {
				margin-bottom: 0px;
				@include border-radius(0 0 6px 6px);
			}
			&:first-child {
				@include border-radius(6px 6px 0 0);
			}
			&:first-child:last-child {
				@include border-radius(6px);
			}
		}
	}
}
