@import '../../sass-utils/variables';

.static-page-wrapper {
	.main-wrapper {
		padding: 2em 0 0;
		.inner-wrapper {
			padding: 0 60px;
		}
		.global-header {
			min-height: auto;
		}
		.MuiTypography-h2 {
			padding-bottom: 0.35em;
		}
		.MuiTypography-body1 {
			padding-bottom: 1em;
		}
		.MuiTypography-h4 {
			padding-bottom: 0.5em;
		}
		.MuiList-root {
			list-style: disc;
			list-style-type: disc;
			.MuiListItem-root {
				padding-top: 0;
				padding-bottom: 0.5em;
				display: block;
				position: relative;
				.MuiTypography-body1,
				.MuiTypography-subtitle2 {
					padding: 0;
				}
				&::after {
					content: '';
					width: 0.35em;
					height: 0.35em;
					position: absolute;
					left: 0;
					top: 0.5em;
					border-radius: 50%;
					background-color: $color-primary2;
				}
			}
		}
		a {
			color: $color-primary1;
			text-decoration: underline;
		}
	}
	.app-header {
		.header-left {
			.app-logo {
				padding: 0 2em;
				h1 {
					line-height: 1;
				}
			}
		}
	}
}
