@import '../styles/sass-utils/variables';

$csspieheight: 50px;

.my-progress {
	.home-title {
		padding-bottom: 1em;
	}
}

.okr-progress-area {
	@include flex;
	@include align-items(center);
	padding-bottom: 0.8em;

	.donut-chart-dashboard {
		max-width: 12.5em;
		min-width: 12.5em;
	}

	.okr-progress-filter {
		@include flex;
		width: calc(100% - 15em);
		padding-left: 1em;
		.progress-card {
			width: 14.25em;
			margin: 0 0 0 1.25em;
			padding: 1em;
			@include flex;
			@include transition-default;
			@include flex-direction(column);
			@include justify-content(space-between);
			min-height: 8.75em;
			@include border-radius(6px);
			background: rgba(255, 255, 255, 0.3);
			border: 1px solid $color-white;
			&:hover {
				background: $color-white;
				box-shadow: 0px 20px 50px rgba(174, 174, 174, 0.5);
				.pie-wrapper {
					&:after {
						background: $color-white;
					}
				}
			}
			&.clicked {
				background: $color-white;
				box-shadow: 0px 20px 50px rgba(174, 174, 174, 0.5);
				@include border-radius(6px);

				.pie-wrapper {
					&:after {
						background: $color-white;
					}
				}
			}
			.pie-wrapper {
				height: 50px;
				width: 50px;
				position: relative;
				@include border-radius($csspieheight / 2);
				@include transition-default;
				&:after {
					width: 40px;
					height: 40px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					background: $color-secondary2;
					border: 2px solid $color-input-field;
					content: '';
					border-radius: 100%;
					z-index: 0;
				}
			}

			.progress-card-content {
				@include flex;
				@include align-items(center);
				@include justify-content(space-between);
				.progress-title {
					width: calc(100% - 55px);
				}
			}
			// .okr-details {
			// 	@include flex;

			// 	h4 {
			// 		color: $color-primary2;
			// 	}

			// 	p {
			// 		margin-right: 0.5em;
			// 		@include flex;
			// 		@include align-items(center);
			// 		flex: 1;
			// 		&:last-child {
			// 			margin-right: 0;
			// 		}
			// 	}

			// 	span {
			// 		width: 1.75em;
			// 		height: 1.75em;
			// 		line-height: 1.75em;
			// 		border-radius: 100%;
			// 		background: #ffdff8;
			// 		color: #ff00c7;
			// 		font-size: $font-size-h6;
			// 		text-align: center;
			// 		margin-left: 0.5em;
			// 		display: inline-block;
			// 		font-weight: $font-weight-bold;
			// 	}
			// }
		}
	}
}

.csspie {
	position: absolute;
	width: $csspieheight/2;
	height: $csspieheight;
	overflow: hidden;
	transform-origin: left center;
	z-index: 1;

	//the visible part of the pie
	&:before {
		content: '';
		position: absolute;
		width: $csspieheight / 2;
		height: $csspieheight;
		left: -($csspieheight / 2);
		border-radius: ($csspieheight / 2) 0 0 ($csspieheight / 2);
		transform-origin: right center;
	}
}

//add color to pie pieces
.notStarted {
	.csspie:before,
	.csspie:after {
		background-color: $color-not-started;
	}
}

.atRisk {
	.csspie:before,
	.csspie:after {
		background-color: $color-text-info;
	}
}

.lagging {
	.csspie:before,
	.csspie:after {
		background-color: $color-lagging;
	}
}

.onTrack {
	.csspie:before,
	.csspie:after {
		background-color: $color-on-track;
	}
}

//rotate the amount of the pie that's showing
@for $i from 1 through 99 {
	.csspie[data-value='#{$i}']:before {
		@include transform(rotate(#{$i * 3.6}deg));
		transition: All 1s ease;
	}
}

@for $i from 50 through 100 {
	.csspie[data-value='#{$i}'] {
		width: $csspieheight;
		height: $csspieheight;
		transform-origin: center;

		&:before {
			left: 0px;
		}

		//big pieces (>50%) need a second semicircle pointed in the opposite direction to hide the first part behind
		&:after {
			content: '';
			position: absolute;
			width: $csspieheight / 2;
			height: $csspieheight;
			left: $csspieheight / 2;
			border-radius: 0 ($csspieheight / 2) ($csspieheight / 2) 0;
		}
	}
}

@for $i from 0 through 49 {
	.csspie[data-value='#{$i}'] {
		left: $csspieheight * 0.5;
	}
}

@keyframes glow {
	0% {
		box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.75);
	}

	50% {
		box-shadow: 0px 0px 18px 0px rgba(255, 255, 255, 0.75);
	}

	75% {
		box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.75);
	}

	100% {
		box-shadow: 0px 0px 14px 0px rgba(255, 255, 255, 0.75);
	}
}

.dashboard-my-goals {
	.my-goal-title {
		@include flex;
		@include align-items(center);
		.home-title {
			margin-right: 0.5em;
		}
		.go-to-link {
			margin-right: 0.5em;
			min-width: auto;
			max-width: 32px;
			width: 32px;
			height: 32px;
			background: $color-white;
			@include border-radius(100%);
			border: 1px solid rgba($color-input-field, 0.4);
			@include flex;
			@include align-items(center);
			&:hover {
				background: $color-secondary2;
			}
		}
	}
}
