@import '../../../styles/sass-utils/variables';
.bulk-user-edit-bar {
	// width: 400px;
	background: rgba($color-primary2, 0.95);
	@include box-shadow(6px 6px 16px rgba($color-primary2, 0.5));
	@include border-radius(6px);
	padding: 1.0625em 0.625em;
	@include flex;
	@include justify-content(space-between);
	position: fixed;
	left: 50%;
	@include transform(translateX(-50%));
	z-index: 9999;
	transition: 1s;
	@include animation(slideIn 0.8s);
	bottom: 30px;
	.bulk-user-selected {
		padding-right: 1.25em;
		padding-left: 0.9375em;
		border-right: 1.4px solid rgba($color-white, 0.2);
		min-height: 2.3125em;
		@include flex;
		@include align-items(center);

		p {
			color: rgba($color-white, 0.5) !important;
			font-weight: $font-weight-bold;
			@include flex;
			@include align-items(center);
			svg {
				margin-right: 5px;
				path {
					fill: $color-white;
				}
			}
		}
	}
	.bulk-user-action {
		position: relative;
		ul {
			@include flex;
			@include align-items(center);
			min-height: 2.3125em;
			margin-right: 2px;
			li {
				padding: 0 10px;
				width: auto;
				svg {
					width: 16px;
					height: 16px;
					path {
						fill: $color-white;
					}
				}
				> .MuiButton-root {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					&:hover {
						background-color: rgba($color-white, 0.2);
					}
				}
				&:last-child {
					padding-right: 0;
				}
				// &:nth-last-child(2) {
				// 	padding: 0 6px;
				// }
				&.active-whiteColor {
					.action-btn.MuiButton-root {
						background-color: $color-white;
						svg {
							path {
								fill: $color-primary2;
							}
						}
					}
				}
			}
		}
		.bulk-user-popover {
			padding-bottom: 60px;
			margin-left: -3px;
			margin-bottom: 5px;
			position: absolute;
			bottom: 100%;
			left: 0px;
			width: 460px;
			height: 328px;
			background: $color-white;
			@include border-radius(6px);
			@include box-shadow(0px 0px 30px rgba($color-primary2, 0.1));
			.change-role-popup {
				height: 340px;
			}
			.connnector-icon {
				width: 0.6875em;
				height: 6px;
				background: $color-white;
				position: absolute;
				bottom: -6px;
				left: 23px;
				//@include box-shadow(31px 42px 25px 0px rgba($color-primary2, 0.12));
				&::before,
				&:after {
					width: 1em;
					height: 7px;
					content: '';
					position: absolute;
					right: -1em;
					top: 0px;
					@include border-radius(50%);
					border: 0 solid $color-white;
					border-width: 0 10px 0 0;
					@include transform(rotate(180deg));
				}
				&:after {
					transform: none;
					right: 0;
					left: -1em;
				}
			}
			.user-selected-info {
				@include flex;
				@include align-items(center);
				padding-bottom: 2em;
				svg {
					margin-right: 0.25em;
					path {
						fill: $color-primary2;
					}
				}
				.MuiTypography-body1 {
					font-size: $font-size-h5;
					line-height: $line-height4;
				}
			}

			.selected-user-message {
				position: absolute;
				width: 100%;
				left: 0;
				top: 3.5em;
				padding: 0.3125em 1.25em;
				background: $color-bg-info;
				@include flex;
				@include align-items(center);
				@include animation(slideUp 0.8s);
				svg {
					margin-right: 0.25em;
					path {
						fill: $color-text-info;
					}
				}
				.MuiTypography-body2 {
					font-weight: $font-weight-bold;
					color: $color-text-info;
				}
			}
			.user-role-dropdown {
				.select-user-role {
					padding-bottom: 0.75em;
					@include flex;
					@include align-items(center);
					svg {
						margin-right: 0.25em;
						path {
							fill: $color-primary2;
						}
					}
					.MuiTypography-body1 {
						font-size: $font-size-h5;
						line-height: $line-height4;
					}
				}
				.user-role__control {
					&.user-role__control--menu-is-open {
						border-top: 0px;
						border-bottom: 1.4px solid $color-primary1;
						@include border-radius(0 0 6px 6px);
						z-index: 999;
					}
				}
				.user-role__menu {
					top: auto;
					padding-top: 0;
					padding-bottom: 40px;
					border-top: 1.4px solid $color-primary1;
					border-bottom: 0px;
					bottom: 0px;
					width: 420px;
					z-index: 99;
					.user-role__menu-list {
						max-height: 215px;
						.user-role__option {
							//padding-top: 0.81em;
							//padding-bottom: 0.81em;
						}
					}
				}
			}
			.bulk-user-popup-actions {
				@include flex;
				@include justify-content(flex-end);
				padding: 0 1.25em;
				position: absolute;
				bottom: 25px;
				width: 100%;
			}
			.bulk-user-popup-head {
				padding: 1em 1.25em;
				background: $color-white;
				position: relative;
				z-index: 9;
				@include border-radius(6px 6px 0 0);
			}
			.bulk-user-popup-content {
				padding: 3.625em 1.25em 1.875em 1.25em;
			}
			.select-search-dropdown {
				&.select-search-dropdown-top {
					&.menu-open {
						.react-select__control {
							@include border-radius(0 0 6px 6px);
							position: relative;
							box-shadow: none;
							border-top: 0px;
							z-index: 99;
							.react-select__indicators {
								.react-select__dropdown-indicator {
									@include transform(rotate(180deg));
								}
							}
							&.react-select__control--menu-is-open,
							&.react-select__control--is-focused {
								.react-select__value-container {
									.react-select__input-container {
										//background-position: 0px -110px;
									}
								}
							}
						}
					}
					.react-select__control {
						&.react-select__control--menu-is-open,
						&.react-select__control--is-focused {
							.react-select__value-container {
								.react-select__input-container {
									//background-position: 0px -107px;
								}
							}
						}
					}
					.react-select__value-container {
						.react-select__multi-value__remove {
							svg {
								path {
									fill: $color-primary2;
								}
							}
							&:hover {
								svg {
									path {
										fill: $color-text-info;
									}
								}
							}
						}
					}
					.react-select__menu {
						top: auto;
						padding-top: 0;
						padding-bottom: 40px;
						border: 1.4px solid $color-primary1;
						bottom: 0px;
						@include border-radius(6px);
						margin: 0;
						z-index: 9;
						@include box-shadow(0px 0px 5px rgba($color-primary1, 0.5));

						.react-select__menu-list {
							border-bottom: 1.4px solid $color-hover;
							border-top: 0px;
							max-height: 15.2em;
							.react-select__option:last-child {
								border-radius: 0px;
							}
						}
					}
				}
			}
		}
	}
}
