body {
	.MuiListItemIcon-root {
		min-width: auto;
	}
	.MuiListItemText-root {
		margin: 0px;
	}

	/** switch css **/
	.MuiSwitch-colorSecondary {
		&.Mui-checked {
			color: $color-white;
		}
		&.Mui-checked + .MuiSwitch-track {
			background-color: $color-on-track;
			opacity: 1;
		}
	}

	/**popover css **/
	.MuiPopover-root {
		z-index: 9999 !important;
	}
	.MuiAutocomplete-popper {
		z-index: 99999 !important;
	}
}
