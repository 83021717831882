@import '../../sass-utils/variables';

/*People View css*/
.view-people-info {
	width: 100%;
	padding: 0.625em 3%;
	top: 0;
	left: 0;
	position: sticky;
	z-index: 99;
	//@include transition-default;
	&.sticky-header {
		background: $color-white;
		@include box-shadow(0 4px 10px rgba($color-primary2, 0.2));
		.view-people-status {
			display: none;
			opacity: 0;
		}
		.view-people-details {
			@include align-items(flex-end);
			.people-header-right {
				//transform: translateY(70px);
			}
		}
	}
	.view-people-details {
		@include flex;
		@include justify-content(space-between);
		@include align-items(flex-start);
		@include transition-default;
		.view-people-info-wrap {
			@include flex;
			@include align-items(flex-start);
		}
		.people-header-right {
			margin-top: -4px;
			@include transition-default;
		}
	}

	.MuiAvatar-root,
	.view-people-img {
		width: 2.5em !important;
		height: 2.5em !important;
		@include border-radius(100%);
		margin-right: 10px;
		font-size: 2em !important;
		margin-top: 0;
		background: $color-bg-info;
		color: $color-tags;
	}
	ul {
		padding: 0;
		@include flex;
		li {
			padding: 0;
			width: auto;
		}
		&.view-profiles-okrs {
			margin: 1em 0 0;
			li {
				margin-right: 1em;
				background-color: $color-bg-profile;
				padding: 0.43em 1.25em;
				@include border-radius(2em);
				font-size: $font-size-h6;
				font-weight: $font-weight-bold;
				min-width: 83px;
				color: $color-primary2;
				min-height: 32px;
			}
		}
	}
	.view-profile-user {
		.profile-user-text {
			.MuiTouchRipple-root {
				display: none;
			}
		}
		ul.view-profile-des {
			padding-top: 0;
			li {
				font-size: $font-size-subtitle2;
				font-weight: $font-weight-bold;
				color: $color-secondary1;
				padding-right: 0.416em;
				margin-right: 0.416em;
				border-right: 1px solid $color-secondary1;
				line-height: 1;
				&:last-child {
					border: none;
					padding: 0;
					margin: 0;
				}
			}
		}
	}
}

/**people view back link css start here**/
.back-to-main.back-to-main-people {
	padding: 0em 3% 0;
	width: 100%;
	position: absolute;
	left: 0;
	top: 10px;
	p {
		padding-left: 90px;
	}
}
/**people view status css start here**/
.view-people-status {
	ul.view-profile-progress {
		margin-top: 00.625em;
		@include flex;
		align-items: center;
		li {
			margin-right: 0.625em;
			width: auto;
			padding: 0;
			span,
			.MuiTypography-body2 {
				width: 18px;
				height: 18px;
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				@include border-radius(1.5em);
				font-weight: $font-weight-bold;
				margin-right: 2px;
				font-size: 10px;
				line-height: 18px;
				display: inline-block;
				text-align: center;
			}
			p {
				font-weight: $font-weight-bold;
				font-size: $font-size-h6;
				color: $color-primary2;
			}
			&.notStarted {
				span,
				.MuiTypography-body2 {
					background: rgba($color-input-field, 0.3);
					color: $color-primary2;
				}
			}
			&.atRisk {
				span,
				.MuiTypography-body2 {
					background: #ffbbbb;
					color: $color-text-info;
				}
			}
			&.lagging {
				span,
				.MuiTypography-body2 {
					background: #ffca99;
					color: $color-lagging;
				}
			}
			&.onTrack {
				span,
				.MuiTypography-body2 {
					background: rgba(9, 204, 140, 0.3);
					color: $color-on-track;
				}
			}
		}
	}
}

.people-view-wrapper,
.team-view-wrapper {
	.page-subheader {
		display: none !important;
	}

	.okr-listing-top-wrap {
		top: 100px;
	}
	.page-container {
		min-height: auto;
		padding-bottom: 0;
	}
	.wrapper-container {
		&.welcome-wrap-content {
			padding-bottom: 0px;
			padding-top: 1.5em;
			object {
				width: 35%;
			}
		}
	}
	.people-action-message-wrap {
		@include flex;
		.action-message {
			@include flex;
			margin-left: 5px;
			@include align-items(center);
			.MuiTypography-body2 {
				color: $color-primary2;
				font-size: $font-size-p-body2;
				line-height: 18px;
				font-weight: $font-weight-bold;
			}
			.declined-text,
			.accepted-text,
			.pending-text,
			.self-text {
				padding: 0.125em 0.4375em !important;
			}
			svg {
				margin-right: 5px;
				max-width: 1em;
				max-height: 1em;
			}
		}
	}
}
