@import '../../../styles/sass-utils/variables';
.report-filter {
	position: relative;
}
.filter-popover {
	background: $color-white;
	@include border-radius(6px);
	padding: 0.75em;
	min-width: 543px;
	max-width: 543px;
	position: absolute;
	top: 32px;
	right: 0;
	@include default-shadow;
	z-index: 999;
	margin-top: 0.5em;
	.connnector-icon {
		width: 0.6875em;
		height: 8px;
		background: $color-white;
		position: absolute;
		top: -8px;
		right: 41px;
		@include box-shadow(31px 42px 25px 0px rgba($color-primary2, 0.12));
		&::before,
		&:after {
			width: 1em;
			height: 8px;
			content: '';
			position: absolute;
			right: -1em;
			top: 0px;
			@include border-radius(50%);
			border: 0 solid $color-white;
			border-width: 0 8px 0 0;
			@include transform(rotate(180deg));
		}
		&:after {
			transform: none;
			right: 0;
			left: -1em;
		}
	}
	.action-btn {
		text-align: right;
		@include flex;
		@include justify-content(flex-end);
		.btn-secondary {
			height: 35px;
		}
		&.action-btn-top {
			position: absolute;
			right: 0px;
			top: 0px;
			z-index: 9;
			padding-top: 0.75em;
			padding-right: 1.25em;
			button {
				&:last-child {
					margin-right: 0px;
				}
				&.btn-cross {
					position: absolute;
					right: 3px;
					top: 3px;
				}
				&.btn-link {
					height: auto;
					line-height: $line-height2;
				}
			}
		}
		.information-message {
			position: static;
			width: 81%;
			padding: 0.3125em 0.625em;
			animation: none;
			.MuiTypography-h6 {
				line-height: 15px;
				@include justify-content(flex-start);
				text-align: left;
				@include align-items(flex-start);
				svg {
					margin-right: 7px;
					min-width: 16px;
					height: 16px;
				}
			}
		}
	}
	.filter-popover-inner {
		.col-head {
			@include flex;
			.MuiFormControlLabel-root {
				margin: 0;
				display: inline-block;
				margin-top: -5px;
				.MuiTypography-body1 {
					font-size: $font-size-h6 !important;
					font-weight: $font-weight-bold !important;
					text-transform: uppercase;
					color: $color-secondary1;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: inline-block;
					max-width: 74%;
					line-height: $line-height5;
					vertical-align: middle;
				}
			}
			.MuiCheckbox-root {
				width: 36px;
				svg {
					width: 19px;
					height: 19px;
				}
			}
			.MuiCheckbox-root.MuiCheckbox-indeterminate {
				svg {
					path {
						fill: $color-white;
					}
				}
			}
		}
		.col-content {
			@include flex;
			max-height: 160px;
			overflow: overlay;
			margin-bottom: 0.625em;
			@include custom-scrollbar;
			&::after {
				content: '';
				position: absolute;
				width: 1px;
				height: calc(100% - 113px);
				border-left: 1px solid $color-hover;
				left: 50%;
				top: 52px;
			}
			.MuiCheckbox-root {
				width: 36px;
				svg {
					width: 19px;
					height: 19px;
				}
			}
		}
		.col {
			padding-right: 1.25em;
			padding-left: 0.625em;
			width: 50%;
			position: relative;
			&:first-child {
				padding-left: 0;
				.MuiFormControlLabel-root {
					padding-left: 0px;
				}
				.filter-list {
					border-left: 0;
					padding-left: 0;
				}
			}
			&:last-child {
				padding-right: 4.25em;
				.MuiTypography-body1 {
					text-transform: initial;
				}
			}
		}
		.filter-list {
			.MuiListItem-root {
				padding: 0em;
				margin-bottom: 0.9375em;
			}
			.MuiFormControlLabel-root {
				padding-left: 0px;
				margin-top: 0px;
				margin: 0px;
				.MuiTypography-body1 {
					font-size: $font-size-h5 !important;
				}
			}
			// .team-details {
			// 	@include flex;

			// 	.MuiTypography-h5 {
			// 		position: relative;
			// 		padding-bottom: 3px;
			// 		white-space: nowrap;
			// 		overflow: hidden;
			// 		text-overflow: ellipsis;
			// 		text-transform: none;
			// 		max-width: 100%;
			// 	}
			// 	.team-info {
			// 		overflow: hidden;
			// 		.MuiTypography-subtitle2 {
			// 			margin-right: 0.5em;
			// 			@include flex;
			// 			@include align-items(center);
			// 			position: relative;
			// 			padding-right: 0.8em;
			// 			&:after {
			// 				content: '';
			// 				position: absolute;
			// 				right: 0;
			// 				width: 3px;
			// 				height: 3px;
			// 				@include border-radius(3px);
			// 				background-color: $color-bg-dot;
			// 				opacity: 0.5;
			// 			}
			// 			&:last-child {
			// 				&::after {
			// 					display: none;
			// 				}
			// 			}
			// 		}
			// 		svg {
			// 			margin-right: 0.3125em;
			// 			width: 14px;
			// 			min-width: 14px;
			// 			height: 14px;
			// 			min-height: 14px;
			// 			path {
			// 				fill: $color-secondary1;
			// 			}
			// 		}
			// 	}
			// }
		}
		.filter-list-nature {
			.MuiListItem-root {
				margin-bottom: 0.3125em;
			}
		}
	}

	&.filter-second-popover {
		min-width: 380px;
		max-width: 380px;
		.filter-popover-inner {
			.col {
				width: 100%;
				padding: 0;
			}
			.col-content {
				&::after {
					display: none;
				}
			}
		}
	}
}
