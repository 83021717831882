@import '../../../styles/sass-utils/variables';
.drawer-upload-image-panel {
	.image-edit-area {
		height: 480px;
		background: $color-primary2;
		.upload-action {
			@include flex;
			@include justify-content(space-between);
			@include align-items(center);
			padding: 1.5em 1.5em 1em;
			.upload-button {
				label {
					width: 10em;
					height: 2.75em;
					line-height: 2.75em;
					text-align: center;
					@include border-radius(3em);
					border: 1px solid $color-white;
					color: $color-white;
					display: block;
					cursor: pointer;
				}
				.upload-image-file-input {
					display: none;
				}
			}
		}
		.MuiTypography-body1 {
			color: $color-white;
		}
		.reset-btn {
			svg {
				path {
					fill: $color-white;
				}
			}
		}
		.MuiSlider-root {
			color: $color-primary1;
			.MuiSlider-rail {
				background-color: $color-not-started;
			}
		}
		.profile-avatar {
			width: 320px;
			height: 320px;
			@include border-radius(100%);
			overflow: hidden;
			canvas {
				width: 320px !important;
				height: 320px !important;
				&.image-section {
					background: $color-white url(../../../images/default-profile-avatar.svg) center center no-repeat;
				}
				&.image-section-white-bg {
					background: $color-white;
				}
			}
		}
	}
	.image-edit-action {
		padding: 3em 10.5% 2em;
	}
	.deleteIcon {
		svg {
			width: 16px;
			height: 16px;
		}
	}
	.profile-avatar-wrap {
		@include flex;
		@include align-items(center);
		@include justify-content(center);
	}
	.image-setting {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.zoom-box,
		.straighten-box {
			width: 100%;
			padding: 16px 16px 0;
		}
	}
}
