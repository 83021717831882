@import '../../../styles/sass-utils/variables';

.popper-menu {
	max-width: 14.6875em;
	width: 100%;
	z-index: 999;
	.popper-menu-inner {
		max-height: 255px;
		@include custom-scrollbar;
		overflow: auto;
	}
	.MuiPaper-root {
		margin-top: 0.3em;
		@include border-radius(6px);
		background: $color-white;
		@include default-shadow;
		position: relative;
		//overflow: hidden;
		.connnector-icon {
			width: 0.6875em;
			height: 7px;
			background: $color-white;
			position: absolute;
			top: -6px;
			right: 19px;
			@include box-shadow(31px 42px 25px 0px rgba($color-primary2, 0.12));
			&::before,
			&:after {
				width: 1em;
				height: 7px;
				content: '';
				position: absolute;
				right: -1em;
				top: 0px;
				@include border-radius(50%);
				border: 0 solid $color-white;
				border-width: 0 6px 0 0;
				@include transform(rotate(180deg));
			}
			&:after {
				transform: none;
				right: 0;
				left: -1em;
			}
		}
		ul {
			padding: 0;
			li {
				padding-top: 0.9375em;
				padding-bottom: 0.9375em;
				cursor: pointer;
				border-bottom: 1.4px solid $color-hover;
				&:first-child {
					@include border-radius(6px 6px 0 0);
				}
				.MuiTypography-root {
					font-size: $font-size-h5;
					color: $color-primary2;
					font-weight: $font-weight-bold;
					line-height: $line-height3;
				}
				.MuiListItemAvatar-root {
					min-width: 1.625em;
					@include flex;
					@include align-items(center);
					margin-right: 0.625em;
					margin-top: 0;
					svg {
						max-width: 17px;
						max-height: 17px;
					}
				}
				&:last-child {
					border-bottom: none;
					@include border-radius(0px 0px 6px 6px);
				}
				&:hover {
					background: $color-hover;
				}
			}
		}
	}

	/**popper-menu-type1**/
	&.popper-menu-type1 {
		max-width: 13.75em;
		.popper-menu-inner {
			max-height: 205px;
		}
		.MuiPaper-root {
			ul {
				li {
					padding-top: 0.625em;
					padding-bottom: 0.625em;
					.MuiListItemAvatar-root {
						svg {
							max-width: 0.875em;
							max-height: 0.875em;
						}
					}
				}
			}
		}
	}
	&.popper-menu-type2 {
		max-width: 205px;
		.popper-menu-inner {
			max-height: 205px;
		}
		.MuiPaper-root {
			overflow: hidden;
			ul {
				li {
					padding-top: 0.625em;
					padding-bottom: 0.625em;
					min-height: 44px;
					.MuiListItemAvatar-root {
						svg {
							max-width: 0.875em;
							max-height: 0.875em;
						}
					}
				}
			}
		}
	}
	&.popper-team-menu {
		min-width: 270px;
		max-width: 270px;
		margin-left: -135px;
		.MuiPaper-root {
			//margin-top: -0.5625em;
			.connnector-icon {
				left: 4.25em;
				right: auto;
			}
			ul {
				li {
					padding: 0.625em;
					.MuiTypography-root {
						text-transform: capitalize;
					}
				}
			}
		}
	}
	&.popper-okr-menu {
		margin-left: -95px;
		margin-top: 5px;
		z-index: 9990;
		.MuiPaper-root {
			ul {
				li {
					padding: 0.625em 1em;
					.MuiTypography-root {
						text-transform: capitalize;
					}
					&.active {
						background: $color-secondary2;
					}
				}
			}
		}
		.MuiPaper-root {
			&::before {
				width: 15px;
				height: 15px;
				content: '';
				position: absolute;
				left: 40px;
				top: -8px;
				background: $color-white;
				transform: rotate(45deg);
				@include box-shadow(-1px 0px 10px 0 rgba($color-primary2, 0.12));
			}
		}

		&[x-placement='top-start'] {
			.MuiPaper-root {
				margin-top: 0px;
				margin-bottom: 0.3em;

				&::before {
					@include box-shadow(7px 3px 10px 0 rgba($color-primary2, 0.12));
					bottom: -7px;
					top: auto;
				}
			}
		}
	}
}
