@import '../../sass-utils/variables';
.kr-btn-group {
	.MuiButton-root {
		@include btn;
		background-color: transparent;
		border: 1px solid rgba($color-input-field, 0.5) !important;
		color: $color-primary2;
		@include border-radius(3em);
		font-weight: $font-weight-regular;
		@include transition-default;
		min-width: 11.4375em;
		&:hover {
			@include box-shadow(6px 9px 26px rgba(57, 163, 250, 0.3));
		}
		&.active {
			background-color: $color-secondary2;
			border-color: $color-primary1 !important;
		}
		&:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		svg {
			width: 16px;
			height: 16px;
			margin-right: 0.5em;
		}
	}
}
.okr-assign-btn-grp {
	.assignee-data {
		margin-top: 0.8em;
	}
}
