@import '../../sass-utils/variables';
.organization-wrapper {
	max-height: 105vh;
	overflow: hidden;
	margin-top: -1em;
	.welcome-content {
		svg,
		object {
			margin-bottom: 2em;
		}
	}
	.navbar {
		padding-bottom: 1em !important;
		padding-top: 1em !important;
		.global-header {
			min-height: auto;
			@include align-items(center);
		}
	}
	.global-header-info {
		position: relative;
		@include justify-content(flex-end);
		.fit-screen-action {
			margin: 0px -36px 0 0;
			position: absolute;
			right: 60px;
			top: -55px;
		}
	}
}
.create-org-area {
	// .okr-drawer-content {
	// 	.okr-form-fields {
	// 		.due-date {
	// 			.MuiFormLabel-root {
	// 				padding-bottom: 0.75em;
	// 				display: block;
	// 			}
	// 			.MuiFormControl-root {
	// 				display: block;
	// 			}
	// 		}
	// 		.okr-form-action {
	// 			@include flex;
	// 			@include justify-content(flex-end);
	// 			padding-top: 0;
	// 		}
	// 		.MuiRadio-colorSecondary {
	// 			&.Mui-checked {
	// 				color: $color-primary1;
	// 				&:hover {
	// 					background: rgba(56, 163, 250, 0.04);
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.upload-wrapper {
		.upload-logo {
			position: relative;
			margin: 0.5em 0;
			@include flex;
		}
		.file-btn {
			min-width: 8em;
			height: 2.42em;
			background-color: rgba($color-input-field, 0.5);
			border: 1px solid rgba($color-input-field, 0.5);
			color: $color-primary2;
			border-radius: 3em;
			font-size: $font-size-h6;
			font-weight: 300;
		}
		input[type='file'] {
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
		.file-path-wrapper {
			@include flex;
			flex-wrap: wrap;
			padding: 0;
			li {
				padding: 0.25em 0.5em 0.25em 1em;
				background: $color-white;
				width: auto;
				border-radius: 40px;
				overflow: hidden;
				margin: 0 0 0 0.5em;
				@include flex;
				border: 1px solid rgba($color-input-field, 0.5);
				button {
					margin-left: 0.2em;
					padding: 0;
					min-width: auto;
					border-radius: 100%;
				}
			}
		}
	}
}
/**organization header css start here**/
.organization-logo-wrapper {
	padding-bottom: 0.5em;
	position: relative;
	width: 110px;
	height: 110px;
	.org-logo {
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		width: 100px;
		height: 100px;
		background: $color-bg-info;
		overflow: hidden;
		position: relative;
		flex-shrink: 0;
		border-radius: 50%;
	}
	.no-org-image {
		svg {
			path {
				fill: $color-tags;
			}
		}
	}

	.upload-image-file-input {
		display: none;
	}
}
