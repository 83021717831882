body{
    .MuiPickersBasePicker-pickerView {
        max-width:100%;
        min-width: 100%;
        min-height: 260px;
        padding: 0 20px 20px 20px;
        .MuiPickersCalendar-transitionContainer{
            min-height: 160px;
        }
        .MuiPickersCalendarHeader-switchHeader{
            border-bottom: 1.4px solid rgba($color-input-field, 0.5);
        }
        .MuiPickersCalendarHeader-daysHeader{
            .MuiPickersCalendarHeader-dayLabel{
                width: 39px;
                margin: 0 4px;
                font-size: $font-size-subtitle1;
                color: $color-secondary1;
            }
        }
        .MuiPickersCalendar-week{
            .MuiPickersDay-day{
                width: 39px;
                height: 30px;
                margin: 0 4px;
                @include border-radius(0);
                .MuiTypography-body2{
                    font-weight: $font-weight-regular;
                    font-size: $font-size-subtitle1;
                }
                &.MuiPickersDay-daySelected{
                    background-color: $color-text-selection;
                }
                &.MuiPickersDay-dayDisabled{
                    opacity: 0.7;
                }
            }
        }
    }
}
