@import '../../../styles/sass-utils/variables';
/**org radial chart css start here**/
.org-radial-report {
	background: $color-bg-radial-report;
	@include border-radius(6px);
	padding: 1.5em;
	position: -webkit-sticky;
	position: sticky;
	top: 4.5em;
	.MuiTypography-h4 {
		margin-bottom: 14px !important;
	}
	.radial-chart-area {
		.radial-chart-ct-image {
			z-index: initial;
		}
	}
}
.org-okr-count {
	margin-top: 2em;
	@include flex;
	@include justify-content(center);
	ul {
		@include flex;
		@include justify-content(flex-start);
		flex-wrap: wrap;
		.MuiListItem-root {
			padding: 0;
			margin-right: 1em;
			@include flex;
			@include align-items(center);
			margin-top: 0.625em;
			width: auto;
			&.okr-count {
				svg {
					width: 20px;
					height: 20px;
				}
			}
			&.kr-count {
				svg {
					width: 18px;
					height: 18px;
				}
			}
			svg {
				width: 14px;
				height: 14px;
				margin-right: 0.3125em;
			}
			.MuiTypography-body2 {
				font-weight: $font-weight-bold !important;
				text-transform: uppercase;
				text-align: left;
				margin: 0px !important;
			}

			&:last-child {
				margin-right: 0;
				padding-right: 0;
				min-width: auto;
				flex: 1;
				&::after {
					display: none;
				}
			}
		}
	}
}

/**org heat chart css start here**/
.heat-chart-area {
	> ul {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: repeat(auto-fill, minmax(176px, 1fr));
		grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
		grid-gap: 0px;
		.MuiListItem-root {
			padding: 0;
			width: auto;
		}
	}
	.team-report-card {
		width: 100%;
		height: 72px;
		border: 0.7px solid $color-white;
		@include border-radius(6px);
		padding: 0.625em;
		@include flex;
		@include flex-direction(column);
		@include align-items(center);
		@include justify-content(center);
		position: relative;

		.MuiTypography-root {
			color: $color-white;
			text-align: center;
			&:first-child {
				margin-bottom: 5px;
			}
		}
		.MuiTypography-h5 {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 100%;
		}
		.favorite-icon {
			position: absolute;
			right: 4px;
			top: 3px;
			padding: 0.3125em;
			@include border-radius(50px);
			svg {
				width: 14px;
				height: 14px;
				path {
					fill: $color-okr-highlighter;
				}
			}
			.MuiTouchRipple-root {
				display: none;
			}
		}
	}
	.chart-legend-default {
		margin-bottom: 0.5em;
		@include flex;
		@include justify-content(center);
		ul {
			li {
				margin-right: 1.5em;
				padding: 0;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.color-1 {
		background: $color-report-map-100;
	}
	.color-2 {
		background: $color-report-map-80;
	}
	.color-3 {
		background: $color-report-map-60;
	}
	.color-4 {
		background: $color-report-map-40;
	}
	.color-5 {
		background: $color-report-map-20;
	}
}

/**org tooltip css start here**/
.heat-chart-popover {
	z-index: 9;
	transform: translate(-50%, -50%) !important;
	left: 50% !important;
	top: 50% !important;
	.heat-chart-tooltip {
		width: 352px;
		min-height: 287px;
		padding: 1.5em 1.375em 50px;
		@include box-shadow(0px 0px 30px rgba($color-primary2, 0.5));
		@include border-radius(6px);
		.tooltip-head {
			@include flex;
			@include justify-content(space-between);
			margin-bottom: 10px;
			.MuiTypography-body1 {
				font-weight: $font-weight-bold;
				margin-bottom: 5px;
				max-width: 80%;
			}
			.tooltip-head-right {
				width: 54px;
				text-align: right;
			}
			button {
				padding: 0.3125em;
				@include border-radius(50px);
				position: static;
				svg {
					width: 14px;
					height: 14px;
					path {
						fill: $color-white;
					}
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
		}
		.MuiTypography-root {
			color: $color-white !important;
		}
		.org-okr-count {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 23px;
			padding: 0 1.375em;
			ul {
				.MuiListItem-root {
					&:last-child {
						svg {
							path {
								fill: $color-white;
							}
						}
					}
				}
			}
		}
	}
	.radial-chart {
		.radial-chart-area {
			.radial-chart-ct-image {
				background: rgba($color-white, 0.5);
				color: $color-white;
			}
		}
	}
}
