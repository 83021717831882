@import '../../sass-utils/variables';
.kr-metrics-fileds {
	max-width: 72%;
	@include flex;
	@include align-items(center);
	.kr-metrics-fileds-inner {
		@include flex;
		.MuiFormHelperText-root {
			&.Mui-error {
				min-width: 220px;
			}
		}
	}
	.matrics-field-seprator {
		@include flex;
		margin: 0 2em;
		align-items: flex-end;
		padding-bottom: 0px !important;
		color: $color-primary2;
	}

	&.kr-metrics-full-width {
		width: 100%;
		max-width: 100%;
	}
	&.decline-metrics-field {
		.kr-metrics-fileds-inner {
			.currency-icon-wrap {
				@include flex;
				svg {
					max-width: 14px;
					max-height: 22px;
				}
			}
		}
	}
}

.kr-measure-unit {
	padding-bottom: 1.875em;
	.measure-btn {
		margin-right: 1.625em;
		width: 6.25em;
		height: 6.25em;
		border: 1.4px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		font-weight: $font-weight-regular;
		background-color: $color-white;
		@include transition-default;
		color: $color-secondary1;
		.MuiButton-label {
			@include flex;
			@include flex-direction(column);
			svg {
				margin: 0.5em 0;
			}
		}
		&.currency-btn {
			// background-image: url(../../../images/more-h-sprite.svg);
			// background-size: 73px;
			// background-repeat: no-repeat;
			// background-position: center 85px;
			position: relative;
			.MuiButton-label {
				> svg {
					width: 14px;
					height: 14px;
					@include transform(rotate(90deg));
					position: absolute;
					bottom: -4px;
				}
			}
		}
		&:hover {
			background-color: $color-hover;
			border: 1.4px solid rgba($color-input-field, 0.5);
			box-shadow: 0px 10px 30px rgba($color-primary2, 0.2);
		}
		&.active {
			background-color: $color-secondary2;
			border: 1.4px solid $color-primary1;
			color: $color-primary1;
			box-shadow: none;
			svg {
				path {
					fill: $color-primary1;
				}
			}
			&.currency-btn,
			&#measure-btn-Currency {
				svg {
					path {
						stroke: $color-primary1;
						stroke-width: 0.4px;
					}
				}
			}
		}
	}
	&.choose-metric-field {
		.MuiFormLabel-root {
			//padding-bottom: 0.15em;
		}
		.choose-metric-msg {
			max-width: 400px;
			margin-bottom: 0.3125em;
		}
	}
}
