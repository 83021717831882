@mixin btn {
	min-width: 7.5em;
	height: 35px;
	line-height: 35px;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin flex {
	display: -webkit-flex;
	display: flex;
}

@mixin inline-flex {
	display: -webkit-inline-flex;
	display: inline-flex;
}

@mixin align-items($value) {
	-webkit-box-align: $value;
	-ms-flex-align: $value;
	align-items: $value;
}

@mixin justify-content($value) {
	-webkit-box-justify-content: $value;
	-ms-flex-justify-content: $value;
	justify-content: $value;
}
@mixin flex-direction($value) {
	-webkit-box-flex-direction: $value;
	-ms-flex-flex-direction: $value;
	flex-direction: $value;
}
@mixin transition-default {
	transition: all ease-in-out 0.25s;
	-webkit-transition: all ease-in-out 0.25s;
	-moz-transition: all ease-in-out 0.25s;
	-o-transition: all ease-in-out 0.25s;
}
@mixin transition($value) {
	transition: $value;
	-webkit-transition: $value;
	-moz-transition: $value;
	-o-transition: $value;
}
@mixin transform($value) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-o-transform: $value;
	-ms-transform: $value;
	transform: $value;
}
@mixin box-shadow($shadow) {
	-moz-box-shadow: $shadow;
	-webkit-box-shadow: $shadow;
	box-shadow: $shadow;
}
@mixin default-shadow {
	-moz-box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
	-webkit-box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
	box-shadow: 2px 2px 10px rgba(41, 41, 41, 0.12);
}
@mixin panel-shadow {
	-moz-box-shadow: 0px 0px 50px rgba(41, 41, 41, 0.1);
	-webkit-box-shadow: 0px 0px 50px rgba(41, 41, 41, 0.1);
	box-shadow: 0px 0px 50px rgba(41, 41, 41, 0.1);
}
@mixin tile-shadow {
	-moz-box-shadow: 0px 10px 5px rgba(41, 41, 41, 0.03);
	-webkit-box-shadow: 0px 10px 5px rgba(41, 41, 41, 0.03);
	box-shadow: 0px 10px 5px rgba(41, 41, 41, 0.03);
}
@mixin tooltip-shadow {
	-moz-box-shadow: 0px 5px 10px rgba(41, 41, 41, 0.15);
	-webkit-box-shadow: 0px 5px 10px rgba(41, 41, 41, 0.15);
	box-shadow: 0px 5px 10px rgba(41, 41, 41, 0.15);
}
@mixin button-shadow {
	-moz-box-shadow: 6px 9px 26px rgba(57, 163, 250, 0.5);
	-webkit-box-shadow: 6px 9px 26px rgba(57, 163, 250, 0.5);
	box-shadow: 6px 9px 26px rgba(57, 163, 250, 0.5);
}

@mixin animation($value) {
	-webkit-animation: $value;
	-moz-animation: $value;
	-o-animation: $value;
	animation: $value;
}
@mixin transition-rotate {
	-webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	-moz-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	-ms-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	-o-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
@mixin custom-scrollbar {
	&::-webkit-scrollbar-track {
		background-color: #eef2f4;
		border-radius: 6px;
	}
	&::-webkit-scrollbar {
		width: 8px;
		background-color: #eef2f4;
		border-radius: 30px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #c4c4c4;
		border-radius: 30px;
	}
}
@mixin horizontal-custom-scrollbar {
	&::-webkit-scrollbar-track {
		background-color: #eef2f4;
		border-radius: 6px;
	}
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: #eef2f4;
		border-radius: 30px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #c4c4c4;
		border-radius: 30px;
	}
}
@mixin horizontal-custom-scrollbar-width-zero {
	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 6px;
	}
	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		background-color: transparent;
		border-radius: 30px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		border-radius: 30px;
	}
}
@mixin custom-scrollbar-small-height {
	&::-webkit-scrollbar-track {
		background-color: rgba(216, 227, 241, 1);
		border-radius: 17px;
		opacity: 0.11;
	}
	&::-webkit-scrollbar {
		width: 8px;
		height: 6px;
		background-color: rgba(216, 227, 241, 1);
		border-radius: 17px;
		opacity: 0.11;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #bcbcbc;
		border-radius: 8px;
		opacity: 0.11;
	}
}
@mixin custom-scrollbar-small-width {
	&::-webkit-scrollbar-track {
		background-color: $color-hover;
		border-radius: 6px;
	}
	&::-webkit-scrollbar {
		width: 6px;
		background-color: $color-hover;
		border-radius: 30px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba($color-primary2, 0.3);
		border-radius: 30px;
	}
}

@mixin custom-scrollbar-dark-theme {
	&::-webkit-scrollbar-track {
		background-color: #efefef;
		border-radius: 8px;
		opacity: 1;
	}
	&::-webkit-scrollbar {
		width: 8px;
		background-color: #efefef;
		border-radius: 30px;
		opacity: 1;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(41, 41, 41, 0.3);
		border-radius: 30px;
		opacity: 1;
	}
}
