/**tooltip css**/
body {
	.MuiTooltip-popper {
		z-index: 99999 !important;
		.MuiTooltip-tooltip {
			background: $color-primary2;
			@include tooltip-shadow;
			color: $color-white;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
			padding: 6px 10px;
			max-width: 166px;
		}
		.MuiTooltip-arrow {
			color: $color-primary2;
		}
		&.MuiTooltip-popperArrow[x-placement*='top'] {
			.MuiTooltip-arrow {
				bottom: 1px !important;
				left: 50% !important;
				margin-left: -6px;
			}
		}
		&.MuiTooltip-popperArrow[x-placement*='bottom'] {
			.MuiTooltip-arrow {
				margin-top: -0.68em;
			}
		}
		.MuiTypography-root {
			color: $color-white;
			font-size: $font-size-h6;
			font-weight: $font-weight-bold;
			line-height: $line-height5;
		}
		em {
			font-weight: $font-weight-regular;
			margin-top: 0.625em;
			display: block;
		}
		&.tooltip-layout-with-icon {
			.MuiTooltip-tooltip {
				max-width: 200px;
				margin: 13px 0 0 0;
			}
			.tooltip-text {
				@include flex;
				@include align-items(flex-start);
				svg {
					height: 14px;
					margin-right: 10px;
				}
			}
			&.MuiTooltip-popperArrow[x-placement*='top'] {
				.MuiTooltip-arrow {
					left: 42px !important;
				}
			}

			&.MuiTooltip-popperArrow[x-placement*='bottom'] {
				.MuiTooltip-arrow {
					left: 38px !important;
				}
			}
		}
		&.tooltip-layout1 {
			.MuiTooltip-tooltip {
				max-width: 130px;
				min-width: 130px;
			}
		}
		&.tooltip-layout2 {
			.MuiTooltip-tooltip {
				max-width: 156px;
				min-width: 156px;
			}
		}
		&.tooltip-layout3 {
			.MuiTooltip-tooltip {
				max-width: 190px;
				min-width: 190px;
			}
		}
		&.tooltip-layout4 {
			.MuiTooltip-tooltip {
				max-width: 200px;
				min-width: 200px;
			}
		}
		&.tooltip-layout5 {
			.MuiTooltip-tooltip {
				max-width: 250px;
				min-width: 250px;
			}
		}
		&.tooltip-layout6 {
			.MuiTooltip-tooltip {
				max-width: 260px;
				min-width: 260px;
			}
		}
		&.tooltip-layout7,
		&.conf-medium {
			.MuiTooltip-tooltip {
				max-width: 225px;
				min-width: 225px;
			}
		}
		&.tooltip-layout8,
		&.conf-low {
			.MuiTooltip-tooltip {
				max-width: 210px;
				min-width: 210px;
			}
		}
		&.add-another-tooltip {
			.MuiTooltip-tooltip {
				margin: 0px 0 0px -46px;
				max-width: 190px;
				min-width: 190px;
			}
		}
		&.tooltip-layout-margin {
			.MuiTooltip-tooltip {
				margin: 5px 0 !important;
			}
		}
		&.tooltip-alert {
			.MuiTooltip-tooltip {
				background: $color-tags;
			}
			.MuiTooltip-arrow {
				color: $color-tags;
			}
			ul {
				padding: 0;
				margin-left: 12px;
				li {
					margin: 0;
					padding: 0;
					position: relative;
					display: list-item;
					list-style-type: disc;
				}
			}
		}
		
	}

	/**tooltip html css start here**/
	.tooltip-html {
		.MuiTooltip-tooltip {
			ul {
				li {
					padding: 0 0 0.25em;
					font-size: $font-size-h6;
					font-weight: $font-weight-bold;
					line-height: $line-height5;
				}
			}
		}
	}
}
