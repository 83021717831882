@import '../sass-utils/variables';

body {
	.drawer-nav {
		z-index: 9990 !important;
		.MuiBackdrop-root {
			//background: none;
		}
		.drawer-nav-head {
			@include flex;
			@include align-items(center);

			.nav-drawer-close {
				margin-left: 0px;
				width: 56px;
				height: 64px;
				margin-right: 10px;
				margin-left: 5px;
				@include border-radius(0px);
			}
			.logo-img {
				img {
					max-width: 107px;
				}
			}
		}
		.MuiDrawer-paper {
			width: 303px;
			@include box-shadow(2px 2px 10px rgba($color-primary2, 0.12));
			@include flex;
			@include justify-content(space-between);
			@include custom-scrollbar;
			ul {
				padding: 0px;
				li {
					padding: 0;
					font-size: $font-size-h5;
					a {
						padding: 0.57em 1em 0.57em 2.85em;
						color: $color-primary2;
						width: $full-width;
						@include flex;
						@include align-items(center);
						svg {
							margin-right: 1.25em;
							width: 14px;
							height: 14px;
							min-width: 14px;
						}
						& > * {
							@include transition-default;
						}
						p {
							font-size: $font-size-h5;
							line-height: $line-height4;
						}
					}
					&:hover {
						background: $color-hover;
						text-decoration: none;
					}
				}
			}
			.drawer-link-list {
				padding: 0;
				flex: 1 1;
				max-height: calc(100% - 238px);
				overflow: auto;
				@include custom-scrollbar;
				li {
					&.reports-download {
						padding: 0.57em 1em 0.57em 2.85em;
						button {
							background: transparent;
							border: 0;
							font-weight: $font-weight-regular;
						}
						svg {
							width: 14px;
							min-width: 14px;
							height: 14px;
							margin-right: 1.25em;
						}
						.MuiTypography-button {
							min-width: 140px;
							font-size: $font-size-h5;
							line-height: $line-height4;
							max-width: 172px;
							white-space: normal;
						}
						.MuiFormControl-root {
							.MuiFormControlLabel-root {
								margin-right: 0;
								.MuiCheckbox-root {
									svg {
										margin-right: 0;
									}
								}
							}
						}
					}
					&.disable-link {
						a {
							cursor: default;
							.MuiTypography-body1 {
								color: $color-input-field;
							}
							svg {
								path {
									fill: $color-input-field;
								}
							}
						}
						&:hover {
							background-color: $color-hover;
						}
					}
					.spinner {
						position: absolute;
						right: 0.5em;
					}
				}
				.download-report-head {
					padding: 0 1em 0 40px;
					position: relative;
					margin-top: 8px;
					margin-bottom: 5px;
					span {
						position: relative;
						background: $color-white;
						font-size: $font-size-h6;
						line-height: $line-height5;
						font-weight: $font-weight-bold;
						color: $color-secondary1;
						padding-right: 15px;
					}
					&::before {
						content: '';
						width: calc(100% - 80px);
						height: 1.4px;
						background: $color-hover;
						position: absolute;
						left: 40px;
						top: 8.5px;
					}
				}
				.download-report-menu {
					padding: 0px;
				}
			}
			.drawer-bottom-links {
				width: 100%;
				padding: 0;
				.bottom-link-info {
					background: $color-bg-form;
					padding: 0;
					margin-bottom: 2px;
					li {
						&.disable-link {
							a {
								cursor: default;
								.MuiTypography-body1 {
									color: $color-input-field;
								}
								svg {
									path {
										fill: $color-input-field;
									}
								}
							}
							&:hover {
								background-color: $color-hover;
							}
						}
					}
				}
				.academy-link {
					margin-bottom: 20px;
					padding: 0;
					li {
						padding: 0px 2.85em;
						background: none !important;
						cursor: default;
						.MuiTouchRipple-root {
							display: none;
						}
						.academy-link-wrap {
							background: $color-threshold;
							height: 70px;
							@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
							@include border-radius(6px);
							color: $color-white;
							padding: 15px 20px;
							@include flex;
							width: 100%;
							cursor: pointer;
							h4 {
								color: $color-white;
								font-weight: $font-weight-bold;
							}
							.MuiTypography-body2 {
								font-size: $font-size-h6;
								line-height: $line-height4;
								font-weight: normal;
								color: $color-white;
							}
							.academy-btn {
								width: 40px;
								min-width: 40px;
								height: 40px;
								@include border-radius(100px);
								@include box-shadow(5px 8px 24px rgba($color-primary1, 0.5));
								background: $color-primary1;
								text-align: center;
								margin-left: 21px;
								svg {
									margin: 0px;
									width: 14px;
									height: 14px;
									min-width: 14px;
									path {
										fill: $color-white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/*Header CSS */
.app-header {
	background: $color-secondary2 !important;
	box-shadow: none !important;
	z-index: 9990 !important;
	position: relative !important;
	padding-right: 0px !important;
	.MuiToolbar-root {
		@include flex;
		@include justify-content(space-between);
	}
	.header-left {
		@include flex;
		@include align-items(center);
		min-width: 30%;
		.MuiIconButton-root {
			padding: 0;
		}
		.app-logo {
			padding: 0 1em;
			button {
				&:hover {
					background: transparent;
				}
			}
			h1 {
				line-height: 0.75;
			}
		}
		.drawer-open {
			width: 44px;
			height: 44px;
		}
	}
	.header-right {
		@include flex;
		@include align-items(center);
		@include justify-content(flex-end);
		min-width: 30%;
		.notification-area {
			@include flex;
			@include align-items(center);
			.notification-btn {
				width: 16px;
				height: 16px;
				font-size: 1em;
				padding: 0;
				object {
					//display: none;
					display: block;
					position: relative;
					z-index: -9;
				}
				&.notification-btn-alert {
					/*animation: pulse_animation;
					animation-duration: 1000ms;
					animation-iteration-count: infinite;
					animation-timing-function: linear;*/
					object {
						display: block;
						position: relative;
						z-index: -9;
					}
					svg {
						display: none;
					}
				}
				&:hover {
					background: transparent;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			}
			.chat-bubble {
				display: block;
				svg {
					width: 1em;
					height: 1em;
				}
			}
		}
		.user-tab {
			position: relative;
			@include flex;
			@include align-items(center);
			.profile-btn {
				padding: 0;
			}
		}
		.client-logo {
			max-width: 100px;
			margin-right: 1em;
		}
	}
	.MuiLinearProgress-root {
		position: fixed !important;
		top: 0 !important;
		z-index: 9999;
		width: 100%;
	}
}
.tenure-navigation {
	margin: 0 20px;
}
.tenure-stepper {
	position: relative;
	min-width: 133px;
	min-height: 30px;
	@include flex;
	@include align-items(center);
	background: $color-white;
	padding: 0px 28px;
	@include border-radius(128px);
	.MuiMobileStepper-root {
		padding: 0px;
		background: none;

		button {
			width: 20px;
			height: 20px;
			@include border-radius(50%);
			background: rgba($color-primary2, 0.05);
			position: absolute;
			top: 50%;
			margin-top: -10px;
			&:hover {
				background: rgba($color-primary2, 0.08);
			}
			&.Mui-disabled {
				opacity: 0.5;
			}
			&.tenure-previous {
				left: 5px;
			}
			&.tenure-next {
				right: 5px;
			}
			svg {
				width: 8px;
				height: 8px;
				path {
					stroke: $color-primary2;
					stroke-width: 2px;
				}
			}
		}
		.MuiMobileStepper-progress {
			display: none;
		}
	}
	.tenure-stepper-text {
		width: 100%;
		text-align: center;
		padding: 0;
		background: none;
		box-shadow: none;
		position: static;
		font-size: $font-size-h6;
		font-weight: $font-weight-bold;
		min-height: 30px;
		&:hover {
			background: none;
			box-shadow: none;
		}
		.icon {
			order: 2;
			svg {
				width: 8px;
				margin-left: 5px;
				height: 7px;
				path {
					stroke-width: 0.5px;
					stroke: $color-primary2;
				}
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
	}
	&.not-current-cycle {
		background: $color-primary1;
		.icon {
			svg {
				path {
					fill: $color-white;
					stroke: $color-white;
				}
			}
		}

		.tenure-stepper-text {
			color: $color-white;
		}
		.MuiMobileStepper-root {
			button {
				background: rgba($color-white, 0.1);
				svg {
					path {
						stroke: $color-white;
					}
				}
			}
		}
	}
}
.notification-drawer {
	z-index: 9991 !important;
	.drawer-close-btn {
		z-index: 99;
	}
	.MuiDrawer-paper {
		overflow: inherit;
		width: 860px;
		@include panel-shadow;
		.notification-tabs-area {
			.loader-area {
				width: 100%;
				position: absolute;
				left: 0px;
				top: 60px;
				z-index: 9;
			}
			.notification-tabs {
				position: relative;
				@include flex;
				@include justify-content(space-between);
				@include align-items(center);
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1.4px;
					background: rgba($color-input-field, 0.5);
				}
				.notification-tabs-left {
					padding-left: 15px;
					width: calc(100% - 40px);
				}
				.notification-tabs-right {
					width: 40px;
					.kebab-icon {
						color: $color-primary1;
						svg {
							path {
								fill: $color-primary1;
							}
						}
					}
				}
			}
			.MuiTabs-root {
				min-height: 60px;
				.MuiTab-root {
					padding-left: 0;
					padding-right: 0;
					min-width: 30px;
					min-height: 60px;
					text-transform: inherit;
					color: $color-secondary1;
					opacity: 1;
					font-size: $font-size-h5;
					font-weight: $font-weight-bold;
					margin-left: 20px;
					&.Mui-selected {
						color: $color-primary1;
					}
				}
				.MuiTabs-indicator {
					z-index: 1;
					height: 1.4px;
					background-color: $color-primary1;
				}
			}
			.notification-tabs-panel {
				overflow-x: hidden;
				overflow-y: auto;
				max-height: calc(100vh - 60px);
				min-height: calc(100vh - 60px);
				@include custom-scrollbar;
				.notification-actions {
					margin: 0.5em 0 0.25em;
					button {
						&:first-child {
							margin-left: 0;
							margin-right: 2em;
						}
					}
				}

				.unread-message-count {
					padding: 30px 50px 20px 35px;
					font-size: $font-size-h5;
					line-height: $line-height3;
				}
				ul {
					padding: 0;
					li {
						padding: 1em 65px 0.875em 35px;
						@include transition-default;
						@include align-items(flex-start);
						&:hover {
							@include box-shadow(0px 0px 30px rgba($color-primary2, 0.1));
							@include border-radius(6px);
							.notification-action-btn {
								right: 0;
							}
						}
						.MuiTypography-h6 {
							margin-top: 5px;
							&:first-letter {
								text-transform: capitalize;
							}
						}
						.MuiAvatar-root {
							margin-right: 0.75em;
						}
						.notification-action-btn {
							position: absolute;
							background: $color-primary1;
							right: -50px;
							top: 0;
							@include flex;
							@include align-items(center);
							@include flex-direction(column);
							height: 100%;
							width: 50px;
							@include justify-content(center);
							@include transition(all ease-in-out 0.5s);
							padding: 5px 0;
							cursor: default;
							.action-btn {
								width: 22px;
								height: 22px;
								margin-bottom: 6px;
								svg {
									width: 14px;
									height: 14px;
									min-width: 14px;
									path {
										fill: $color-white;
									}
								}
								&:last-child {
									margin-bottom: 0px;
								}
							}
						}
						&.unread-message {
							.MuiTypography-body1 {
								color: $color-primary1;
							}
						}
						&.load-more-link {
							&:hover {
								box-shadow: none;
							}
							.MuiListItemText-root {
								@include flex;
								@include align-items(center);
								.MuiTypography-body1 {
									cursor: pointer;
									color: $color-primary1;
									&:hover {
										text-decoration: underline;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#menu-list-grow {
		@include custom-scrollbar;
	}
}
.popper-menu-list {
	&.notification-menu-list {
		margin-right: 1em;
		min-width: 203px;
		z-index: 9999;
		@include border-radius(6px);
		.MuiPaper-elevation1 {
			ul {
				li {
					font-size: $font-size-h5;
					svg {
						width: $font-size-h5;
						height: $font-size-h5;
					}
				}
			}
		}
	}
}
.my-account {
	z-index: 999 !important;
	.MuiPaper-elevation1 {
		width: 215px;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
		position: relative;
		margin-top: 1.65em;
		margin-right: 1em;
		&::before {
			@include border-radius(3px);
			width: 15px;
			height: 15px;
			content: '';
			position: absolute;
			right: 12px;
			top: -7px;
			background: $color-white;
			transform: rotate(45deg);
		}
		ul {
			padding: 0;
			li {
				padding: 0;
				@include align-items(center);
				& > * {
					@include transition-default;
				}
				a {
					width: $full-width;
					padding: 0.5em 0.75em;
					@include flex;
					color: $color-primary2;
					svg {
						margin-right: 0.5em;
					}
					& > * {
						@include transition-default;
					}
				}
				svg {
					margin: 0 0.5em 0 0;
				}
				&.logout-btn {
					padding: 0.5em 0.75em;
					@include flex;
					@include align-items(center);
				}
				&.pfeedback-btn {
					svg {
						-webkit-filter: grayscale(100%);
						filter: grayscale(100%);
					}
				}
				&:hover {
					background-color: $color-hover;
				}
				.MuiTypography-body1 {
					padding: 0;
				}
			}
		}
	}
}
.no-notification {
	&:hover {
		box-shadow: none !important;
		border-radius: 0 !important;
	}
	.no-notification-msg {
		text-align: center;
		padding: 50px;
		height: calc(100vh - 90px);
		width: 100%;
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		img {
			margin-top: 70px;
		}
	}
}
.deleteIcon {
	svg {
		path {
			fill: $color-white;
		}
	}
}

.notification-delete {
	svg {
		path {
			fill: $color-white;
		}
	}
}
/**subheader**/
.page-subheader {
	padding: 0em 60px 0em;
	position: -webkit-sticky;
	position: sticky;
	top: 0em;
	left: 0;
	width: 100%;
	z-index: 9;
	background: $color-secondary2;
	&.sticky-header {
		background: $color-white;
		z-index: 99;
		box-shadow: 0 4px 10px rgba($color-primary2, 0.2) !important;
	}
	.page-subheader-inner {
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		position: relative;
		min-height: 60px;
		&::after {
			content: '';
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			bottom: 0;
			border-bottom: 1px solid rgba($color-input-field, 0.5);
			opacity: 0.5;
		}
		.okr-tabs {
			.MuiTabs-fixed {
				margin-left: -1.25em;
			}
		}
	}
	.page-subheader-left {
		@include flex;
		@include align-items(center);
	}
	.page-subheader-right {
		@include flex;
		@include align-items(center);
		button {
			margin-left: 1em;
		}
	}
	&.page-subheader-home {
		padding-left: 3%;
		padding-right: 3%;
		.okr-tabs-space {
			.MuiTabs-scroller.MuiTabs-fixed {
				margin-left: -1em;
			}
		}
	}
}

/**profile info right drawer css start here**/
.profile-setting-drawer {
	z-index: 9990 !important;
	.MuiDrawer-paper {
		width: 300px;
		@include box-shadow(2px 0px 10px rgba($color-primary2, 0.12));
		@include custom-scrollbar;
		.user-account-info {
			text-align: center;
			padding: 1.25em 1.25em 0.9375em;
			.user-info-wrap {
				padding-bottom: 1.25em;
				border-bottom: 1.4px solid $color-hover;
			}
			.user-setting-avatar {
				margin: 0 auto 0.625em;
			}
			.user-role-chip {
				margin-top: 0.625em;
				background: #f0f0f0;
				padding: 0 7px;
				@include border-radius(100px);
				@include inline-flex;
				color: $color-primary2;
			}
		}
		.user-account-setting {
			.account-head {
				padding-left: 1.25em;
				padding-bottom: 0.5em;
			}
			ul {
				padding: 0px;
				> li {
					padding: 0.625em 1.25em;
					display: block;
					cursor: pointer;
					> .link-txt {
						color: #292929;
						width: 100%;
						display: flex;
						align-items: center;
						p {
							font-size: $font-size-h5;
							line-height: $line-height4;
						}
						svg {
							width: 14px;
							height: 14px;
							margin-right: 1.25em;
						}
					}
					&.activeMenu {
						background: $color-hover;
					}
					&:hover {
						background: $color-hover;
					}
					.user-quick-search-type2 {
						min-width: 257px;
						margin-top: 2px;
						.rbt-input-main {
							min-width: 257px;
						}
						.rbt-menu#async-pagination {
							min-width: 257px !important;
							max-height: 250px !important;
							a {
								.search-no-record {
									h2 {
										font-size: $font-size-base;
										line-height: $line-height3;
										white-space: nowrap;
									}
									h6 {
										font-size: $font-size-subtitle2;
									}
								}
							}
							.des-with-team {
								@include flex;
								.team-avatar-smaller {
									margin-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}
