@import '../../sass-utils/variables';
/**onboarding popup**/
.onboarding-popup {
	@include flex;
	@include align-items(center);
	@include justify-content(center);
	z-index: 9999 !important;
	div[aria-hidden='true'] {
		background: rgba($color-primary2, 0.3) !important;
	}
	.fade {
		opacity: 0;
		@include animation(fade-in-row ease-in-out 5s);
		animation-fill-mode: forwards;
		transition: 1s;
	}
	.onboarding-popup-inner {
		position: absolute;
		width: 50em;
		min-height: 28.75em;
		background: $color-white;
		@include border-radius(6px);
		@include box-shadow(0px 0px 60px rgba($color-primary2, 0.2));
		.skip-btn {
			position: absolute;
			right: 1.0625em;
			top: 0.375em;
			color: rgba($color-primary2, 0.5);
			font-size: $font-size-h5;
			line-height: 1.25em;
			z-index: 9;
			text-decoration: none;
		}
		.slick-slider {
			min-height: 30.375em;
			.slick-slide {
				background: none !important;
				&.slick-current {
					z-index: 99;
				}
			}
		}
		.slick-dots {
			width: 100%;
			background: $color-primary1;
			padding: 1.8125em 1.25em;
			bottom: 0;
			pointer-events: none;
			@include border-radius(0 0 0.375em 0.375em);
			li {
				width: 0.75em;
				height: 0.75em;
				margin: 0.375em 0.1875em 0;
				padding: 0;
				vertical-align: middle;
				button {
					width: 0.75em;
					height: 0.75em;
					padding: 0;
					&::before {
						font-size: $font-size-h6;
						line-height: 0.75em;
						width: 0.6875em;
						height: 0.6875em;
						color: rgba(255, 255, 255, 0.4);
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&::before {
							color: rgba(255, 255, 255, 0.7);
						}
					}
				}
			}
		}

		.slick-arrow {
			top: auto;
			bottom: 1.25em;
			z-index: 99;
			font-size: 1em;
			line-height: 1.25em;
			font-weight: $font-weight-regular;
			color: $color-white;
			width: auto;
			&:before,
			&:after {
				content: '';
				font-weight: $font-weight-regular;
				background: url(../../../images/slick-arrow.svg) no-repeat 0 center;
				width: 0.5em;
				height: 20px;
				display: inline-block;
				vertical-align: middle;
			}
			&.slick-next {
				right: 1.125em;
				&:after {
					margin-left: 0.5em;
				}
				&::before {
					display: none;
				}
				&.slick-disabled {
					opacity: 0.5;
				}
			}
			&.slick-prev {
				left: 1.125em;
				&::before {
					transform: rotate(180deg);
					margin-right: 0.5em;
					margin-top: -1px;
				}
				&::after {
					display: none;
				}
			}
			&.slick-disabled {
				display: none !important;
			}
		}
		.slide-wrap {
			padding: 2.8125em 0 2.8125em 2.8125em;
			min-height: 30.375em;
			@include border-radius(0.375em);
			/**slide column half width**/
			&.slide-column-half {
				.slide-wrap-inner {
					@include align-items(center);
				}
				.slide-content {
					width: 50%;
					position: relative;
					.MuiTypography-body1 {
						font-size: $font-size-p-body1;
						line-height: $line-height2;
						&.top-space {
							margin-top: 1.25em;
						}
					}
					.styled-text {
						background: url(../../../images/onboarding-arrow.png) no-repeat right 0;
						padding-top: 1.625em;
						width: 9.75em;
						position: absolute;
						right: -2.375em;
						bottom: -3.8125em;
						.MuiTypography-body1 {
							font-size: $font-size-subtitle2;
							color: rgba($color-primary2, 0.5);
							font-family: $font-family-other;
							font-weight: bold;
							padding-right: 0.5em;
						}
					}
				}
				.slide-video {
					background: $color-white;
					width: 50%;
					max-width: 21.5625em;
					min-height: 14.9375em;
					padding: 0.625em 0 0.625em 0.625em;
					@include box-shadow(0px 0px 1.875em rgba($color-primary2, 0.1));
					@include border-radius(0.75em 0px 0px 0.75em);
					border: 1px solid $color-hover;
					margin-left: 2.4375em;
					img {
						@include border-radius(0.375em 0px 0px 0.375em);
						width: 100%;
					}
					.slide-video-inner {
						background: url('../../../images/onboarding-video-bg.png') no-repeat 0 0;
						padding: 0.5625em 0 0.5625em 0.5625em;
						background-size: 100%;
						@include border-radius(0.375em 0px 0px 0.375em);
						position: relative;
						min-height: 13.5625em;
						cursor: pointer;
					}
					.video-icon {
						background: url('../../../images/video-icon.svg') no-repeat 0 0;
						width: 2.6875em;
						height: 2.6875em;
						position: absolute;
						top: 50%;
						left: 50%;
						margin-left: -0.875em;
						margin-top: -1.375em;
						cursor: pointer;
					}
				}
			}
			&.ready-slide {
				.slide-wrap-inner {
					@include align-items(center);
				}
				.slide-content {
					padding-top: 4.25em;
					width: 51%;
				}
				.slide-image {
					width: 40%;
					text-align: center;
					padding: 0;
					img {
						display: inline-block;
					}
				}
				.button-wrap {
					.button-blue-border {
						min-width: 8.375em;
					}
				}
			}
			&.sample-example {
				.slide-content {
					padding-right: 1em;
				}
			}
			&.okr-list-slide {
				.slide-content {
					width: 95%;
				}
				.MuiTypography-h4 {
					font-size: 1.125em;
					font-weight: normal;
					margin-bottom: 0.4375em;
				}

				.okr-list {
					padding-top: 0;
					padding-bottom: 0;
					.MuiListItem-gutters {
						padding: 0;
						display: block;
						.keyresult-list {
							padding: 1.0625em 0 0 1.875em;
							animation-delay: 2.2s;

							li {
								margin-bottom: 0.875em;
							}
						}
					}
					.MuiBox-root {
						@include flex;
						@include align-items(flex-start);
						img {
							margin-right: 0.625em;
						}
					}
					.subTitle {
						padding-bottom: 0.9375em;
						animation-delay: 0.15s;
					}
					.obj-title-wrap {
						animation-delay: 0.5s;
					}
					.obj-title {
						position: relative;
					}
					.objective-arrow {
						padding-top: 0;
						width: 6.0625em;
						position: relative;
						margin-top: -0.9375em;
						margin-left: 1.0625em;
						padding-left: 2.5em;
						animation-delay: 1.5s;
						svg {
							position: absolute;
							left: -0.5em;
							top: -16px;
							width: 40px;
							transform: rotate(-44deg);
							opacity: 0.9;
							path {
								fill: $color-primary2;
							}
						}
						.MuiTypography-body1 {
							font-size: $font-size-subtitle2;
							color: rgba($color-primary2, 0.5);
							font-family: $font-family-other;
							font-weight: bold;
							padding-right: 0.5em;
						}
					}
					.kr-arrow {
						width: 4.625em;
						position: absolute;
						right: auto;
						top: 112px;
						left: -4.625em;
						display: block;
						padding-top: 2.625em;
						//opacity: 0;
						animation-delay: 3s;
						svg {
							position: absolute;
							top: -0.125em;
							right: 0;
						}
						.MuiTypography-body1 {
							font-size: $font-size-subtitle2;
							color: rgba($color-primary2, 0.5);
							font-family: $font-family-other;
							font-weight: bold;
							padding-right: 0.5em;
							line-height: 0.9375em;
						}
					}
				}
				.slide-content {
					.button-wrap {
						padding-left: 10px;
						margin-top: 18px;
						opacity: 0;
						transition: opacity 1s;
						@include flex;
						@include align-items(center);
						animation-delay: 3s;
					}
					.slide1-active {
						.button-wrap {
							padding-left: 30px;
						}
					}
				}

				.more-btn {
					position: relative;
					padding-right: 35px;
					height: auto;
					line-height: normal;
					text-decoration: none !important;
					&::after {
						content: '';
						background: url(../../../images/more-right-arrow.svg) no-repeat right center;
						width: 1.75em;
						height: 0.6875em;
						transition: 0.5s;
						position: absolute;
						left: 48px;
						top: 50%;
						margin-top: -4.5px;
					}
					.icon {
						margin-right: 0.1875em;
					}
					&:hover {
						&::after {
							width: 36px;
						}
					}
				}
				.back-btn {
					text-decoration: none !important;
					line-height: normal;
					height: auto;
					.icon {
						margin-right: 0.1875em;
					}
				}
				.back-arrow {
					width: 20px;
					position: relative;
					margin: 0 0px 0 0;
					padding-right: 2px;
					height: 0.6875em;
					.MuiButton-label {
						display: none;
					}
					&::before {
						content: '';
						background: url(../../../images/more-right-arrow.svg) no-repeat right center;
						width: 10px;
						height: 0.6875em;
						transition: 0.3s;
						position: absolute;
						right: 0px;
						top: 50%;
						margin-top: -4.5px;
						transform: rotate(180deg);
					}

					&:hover {
						//width: 38px;
						&::before {
							width: 20px;
							right: 0px;
						}
					}
				}
			}
			.slide-wrap-inner {
				display: flex !important;
			}
			.slide-content {
				width: 66%;
				padding-left: 4.0625em;
				.MuiTypography-h3 {
					font-size: 1.125em;
					margin-bottom: 0.625em;
					img {
						display: inline-block;
						vertical-align: top;
						margin-top: 2px;
					}
				}
				.top-space {
					margin-top: 2em;
				}
				.button-wrap {
					margin-top: 1.25em;
					.button-blue-border {
						background-color: transparent;
						margin-right: 0.75em;
						padding: 0.4em 1.375em 0.4em;
						@include transition-default;
						&:hover {
							background-color: $color-primary1;
							border: 1px solid $color-primary1;
							@include box-shadow(5px 8px 24px rgba(62, 162, 229, 0.5));
							color: $color-white;
						}
					}
				}
			}
			.slide-image {
				padding-top: 0.4375em;
				padding-left: 0.75em;
				padding-right: 0;
				img {
					max-width: 132px;
				}
			}

			strong {
				font-weight: bold;
			}
		}
	}
}
/**callout tooltip**/
.callout-tooltip {
	z-index: 9990;
	padding: 1.4375em 1.4375em 3.75em 1.4375em;
	background-color: $color-primary1;
	background-image: url(../../../images/callout-tooltip-bg.png);
	background-repeat: no-repeat;
	background-position: 0 bottom;
	@include border-radius(10px);
	@include box-shadow(0px 0px 30px rgba($color-primary2, 0.3));
	border: 1px solid #abd9ff;
	min-width: 330px;
	max-width: 330px;
	min-height: 140px;
	margin-left: -2.5em;
	margin-top: 0.5em;
	@include animation(fade-in 0.8s);
	&.assignContributorTooltip {
		z-index: 9991;
	}
	&.create-title-guidedtext {
		margin-left: -1.5625em;
	}
	&.setting-kr-guidedtext {
		margin-left: -0.9375em;
	}
	.MuiTypography-body1 {
		color: $color-white;
	}
	&::before {
		width: 0.75em;
		height: 0.75em;
		content: '';
		position: absolute;
		left: 2.5em;
		top: -0.4em;
		background: $color-primary1;
		transform: rotate(45deg);
	}
	&.assignkr-date-guidedtext {
		margin-top: -0.8125em;
		&::before {
			left: 50%;
			margin-left: 1.25em;
		}
	}

	&[x-placement='top-start'] {
		&::before {
			bottom: -0.1em;
			top: auto;
		}
		&.submitGuidedText {
			margin-top: 0px;
			margin-bottom: 0.9375em;
			&::before {
				left: auto;
				right: 8.125em;
			}
		}
		&.assignkr-target-guidedtext {
			margin-top: -1.375em;
			margin-left: 0;
		}
	}
	&[x-placement='bottom-start'] {
		&.assignkr-target-guidedtext {
			margin-left: 0;
			margin-top: -1.375em;
		}
	}
	&[x-placement='left-start'] {
		margin-right: 1.875em;
		margin-left: 0;
		margin-top: -2.8125em;
		&::before {
			width: 0.75em;
			height: 0.75em;
			content: '';
			position: absolute;
			right: -6px;
			top: 3.125em;
			background: $color-primary1;
			transform: rotate(45deg);
			left: auto;
		}
		&.middle-md {
			margin-top: 0px;
		}
		&.middle-md-5 {
			margin-top: -0.3125em;
		}
		&.middle-md-10 {
			margin-top: -0.625em;
		}
		&.desGuided {
			margin-top: -2.0625em;
		}
	}
	.cancel-btn {
		position: absolute;
		top: 0.4375em;
		right: 0.125em;
		padding: 0 0.375em;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		margin-top: -2px;
		margin-right: 2px;
		svg {
			width: 10px;
			path {
				fill: rgba(255, 255, 255, 0.7);
				stroke: rgba(255, 255, 255, 0.7);
			}
		}
		.MuiTouchRipple-root {
			display: none;
		}
		&:hover {
			//background: none;
			svg {
				path {
					fill: $color-white;
				}
			}
		}
	}
	.callout-btn {
		position: absolute;
		width: 100%;
		left: 0;
		@include flex;
		@include justify-content(space-between);
		padding: 0 1.375em;
		bottom: 1.625em;
		.MuiButton-text {
			color: $color-white;
			padding: 0;
			&:hover {
				background: none;
			}
		}
		&.callout-btn-onlysingle {
			@include justify-content(flex-end);
		}
	}
	&.okr-tooltip {
		z-index: 9990;
		padding: 0.4375em 0.625em;
		background-color: $color-primary2;
		@include tooltip-shadow;
		@include border-radius(6px);
		min-width: 205px;
		max-width: 205px;
		min-height: 64px;
		margin-bottom: 0.5em;
		margin-left: -2.375em;
		background-image: none;
		border: 0px;
		@include flex;

		&::before,
		&::after {
			width: 0.54em;
			height: 0.54em;
			content: '';
			position: absolute;
			left: 2.5em;
			top: -0.3em;
			background: $color-primary2;
			transform: rotate(45deg);
		}
		.tooltip-icon {
			margin-right: 0.5em;
		}
		.cancel-btn {
			display: none;
		}
		.MuiTypography-body1 {
			color: $color-white;
			font-size: $font-size-h6;
			line-height: 16px;
			svg {
				vertical-align: text-bottom;
			}
		}
		&[x-placement='top-start'] {
			&::before {
				display: none;
			}
			&::after {
				top: auto;
				bottom: -0.2em;
			}
		}
		&.kr-tooltip {
			margin-left: 0;
			&::before,
			&::after {
				left: 1.6875em;
			}
		}
	}
}

.tour-band {
	padding-left: 2.5em;
	position: fixed;
	bottom: 40px;
	left: -379px;
	transition: 1s;
	z-index: 9999;
	height: 203px;
	display: none;
	&:hover {
		left: 0;
		.tour-band-txt {
			opacity: 0;
			display: none;
		}
	}
	&.active-band {
		left: 0;
	}
	&.openTourBandText {
		.tour-band-txt {
			opacity: 1;
		}
	}

	.guide-me-tour-action {
		z-index: 9990;
		padding: 1.3125em 0.75em 3.125em 0.75em;
		background-color: $color-primary1;
		background-image: url(../../../images/hills_City.png);
		background-repeat: no-repeat;
		background-position: 0 bottom;
		@include box-shadow(0px 0px 20px rgba($color-primary2, 0.2));
		@include border-radius(6px);
		border: 1px solid #abd9ff;
		min-width: 350px;
		max-width: 350px;
		//min-height: 163px;
		.MuiTypography-body1 {
			color: $color-white;
			font-weight: $font-weight-bold;
		}
		.guided-intro {
			@include flex;
			@include align-items(flex-start);
			img {
				max-width: 93px;
			}
		}
		.button-white-bg {
			@include border-radius(30px);
			color: $color-primary1;
			background: $color-white;
			padding: 0.25em 1.09375em;
			@include transition-default;
			border: 0px solid $color-primary1;
			margin-top: 1.0625em;
			line-height: 27px;
			&:hover {
				background: $color-white;
			}
		}
		.action-area {
			padding-top: 12px;
		}
	}
	.tour-band-txt {
		position: absolute;
		right: -146px;
		bottom: -37px;
		max-width: 116px;
		padding-top: 3.375em;
		pointer-events: none;
		opacity: 0;
		@include transition(0.5s);
		//@include animation(fade-out 1s);
		svg {
			position: absolute;
			top: 2px;
			left: -14px;
		}
		&.openTourBandText {
			//.tour-band-txt {
			//opacity: 1;
			//@include animation(fade-in 1s);
			//}
		}
	}
}
.guideme-home {
	.tour-band {
		display: block;
	}
	&.guide-me-check {
		.tour-band,
		#guide-tour-nolocked {
			display: none;
		}
	}
}

.guided-tour-callout {
	z-index: 9990;
	padding: 14px;
	background-color: $color-primary2;
	box-shadow: 0px 30px 30px rgba(41, 41, 41, 0.3);
	border-radius: 10px;
	min-width: 300px;
	max-width: 300px;
	min-height: 110px;
	margin-bottom: 8px;
	margin-left: 60px;
	animation-iteration-count: infinite;
	animation-duration: 1.5s;
	animation-name: bounce;
	transform-origin: center bottom;
	animation-fill-mode: both;

	&::before {
		width: 0.75em;
		height: 0.75em;
		content: '';
		position: absolute;
		left: 40px;
		bottom: -0.4em;
		background: $color-primary2;
		transform: rotate(45deg);
	}

	.cancel-btn {
		display: none;
	}
	.MuiTypography-body1 {
		color: $color-white;
		line-height: 24px;
		svg {
			vertical-align: text-bottom;
		}
	}
}

.guided-btn {
	.add-btn {
		transform: scale(1.05);
		transition: 1s;
		@include animation(pulse 0.8s infinite);
	}
}
