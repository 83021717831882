@import '../../sass-utils/variables';

.feedback-okr-listing {
	.MuiExpansionPanelDetails-root {
		display: block;
		padding: 0 1.25em 1.25em;
		.feedback-key-result {
			position: relative;
			margin: 0;
			padding: 1em;
			border-bottom: 1px solid #dadada;
			width: 100%;
			background: #f5f6f7;
			&:last-child {
				border: none;
			}
		}
	}
	.MuiPaper-elevation1 {
		border: 1px solid #dadada;
		box-shadow: none;
		border-radius: 5px;
		margin-bottom: 0.5em;
		&.Mui-expanded {
			@include box-shadow(0px 4px 12px rgba(174, 174, 174, 0.2));
			margin: 16px 0;
		}
		&:before {
			display: none;
		}
		.feedback-menu {
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}
.feedback-comments-area {
	.feedback-comments {
		background: $color-white;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		border: 1px solid rgba($color-input-field, 0.5);
		@include border-radius(6px);
		margin-bottom: 0.75em;
		&:hover {
			@include box-shadow(0px 4px 12px rgba(174, 174, 174, 0.2));
			border: 1px solid $color-keyword-highlighter;
		}
		&.active {
			-webkit-transform: scale(1.02);
			-moz-transform: scale(1.02);
			transform: scale(1.02);
			@include box-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
			margin-bottom: 1.5em;
			.comments-sub-list {
				max-height: 590px;
				padding: 0 0.875em 1.75em 0;
				overflow-x: auto;
				overflow-y: auto;
				@include custom-scrollbar;
			}
		}
	}
	ul {
		padding: 0;
		&.comments-list {
			padding-bottom: 1.5em;
			.MuiListItemText-multiline {
				margin-top: 6px;
				margin-bottom: 6px;
			}
			.MuiListItemAvatar-alignItemsFlexStart {
				//margin-top: 6px;
			}
		}
		&.comments-sub-list {
			margin-top: 1em;
			li {
				padding-left: 4em;
				font-size: 14px;
			}
			&.comments-sub-given-list {
				li {
					padding-left: 0px;
				}
			}
		}
		li {
			padding: 0;
			span {
				&.MuiTypography-body1 {
					display: block;
				}
			}
			.MuiListItemText-secondary {
				span.MuiTypography-body1 {
					margin: 0em 0 0.5em;
				}
			}
			.MuiInput-underline {
				&::before,
				&::after {
					display: none;
				}
				.MuiInputBase-input {
					font-size: $font-size-base;
					color: $color-primary2;
				}
			}
		}
	}
	.comments-zoom-in {
		padding: 1.3125em 1.75em 0 1.75em;
		display: none;
	}
	.comments-zoom-out {
		padding: 0.65625em 0.65625em 0.65625em 1.53em;
		display: block;
		.comments-top {
			padding-bottom: 0.25em;
		}
	}
	.animate {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}
	.animate-fadeIn {
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
		display: block;
	}
	.animate-fadeOut {
		-webkit-animation-name: fadeOut;
		animation-name: fadeOut;
		display: none;
	}
}
.feedback-action {
	@include justify-content(flex-end);
	@include flex;
	margin-top: 1.25em;
}

// New code

.feedback-item {
	padding-left: 4em !important;
	padding-bottom: 0em !important;

	.feedback-field {
		.MuiTextField-root {
			width: 100%;
		}
	}
}
.feedback-msg {
	textarea {
		font-size: $font-size-base;
		padding-left: 0 !important;
	}
}
.key-result {
	padding-bottom: 0.5em !important;
}

.okr-new-form-area {
	small {
		display: block;
		padding-bottom: 1em;
	}
	.okr-form-action {
		padding-top: 1em;
	}
}
/**feedback css start here**/
.view-feedback-area {
	.okr-form-action {
		.request-btn {
			margin-left: 0.65em !important;
			margin-right: 0.5em;
		}
	}
	.contributors-chip {
		//margin-top: 0.625em;
	}
	.drawer-field-bottom {
		width: 100%;
	}
}
.personal-comments-area {
	.okr-form-action {
		@include justify-content(flex-end);
		@include flex;
	}
	.okr-form-fields {
		.MuiFormControl-root.MuiTextField-root {
			padding-bottom: 0px !important;
		}
	}
	.comments-sub-list {
		padding-bottom: 1.5em !important;
	}
}
.feedback-keys {
	ul {
		position: relative;
		padding: 0;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0.8em;
			height: 100%;
			width: 2px;
			background: $color-primary1;
			border-radius: 0.25em;
			z-index: 1;
		}
		li {
			position: relative;
			display: block;
			padding: 1em 2em 0;
			&::before {
				content: '';
				position: absolute;
				left: 0.55em;
				top: 1.5em;
				width: 0.625em;
				height: 0.625em;
				@include border-radius(1em);
				background: $color-primary1;
			}
			.add-kr-btn {
				position: absolute;
				left: 0;
			}
			.active-key {
				.MuiTypography-body1 {
					color: $color-primary2;
				}
			}
		}
	}
}
