@import '../../../styles/sass-utils/variables';
/* Bulk user Upload  CSS*/
.bulk-user-content {
	padding: 0 60px;
	.upload-head {
		padding-bottom: 0.5em;
		@include flex;
		@include align-items(center);
		@include justify-content(space-between);
		.MuiLink-root {
			color: $color-primary1;
			text-decoration: underline;
		}
		strong {
			font-weight: $font-weight-bold;
			font-style: italic;
		}
	}
	.upload-file {
		margin: 1em 0;
		background-image: url('../../../images/mask-group-upload.svg'), url('../../../images/base-rectangle-upload-img.svg');
		background-repeat: no-repeat;
		@include justify-content(center);
		@include align-items(center);
		background-size: cover;
		border: 1px solid $color-input-field;
		@include border-radius(6px);
		@include flex;
		cursor: pointer;
		min-height: 264px;
		.upload-file-inner {
			text-align: center;
			.upload-img {
				@include flex;
				margin: auto;
				//padding-top: 3em;
				padding-bottom: 1.25em;
			}

			h4 {
				//padding-bottom: 2.5em;
				a {
					color: $color-primary1;
					text-decoration: none;
				}
			}
		}
	}
	.download-link {
		em {
			font-style: italic;
		}

		a {
			margin-left: 0.3em;
			color: $color-primary1;
			text-decoration: underline;
		}
	}
	.upload-result {
		margin-top: 3.125em;
		padding: 0.9375em 0;
		@include flex;
		@include justify-content(space-between);
		border-top: 1.4px solid rgba($color-primary2, 0.1);
		svg {
			width: 14px;
			height: 14px;
			margin-right: 0.325em;
		}
		.no-error-mssg {
			@include flex;
			@include align-items(center);
			svg {
				path {
					fill: $color-text-info;
				}
			}
			.MuiTypography-h5 {
				color: $color-text-info;
				line-height: $line-height2;
			}
		}
		.download-error-link {
			@include flex;
			@include align-items(center);
			svg {
				path {
					fill: $color-text-info;
				}
			}
			.MuiLink-root {
				color: $color-text-info;
				font-weight: $font-weight-bold;
				font-size: $font-size-h5;
				line-height: $line-height2;
			}
		}
	}
	.note-text {
		margin-top: 1em;
		font-size: $font-size-h6;
		font-style: italic;
		color: rgba(98, 98, 98, 0.8);

		strong {
			font-weight: $font-weight-bold !important;
		}
		ul {
			padding: 0;
			margin-left: 13px;

			li {
				margin: 0;
				padding-top: 0;
				padding-bottom: 0;
				position: relative;
				display: list-item;
				list-style-type: disc;
				padding-left: 0;
				color: $color-secondary1;
			}
		}
	}
}
.uploading-bar {
	.bar {
		background-color: $color-input-field;
		width: 100%;
		height: 4.5em;
		position: relative;
		margin: 2.5em 0;
		@include border-radius(5px);

		.inner-bar {
			padding: 1.3em;
			position: absolute;
			height: 4.5em;
			width: 100%;
			@include justify-content(space-between);
			border: 1px solid $color-keyword-highlighter;
			@include border-radius(5px);
			@include flex;
			@include align-items(center);
			p {
				color: $color-white;
				padding: 0.2em;
				z-index: 99;
			}
			.delete-icon {
				padding: 0;
				min-width: auto;
				z-index: 99;
				font-size: $font-size-base;
				width: 14px;
				height: 14px;
				@include border-radius(100%);
				svg {
					path {
						fill: $color-white;
					}
				}
			}
		}
		.success {
			background-color: $color-completed;
			position: absolute;
			width: 100%;
			height: 4.5em;
			z-index: 99;
			@include border-radius(5px);
		}
		// .uploading {
		// 	background-color: #3ea2e5;
		// 	position: absolute;
		// 	width: 89%;
		// 	height: 4.5em;
		// 	z-index: 99;
		// 	@include border-radius(5px 0 0 5px);

		// 	p {
		// 		color: $color-white;
		// 		position: absolute;
		// 		right: 0.5em;
		// 		bottom: 0.3em;
		// 	}
		// }
	}
}
