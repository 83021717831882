/*Main Navigation CSS*/
.main-nav {
	@include flex;
	@include justify-content(center);
	flex: 1;
	ul {
		@include flex;
		@include align-items(center);
		@include justify-content(center);
		padding: 0;
		li {
			font-size: $font-size-base;
			font-weight: $font-weight-bold;
			min-height: $header-height;
			min-width: $header-height;
			position: relative;
			a {
				color: $color-primary2;
				&:hover {
					text-decoration: none;
				}
			}
			&.home {
				@include flex;
				@include justify-content(center);
				a {
					line-height: 1;
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						margin-left: -10px;
						margin-top: -10px;
						width: 25px;
						height: 25px;
						z-index: 99;
					}
				}
				svg {
					width: 1.1em;
				}
				.home-hover {
					position: absolute;
					top: 50%;
					left: 50%;
					margin-left: -10px;
					margin-top: -15px;
					opacity: 0;
					svg {
						width: 19px;
					}
				}
			}
			&.active {
				background-color: $color-secondary2;
				a {
					color: $color-primary1;
				}
				&::after {
					position: absolute;
					left: 0;
					bottom: 0;
					height: 3px;
					background-color: $color-primary1;
					content: '';
					width: $full-width;
				}
				svg {
					path {
						fill: $color-primary1;
					}
				}
			}
			&:hover {
				background-color: rgba($color-primary2, 0.05);
				&.home {
					svg {
						opacity: 0;
					}
					.home-hover {
						opacity: 1;
						svg {
							opacity: 1;
						}
					}
				}
				&.active {
					background-color: $color-secondary2;
				}
			}
		}
	}
}
