@import '../../../styles/sass-utils/variables';

@import './popperMenu.scss';
@import './tabsHeader.scss';
@import './overwrite.scss';

.main-wrapper {
	.page-content-wrapper {
		padding-top: 1em;
		.admin-role-tab,
		.admin-user-tab {
			padding: 0 60px; /* Temproraly added this css on admin-role-tab later it will move globally on page-content wrapper */
		}
	}
}

/*matrial overwrite css*/
body.admin-body-class {
	.MuiChip-root {
		background: $color-bg-tag;
		min-height: 16px;
		min-width: 3.0625em;
		@include border-radius(12px);
		padding: 2px 7px 2px;
		height: auto;
		color: $color-primary2;
		.MuiChip-label {
			font-size: $font-size-subtitle2;
			font-weight: $font-weight-bold;
			line-height: $line-height3;
			padding: 0px;
			display: block;
			text-align: center;
			text-transform: uppercase;
		}
		&.chip-star {
			position: relative;
			padding-right: 14px;
			sup {
				position: absolute;
				right: 7px;
				top: 0;
			}
		}
		&.chip-avatar {
			padding: 0px 7px 0 0;
			border-radius: 100px;
			max-width: 200px;
			background: $color-secondary2;
			.MuiChip-avatar {
				margin: 0px 9px 0 0px;
				border: 1.4px solid $color-white;
				color: $color-threshold;
				background: $color-secondary2;
				font-weight: $font-weight-bold;
				font-size: $font-size-subtitle2;
				line-height: 24px;
				width: 26px;
				height: 26px;
				.MuiAvatar-img {
					@include border-radius(100%);
				}
			}
			.MuiChip-deleteIcon {
				@include flex;
				@include align-items(center);
				@include justify-content(center);
				margin: 0 0 0 7px;
				width: 16px;
				height: 16px;
				svg {
					width: 8px;
					height: 8px;
					path {
						stroke: $color-primary2;
						stroke-width: 3px;
					}
				}
				&:hover {
					svg {
						path {
							fill: $color-text-info;
							stroke: $color-text-info;
						}
					}
				}
			}
			.MuiChip-label {
				font-size: $font-size-h6;
				line-height: $line-height5;
				color: $color-threshold;
				margin: 0;
			}
		}
	}
}

.ml-1{
	margin-left: 1em !important;
}

body.admin-body-class {
	/**information message css start here**/
	.information-message {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		padding: 0.3125em 3.75em;
		background: $color-bg-info;
		@include animation(slideUp 0.8s);
		.MuiTypography-h6 {
			@include inline-flex;
			@include align-items(center);
			color: $color-text-info;
			text-transform: initial;
			line-height: 14px;
			svg {
				margin-right: 0.25em;
				path {
					fill: $color-text-info;
				}
			}
		}
	}

	/**default dialog box css start here**/
	.default-dialog {
		z-index: 99999 !important;
		.MuiDialog-paper {
			@include panel-shadow;
			@include border-radius(6px);
			padding: 1.5em 1.25em 1.25em;
			min-width: 550px;
			max-width: 550px;
			min-height: 502px;
			overflow-y: visible;
			.MuiDialogContent-root {
				padding: 0;
				text-align: left;
				max-width: 75%;
				@include animation(fade-in 1.8s);
				svg {
					margin-bottom: 0.625em;
				}
				p {
					padding-bottom: 2em;
					margin: 0;
				}
			}
		}
		/** dialog box css start here**/
		&.delete-dialog-box {
			.MuiDialog-paper {
				@include custom-scrollbar;
				.MuiDialogContent-root {
					max-width: 100%;
					overflow-y: initial;
					.delete-dialog-inner {
						padding-bottom: 2.5em;
						p {
							margin: 0px;
							padding-bottom: 0;
							strong {
								font-weight: $font-weight-bold;
							}
						}
						.delete-confirm-text {
							padding-bottom: 2.5em;
							padding-right: 0.625em;
						}
						.delete-confirm-text-field {
							.MuiTextField-root {
								width: 100%;
								margin-top: 0.75em;
								.MuiInputBase-root {
									padding-left: 10px;
									padding-right: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.admin-org-tab {
	.organization-wrapper {
		margin-top: 0;
		.navbar {
			padding-left: 60px !important;
			padding-right: 60px !important;
			padding-top: 0px !important;
			.MuiTypography-h2 {
				font-weight: $font-weight-black;
			}
		}
		.rd3t-tree-container.rd3t-grabbable {
			padding-left: 20px !important;
			padding-right: 20px !important;
		}
	}
}

.search-not-found {
	text-align: center;
	object {
		margin-bottom: 2em;
	}
}
